/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import './Checkbox.css';

const Checkbox = (props) => {
  const { onChange, checked, labelStyle, text, key, checkboxStyle } = props;
  return (
    <label style={{ display: 'flex', marginBottom: 12 }} className="checkboxes">
      <input
        key={key}
        type="checkbox"
        onChange={onChange}
        checked={checked}
        style={checkboxStyle}
      />
      <p style={labelStyle}>{text}</p>
    </label>
  );
};

export default Checkbox;
