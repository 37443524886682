/* eslint-disable no-await-in-loop */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import moment from 'moment';
import QRCodeStyling from 'qr-code-styling';
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { Button, Dropdown, Grid, Image, Input, Table } from 'semantic-ui-react';
import { getAllShops, createQRPDF } from '../../../api/ShopAPI';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';
import { useStateValue } from '../../../core/context/StateProvider';
import logo from '../../shop/Settings/logoblack.png';
import './CreateQRScreen.css';

const CreateQRScreen = () => {
  const [{ token }, dispatch] = useStateValue();
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [selectedShop, setSelectedShop] = useState(null);
  const [shops, setShops] = useState([]);
  const [tables, setTables] = useState([]);
  const [isShopsLoaded, setIsShopsLoaded] = useState(false);
  const [warningModalOptions, setWarningModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
    amount: null,
  });
  const [errorModalOptions, setErrorModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
  });

  useEffect(() => {
    if (dropDownOptions.length === 0 && isShopsLoaded === false) {
      getShops();
    }
    if (selectedShop !== null && selectedShop.tables !== null && tables.length === 0) {
      createTableCodes();
    }
  });

  const createTableCodes = async () => {
    for (let i = 0; i < selectedShop.tables.length; i++) {
      const table = selectedShop.tables[i];
      const qrCodeOptions = {
        width: 800,
        height: 800,
        data: `${process.env.REACT_APP_URL_PREFIX}${process.env.REACT_APP_CUSTOMER_SUBDOMAIN}.${process.env.REACT_APP_QR_WEBSITE}/${table.code}`,
        margin: 0,
        qrOptions: { typeNumber: '0', mode: 'Byte', errorCorrectionLevel: 'Q' },
        imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
        dotsOptions: { type: 'extra-rounded', color: '#000000', gradient: null },
        backgroundOptions: { color: '#ffffff' },
        image: logo,
        dotsOptionsHelper: {
          colorType: { single: true, gradient: false },
          gradient: {
            linear: true,
            radial: false,
            color1: '#000000',
            color2: '#000000',
            rotation: '0',
          },
        },
        cornersSquareOptions: { type: 'extra-rounded', color: '#000000' },
        cornersSquareOptionsHelper: {
          colorType: { single: true, gradient: false },
          gradient: {
            linear: true,
            radial: false,
            color1: '#000000',
            color2: '#000000',
            rotation: '0',
          },
        },
        cornersDotOptions: { type: '', color: '#000000' },
        cornersDotOptionsHelper: {
          colorType: { single: true, gradient: false },
          gradient: {
            linear: true,
            radial: false,
            color1: '#000000',
            color2: '#000000',
            rotation: '0',
          },
        },
        backgroundOptionsHelper: {
          colorType: { single: true, gradient: false },
          gradient: {
            linear: true,
            radial: false,
            color1: '#ffffff',
            color2: '#ffffff',
            rotation: '0',
          },
        },
      };
      const qr = new QRCodeStyling(qrCodeOptions);
      await qr._canvasDrawingPromise;
      const qrImage = qr._canvas.toDataURL('image/png', 90);
      tables.push(qrImage);
    }
    setTables([...tables]);
  };

  const getShops = async () => {
    const res = await getAllShops(token);
    if (res && res.success === true) {
      setIsShopsLoaded(true);
      setShops(res.shops);
      setDropDownOptions(
        res.shops.map((shop) => ({
          key: shop._id.toString(),
          text: `${shop.name} ${shop._id.toString()}`,
          value: shop._id.toString(),
        }))
      );
    }
  };

  const closeWarningModal = () => {
    setWarningModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
      amount: null,
    });
  };

  const closeErrorModal = () => {
    setErrorModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
    });
  };

  const triggerQRCodeCreate = async (table, code) => {
    try {
      const tableDesc = `${table.description} ${table.number ? `(${table.number})` : ''}`;
      await createQRPDF(tableDesc, code, token);
    } catch (error) {
      setErrorModalOptions({
        open: true,
        title: 'Error',
        message: error,
        ok: true,
        onOk: () => closeErrorModal(),
        yes: false,
        onYes: null,
        no: false,
        onNo: null,
      });
    }
  };

  const parseTable = () => (
    <>
      <div className="settings-table-container" style={{ height: 'calc(100vh - 460px)' }}>
        <Table fixed celled selectable padded sortable className="settings-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Table</Table.HeaderCell>
              <Table.HeaderCell>QR-Code</Table.HeaderCell>
              <Table.HeaderCell>Download</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{parseTableBody()}</Table.Body>
        </Table>
      </div>
    </>
  );

  const parseTableBody = () => {
    if (
      selectedShop &&
      selectedShop !== null &&
      selectedShop.tables !== null &&
      selectedShop.tables.length > 0
    ) {
      return selectedShop.tables.map((table, index) => (
        <>
          <Table.Row>
            <Table.Cell collapsing>{`${table.description} (${table.number})`}</Table.Cell>
            <Table.Cell verticalAlign="middle" textAlign="center">
              {tables[index] ? (
                <Image
                  style={{ height: 100, width: 100, margin: '0 auto', display: 'table' }}
                  src={tables.length > 0 ? tables[index] : ''}
                />
              ) : (
                <></>
              )}
            </Table.Cell>
            <Table.Cell verticalAlign="middle" textAlign="center">
              <Button
                icon="download"
                size="large"
                basic
                color="blue"
                onClick={() => triggerQRCodeCreate(table, tables[index])}
              />
            </Table.Cell>
          </Table.Row>
        </>
      ));
    }
    return <></>;
  };

  return (
    <>
      <div className="cockpit-title">
        <p className="cockpit-title">QR Codes</p>
      </div>
      <div className="create-qr-container">
        <Dropdown
          selection
          fluid
          placeholder="Select Shop"
          options={dropDownOptions}
          onChange={(_, data) => {
            setTables([]);
            setSelectedShop(shops.filter((shop) => shop._id.toString() === data.value)[0]);
          }}
        />
        <br />
        {selectedShop && (
          <Grid stackable doubling columns={1}>
            <Grid.Column>
              <p style={{ fontWeight: 700 }}>QR Codes</p>
              {parseTable()}
            </Grid.Column>
          </Grid>
        )}
      </div>
      <ErrorWarningModal
        open={warningModalOptions.open}
        title={warningModalOptions.title}
        message={warningModalOptions.message}
        ok={warningModalOptions.ok}
        onOk={warningModalOptions.onOk}
        yes={warningModalOptions.yes}
        onYes={warningModalOptions.onYes}
        no={warningModalOptions.no}
        onNo={warningModalOptions.onNo}
        amount={warningModalOptions.amount}
      />
      <ErrorWarningModal
        open={errorModalOptions.open}
        title={errorModalOptions.title}
        message={errorModalOptions.message}
        ok={errorModalOptions.ok}
        onOk={errorModalOptions.onOk}
        yes={errorModalOptions.yes}
        onYes={errorModalOptions.onYes}
        no={errorModalOptions.no}
        onNo={errorModalOptions.onNo}
      />
    </>
  );
};

export default CreateQRScreen;
