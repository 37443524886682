/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Image, Modal, Table } from 'semantic-ui-react';
import { getAllCategories } from '../../../api/CategoryAPI';
import { useStateValue } from '../../../core/context/StateProvider';
import UpdateCategoriesModal from './UpdateCategoriesModal';
import './UpdateCategoriesScreen.css';

const { orderBy } = require('lodash');

const UpdateCategoriesScreen = () => {
  const [{ token, config }, dispatch] = useStateValue();
  const [allCategories, setAllCategories] = useState([]);
  const [categoriesModalOptions, setCategoriesModalOptions] = useState({
    open: false,
    category: null,
  });

  useEffect(() => {
    if (allCategories.length === 0) {
      getCategories();
    }
  });

  const getCategories = async () => {
    const res = await getAllCategories();
    if (res && res.success) {
      const categories = orderBy(res.categories, ['name'], ['asc']);
      setAllCategories(categories);
    }
  };

  const onCategoryModalClose = (refreshNeeded) => {
    setCategoriesModalOptions({ open: false, category: null });
    if (refreshNeeded) {
      setAllCategories([]);
    }
  };

  const parseCategories = (categories) =>
    categories.map((category) => (
      <>
        <Table.Row
          key={category._id}
          onClick={() => setCategoriesModalOptions({ open: true, category })}
        >
          <Table.Cell key={`${category._id}2`} collapsing>
            <Image
              alt=""
              style={{ backgroundColor: 'violet' }}
              src={config.cloudinaryUrlPrefix + category.image}
              size="small"
              centered
            />
          </Table.Cell>
          <Table.Cell key={`${category._id}3`} collapsing>
            {category.name}
          </Table.Cell>
          <Table.Cell key={`${category._id}4`} collapsing>
            {category.key}
          </Table.Cell>
        </Table.Row>
      </>
    ));

  return (
    <>
      <div className="cockpit-title">
        <p className="cockpit-title">Categories</p>
      </div>
      <div className="categories-tile">
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: '600', fontSize: '16px', width: '80%' }}>All Categories</p>
          <div className="items-dropdown" style={{ marginTop: '-10px' }}>
            <Button
              content="Add Category"
              floated="right"
              className="items-btn"
              onClick={() => setCategoriesModalOptions({ open: true, category: null })}
            />
          </div>
        </div>
        <div className="settings-table-container" style={{ height: 'calc(100vh - 300px)' }}>
          <Table fixed celled selectable padded sortable className="settings-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width="5">Picture</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell width="3">ID</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{parseCategories(allCategories)}</Table.Body>
          </Table>
        </div>
      </div>
      <UpdateCategoriesModal
        category={categoriesModalOptions.category}
        open={categoriesModalOptions.open}
        onClose={onCategoryModalClose}
      />
    </>
  );
};

export default UpdateCategoriesScreen;
