/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Modal, Button, Image, Input } from 'semantic-ui-react';
import { createCategory, deleteCategory, updateCategory } from '../../../api/CategoryAPI';
import { createTable, deleteTable, updateTable } from '../../../api/ShopAPI';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';
import StyledInput from '../../../components/general/Input/Input';
import { useStateValue } from '../../../core/context/StateProvider';
import './UpdateCategoriesScreen.css';

const UpdateCategoriesModal = (props) => {
  const { category, onClose, open } = props;
  const [{ token, config }, dispatch] = useStateValue();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [name, setName] = useState('');
  const [picture, setPicture] = useState(null);
  const [pictureBase64, setPictureBase64] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const [warningModalOptions, setWarningModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
  });

  useEffect(() => {
    if (category && !name) {
      setName(category.name);
    }
    if (!category && name && picture) {
      setIsSaveDisabled(false);
    }
  });

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const onSave = async () => {
    setIsLoading(true);
    if (category) {
      const data = {};
      if (name !== category.name && name) {
        data.name = name;
      }
      if (picture !== category.image && picture && pictureBase64) {
        data.image = pictureBase64;
      }
      const res = await updateCategory(token, category._id, data);
      if (res && res.success) {
        closeModal(true);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: 'Category could not be updated. Please try again.',
        });
        setIsLoading(false);
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (name && picture && pictureBase64) {
        const data = {};
        data.name = name;
        data.image = pictureBase64;
        const res = await createCategory(token, data);
        if (res && res.success) {
          closeModal(true);
        } else {
          setErrorWarningModalOptions({
            open: true,
            message: 'Category could not be created. Please try again.',
          });
          setIsLoading(false);
        }
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: 'All fields are mandatory.',
        });
        setIsLoading(false);
      }
    }
  };

  const onDelete = async () => {
    setIsLoading(true);
    setWarningModalOptions({
      open: true,
      title: 'Category Deletion',
      message: 'Are you sure about deleting this Category?',
      ok: false,
      onOk: null,
      yes: true,
      onYes: async () => {
        const res = await deleteCategory(token, category._id);
        if (res && res.success) {
          closeModal(true);
        } else {
          setErrorWarningModalOptions({
            open: true,
            message: 'Category could not be deleted. Please try again.',
          });
          setIsLoading(false);
        }
      },
      no: true,
      onNo: () => {
        setIsLoading(false);
        setWarningModalOptions({
          open: false,
          title: '',
          message: '',
          ok: false,
          onOk: null,
          yes: false,
          onYes: null,
          no: false,
          onNo: null,
        });
      },
    });
  };

  const onPictureUpload = (e) => {
    if (e.target.files[0].size > 500000) {
      setErrorWarningModalOptions({
        open: true,
        message: 'Image is too big. Please choose an image up to 500 kB.',
      });
    } else {
      setPicture(URL.createObjectURL(e.target.files[0]));
      const reader = new FileReader();
      reader.onload = (upload) => setPictureBase64(upload.target.result);
      reader.readAsDataURL(e.target.files[0]);
      if (category) setIsSaveDisabled(false);
    }
  };

  const closeModal = (refreshNeeded) => {
    setWarningModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
    });
    setName('');
    setPicture(null);
    setPictureBase64(null);
    setIsLoading(false);
    setIsSaveDisabled(true);
    onClose(refreshNeeded);
  };

  return (
    <>
      <Modal open={open} dimmer="blurring" size="mini" closeOnEscape closeOnDimmerClick>
        <Modal.Header>
          {(category ? category.name : null) || 'New Category'}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal(false)}
            floated="right"
            circular
            size="big"
            className="tables-close-btn"
          />
        </Modal.Header>
        <Modal.Content scrolling>
          <Image
            size="small"
            src={picture ? picture : category ? config.cloudinaryUrlPrefix + category.image : ''}
            centered
          />
          {category ? <br /> : <></>}
          <p>Name</p>
          <Input
            fluid
            placeholder="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              if (category) setIsSaveDisabled(false);
            }}
          />
          <br />
          <input
            className="create-file-input withBorder"
            type="file"
            id="back"
            onChange={onPictureUpload}
            accept="image/*"
          />
          <label htmlFor="back" style={{ width: '100%', height: 40, position: 'relative' }}>
            {category ? 'Change Picture' : 'Add Picture'}
          </label>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={isLoading}
            content="Save"
            disabled={isSaveDisabled}
            floated="right"
            onClick={onSave}
            color="teal"
          />
          {category ? (
            <Button
              loading={isLoading}
              content="Delete"
              floated="left"
              onClick={onDelete}
              color="red"
            />
          ) : (
            <></>
          )}
        </Modal.Actions>
      </Modal>
      <Modal dimmer="blurring" size="tiny" open={errorWarningModalOptions.open}>
        <Modal.Header style={{ borderBottom: 'none' }}>
          <p>Error</p>
        </Modal.Header>
        <Modal.Content>
          <p style={{ fontWeight: 600 }}>{errorWarningModalOptions.message}</p>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
          <Button content="OK" color="teal" onClick={onErrorWarningModalOk} />
        </Modal.Actions>
      </Modal>
      <ErrorWarningModal
        open={warningModalOptions.open}
        title={warningModalOptions.title}
        message={warningModalOptions.message}
        ok={warningModalOptions.ok}
        onOk={warningModalOptions.onOk}
        yes={warningModalOptions.yes}
        onYes={warningModalOptions.onYes}
        no={warningModalOptions.no}
        onNo={warningModalOptions.onNo}
      />
    </>
  );
};

export default UpdateCategoriesModal;
