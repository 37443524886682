// url => string, server is defined in process.env
// header => JSON object
// body => JSON object

export const get = async (url, headers) => {
  const response = await fetch(url, {
    method: 'GET',
    headers: { ...headers, 'Content-Type': 'application/json' },
  });
  return response.json();
};

export const post = async (url, headers, body) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: { ...headers, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
  return response.json();
};

export const put = async (url, headers, body) => {
  const response = await fetch(url, {
    method: 'PUT',
    headers: { ...headers, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
  return response.json();
};

export const del = async (url, headers, body) => {
  const response = await fetch(url, {
    method: 'DELETE',
    headers: { ...headers, 'Content-Type': 'application/json' },
    body: body ? JSON.stringify(body) : null,
  });
  return response.json();
};
