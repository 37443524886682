/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { Modal, Button } from 'semantic-ui-react';
import { createTable, deleteTable, updateTable } from '../../../api/ShopAPI';
import StyledInput from '../../../components/general/Input/Input';
import Toggle from '../../../components/general/Toggle/Toggle';
import { useStateValue } from '../../../core/context/StateProvider';
import './TablesScreen.css';

const TablesScreenModal = (props) => {
  const { table, onClose, open, tableNumbers } = props;
  const [{ token, shop }, dispatch] = useStateValue();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [newNumber, setNewNumber] = useState({ number: '' });
  const [newDescription, setNewDescription] = useState({ description: '' });
  const [allowCoronaCheckIn, setAllowCoronaCheckIn] = useState(table ? table.coronaCheckIn : false);
  const [isTakeAway, setIsTakeAway] = useState(table ? table.isTakeAway : false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });

  useEffect(() => {
    setAllowCoronaCheckIn(table ? table.coronaCheckIn : false);
    setIsTakeAway(table ? table.isTakeAway : false);
  }, [table]);

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const onSave = async () => {
    const tableData = {};
    if (table) {
      if (newNumber.number) {
        if (tableNumbers.includes(parseInt(newNumber.number))) {
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('tablesNumberError'),
          });
        } else if (newNumber.number === '0') {
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('tablesZero'),
          });
        } else {
          tableData.number = newNumber.number;
        }
      }
      if (newDescription.description) {
        tableData.description = newDescription.description;
      }
      if (allowCoronaCheckIn !== table.coronaCheckIn) {
        tableData.coronaCheckIn = allowCoronaCheckIn;
      }
      if (isTakeAway !== table.isTakeAway) {
        tableData.isTakeAway = isTakeAway;
      }
      setIsLoading(true);
      const res = await updateTable(token, table._id, tableData);
      if (res && res.success) {
        setIsLoading(false);
        setIsSaveDisabled(true);
        closeModal(true);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('tablesUpdateError'),
        });
        setIsLoading(false);
        setIsSaveDisabled(true);
      }
    } else {
      const showMissingFieldModal = () => {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('tablesMandatoryFieldsError'),
        });
      };
      let dataIsAlright = true;
      if (newNumber.number) {
        if (newNumber.number === '0') {
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('tablesZero'),
          });
          dataIsAlright = false;
        } else if (tableNumbers.includes(parseInt(newNumber.number))) {
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('tablesNumberError'),
          });
          dataIsAlright = false;
        } else {
          tableData.number = newNumber.number;
          dataIsAlright = true;
        }
      } else {
        showMissingFieldModal();
        dataIsAlright = false;
      }
      if (newDescription.description) {
        tableData.description = newDescription.description;
      } else {
        showMissingFieldModal();
        dataIsAlright = false;
      }
      tableData.coronaCheckIn = allowCoronaCheckIn;
      tableData.isTakeAway = isTakeAway;
      if (dataIsAlright) {
        setIsLoading(true);
        const res = await createTable(token, tableData);
        if (res && res.success) {
          setIsLoading(false);
          setIsSaveDisabled(true);
          closeModal(true);
        } else {
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('tablesCreateError'),
          });
          setIsLoading(false);
          setIsSaveDisabled(true);
        }
      }
    }
  };

  const onDelete = async () => {
    if (
      shop.tables.length === 1 &&
      (shop.isInHouseTableAllowed === false || shop.isTakeAwayTableAllowed === false)
    ) {
      setErrorWarningModalOptions({
        open: true,
        message: `${i18n.t('tablesDeleteError')} ${i18n.t('lastTableError')}`,
      });
    } else {
      const res = await deleteTable(token, table._id);
      if (res && res.success) {
        closeModal(true);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: `${i18n.t('tablesDeleteError')} ${i18n.t('unpaidTableError')}`,
        });
      }
    }
  };

  const closeModal = (refreshNeeded) => {
    setNewDescription({ description: '' });
    setNewNumber({ number: '' });
    setAllowCoronaCheckIn(false);
    setIsTakeAway(false);
    onClose(refreshNeeded);
  };

  return (
    <>
      <Modal open={open} dimmer="blurring" size="tiny" closeOnEscape closeOnDimmerClick>
        <Modal.Header style={{ wordBreak: 'break-all', borderBottom: 'none' }}>
          {(table ? table.description : null) || i18n.t('tableModalTitle')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal(false)}
            floated="right"
            circular
            size="big"
            className="tables-close-btn"
          />
        </Modal.Header>
        <Modal.Content scrolling>
          <div className="items-modal-header">
            <StyledInput
              id="description"
              title={table ? table.description : null}
              placeholder={i18n.t('tableDescription')}
              onChangeTitle={(value) => {
                const trimmedValue = value != null ? value.trim() : value;
                if (trimmedValue != null && trimmedValue.length > 0) {
                  setNewDescription({ description: value });
                  setIsSaveDisabled(false);
                }
              }}
            />
            <StyledInput
              id="number"
              title={table ? table.number : null}
              placeholder="#"
              type="number"
              onChangeTitle={(value) => {
                if (value) {
                  setNewNumber({ number: value });
                  setIsSaveDisabled(false);
                }
              }}
            />
          </div>
          <br />
          <div style={{ display: 'flex' }}>
            <p style={{ fontSize: 16 }}>Take-Away QR-Code</p>
            <div style={{ right: '26px' }}>
              <Toggle
                onChange={(e) => {
                  setIsTakeAway(e.target.checked);
                  setIsSaveDisabled(false);
                }}
                checked={isTakeAway}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
          <Button
            loading={isLoading}
            content={i18n.t('tableModalSave')}
            disabled={isSaveDisabled}
            floated="right"
            onClick={onSave}
            color="teal"
          />
          {table ? (
            <Button
              loading={isLoading}
              content={i18n.t('itemsDelete')}
              floated="left"
              onClick={onDelete}
              color="red"
            />
          ) : (
            <></>
          )}
        </Modal.Actions>
      </Modal>
      <Modal dimmer="blurring" size="tiny" open={errorWarningModalOptions.open}>
        <Modal.Header style={{ borderBottom: 'none' }}>
          <p>{i18n.t('newPrinterErrorTitle')}</p>
        </Modal.Header>
        <Modal.Content>
          <p style={{ fontWeight: 600 }}>{errorWarningModalOptions.message}</p>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
          <Button content="OK" color="teal" onClick={onErrorWarningModalOk} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default TablesScreenModal;
