/* eslint-disable no-use-before-define */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';

function MultiInput({ numberOfDigits, defaultValue, onValueChange }) {
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(''));
  const otpBoxReference = useRef([]);

  useEffect(() => {
    setDefaultValue(defaultValue ? defaultValue.split('') : new Array(numberOfDigits).fill(''));
  }, []);

  function setDefaultValue(value) {
    setOtp(value);
  }

  function handleChange(event, index) {
    const value = event.nativeEvent.data || '';
    const newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);
    onValueChange(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === 'Backspace' && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === 'Enter' && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  return (
    <div style={styles.container}>
      {otp.map((digit, index) => (
        <input
          key={`${index}otp`}
          value={digit}
          maxLength={2}
          onChange={(e) => handleChange(e, index)}
          onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
          ref={(reference) => {
            otpBoxReference.current[index] = reference;
          }}
          style={styles.input}
        />
      ))}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    gap: '4px',
    marginTop: '0px',
    flexWrap: 'wrap',
  },
  input: {
    width: '22px',
    height: '32px',
    textAlign: 'center',
    fontSize: '20px',
    border: '1px solid #aaa',
    borderRadius: '4px',
    caretColor: '#7268cd',
    outline: 'none',
  },
};

export default MultiInput;
