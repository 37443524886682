/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React from 'react';
import i18n from 'i18next';
import { Loader } from 'semantic-ui-react';
import { Bar } from 'react-chartjs-2';
import { abbreviate } from '../../../utils/NumberFormatter';
import './HorizontalBarChartTile.css';

// HorizontalBarChartTile component.
const HorizontalBarChartTile = (props) => {
  const { data, details, loading } = props;
  let chartData;
  let indexOfLongestArray;
  if (loading === false && data != null) {
    if (data.data.filter((current) => current.length !== 0).length === 0) {
      indexOfLongestArray = i18n.t('dashBoardTopTenItemsNoData');
    } else {
      indexOfLongestArray = data.data.reduce((p, c, i, a) => (a[p].length > c.length ? p : i), 0);
    }
    const datasets = data.data.flatMap((hour) => (hour.length > 0 ? hour.length : []));
    chartData = (canvas) => {
      const ctx = canvas.getContext('2d');
      const gradient = ctx.createLinearGradient(0, 0, 0, 350);
      gradient.addColorStop(0, 'rgba(99, 164, 255, 0.9)');
      gradient.addColorStop(1, 'rgba(131, 234, 241, 0.5)');
      return {
        labels: data.data.flatMap((hour, index) => (hour.length > 0 ? `${index}` : [])),
        datasets: [
          {
            data: datasets,
            backgroundColor: gradient,
            borderColor: gradient,
            borderWidth: 2,
            borderRadius: 5,
            borderSkipped: false,
          },
        ],
      };
    };
  }
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: { display: false },
    tooltips: {
      displayColors: false,
      titleFontSize: 16,
      bodyFontSize: 14,
      xPadding: 10,
      yPadding: 10,
      backgroundColor: 'rgba(99, 164, 255, 1)',
      callbacks: {
        label: (tooltipItem) => `${abbreviate(tooltipItem.value)} ${i18n.t('bookingsTitle')}`,
        title: (current) => `${current[0].label}`,
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            color: 'rgba(0, 0, 0, 0.05)',
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            callback: (value) => abbreviate(value),
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: '#fff',
            zeroLineColor: '#fff',
          },
        },
      ],
    },
  };

  return (
    <>
      <div className="horizontal-bar-chart-container">
        <div className="horizontal-bar-content-container">
          <div className="horizontal-bar-chart-header">
            <p className="horizontal-bar-chart-title">{details.title}</p>
            <div
              className="horizontal-bar-chart-image-container"
              style={{
                backgroundColor: `${details.imageBackground}1F`,
              }}
            >
              <img className="horizontal-bar-chart-image" src={details.image} alt="" />
            </div>
          </div>
          {loading ? (
            <Loader size="massive" active={loading} inverted className="bookings-create-loader" />
          ) : (
            <>
              {data ? (
                <>
                  <div className="horizontal-bar-chart-legend">
                    <p className="horizontal-bar-chart-legend-title">{data.legendTitle}</p>
                    <p className="horizontal-bar-chart-legend-value">
                      {`${indexOfLongestArray} ${
                        typeof indexOfLongestArray === 'string' ? '' : i18n.t('oClock')
                      }`}
                    </p>
                  </div>
                  <div className="horizontal-bar-chart-bar">
                    {typeof indexOfLongestArray !== 'string' ? (
                      <>
                        <Bar data={chartData} height={354} options={options} />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <p style={{ fontWeight: 600, fontSize: 15, marginTop: 35 }}>
                    {`${i18n.t('dataError')}.`}
                  </p>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default HorizontalBarChartTile;
