/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useParams } from 'react-router-dom';
import { Form, Button, Confirm } from 'semantic-ui-react';
import validator from 'validator';
import {
  forgotPassword,
  forgotPasswordComplete,
  updatePasswordComplete,
} from '../../../api/UserAPI';
import { useStateValue } from '../../../core/context/StateProvider';
import Navbar from '../../../components/general/Navbar/Navbar';
import './LoginScreen.css';

const PasswordForgotScreen = (props) => {
  const { hash, token } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [confirmOptions, setConfirmOptions] = useState({
    open: false,
    message: '',
    title: '',
    customOk: null,
  });
  const { resetToken } = useParams();

  useEffect(() => {});

  const resetPw = async () => {
    if (validator.isEmail(email)) {
      const res = await forgotPassword(email);
      if (res && res.success) {
        setConfirmOptions({
          open: true,
          title: i18n.t('passwordResetTitle'),
          message: i18n.t('passwordResetMessage'),
          customOk: null,
        });
      } else {
        setConfirmOptions({
          open: true,
          title: i18n.t('error'),
          message: i18n.t('passwordResetError'),
          customOk: null,
        });
      }
    } else {
      setConfirmOptions({
        open: true,
        title: i18n.t('error'),
        message: i18n.t('passwordResetValidEmailError'),
        customOk: null,
      });
    }
  };

  const updatePwComplete = async () => {
    if (hash && token) {
      const res = await updatePasswordComplete(token, hash);
      if (res && res.success) {
        setConfirmOptions({
          open: true,
          title: i18n.t('passwordResetTitle'),
          message: i18n.t('passwordResetSuccess'),
          customOk: () => {
            window.location.href = `${process.env.REACT_APP_URL_PREFIX}${process.env.REACT_APP_SHOPS_SUBDOMAIN}.${process.env.REACT_APP_QR_WEBSITE}/login`;
          },
        });
      } else {
        setConfirmOptions({
          open: true,
          title: i18n.t('error'),
          message: i18n.t('passwordResetError'),
          customOk: null,
        });
      }
    } else {
      setConfirmOptions({
        open: true,
        title: i18n.t('error'),
        message: i18n.t('passwordMatchError'),
        customOk: null,
      });
    }
  };

  const resetPwComplete = async () => {
    if (password && passwordRepeat && password === passwordRepeat) {
      const res = await forgotPasswordComplete(resetToken, password);
      if (res && res.success) {
        setConfirmOptions({
          open: true,
          title: i18n.t('passwordResetTitle'),
          message: i18n.t('passwordResetSuccess'),
          customOk: () => {
            window.location.href = `${process.env.REACT_APP_URL_PREFIX}${process.env.REACT_APP_SHOPS_SUBDOMAIN}.${process.env.REACT_APP_QR_WEBSITE}/login`;
          },
        });
      } else {
        setConfirmOptions({
          open: true,
          title: i18n.t('error'),
          message: i18n.t('passwordResetError'),
          customOk: null,
        });
      }
    } else {
      setConfirmOptions({
        open: true,
        title: i18n.t('error'),
        message: i18n.t('passwordMatchError'),
        customOk: null,
      });
    }
  };

  return (
    <>
      <div className="login-navbar">
        <Navbar isShop items={[]} isLogin />
      </div>
      <div className="container">
        {resetToken ? (
          <>
            <div className="login-text">{i18n.t('passwordNewPasswordTitle')}</div>
            <Form loading={isLoading}>
              <Form.Input
                className="login-input"
                placeholder={i18n.t('employeeModalNewPassword')}
                name="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                icon="lock"
                iconPosition="left"
                type="password"
              />
              <Form.Input
                className="login-input"
                placeholder={i18n.t('employeeModalNewPasswordRepeat')}
                name="passwordRepeat"
                value={passwordRepeat}
                onChange={(e) => {
                  setPasswordRepeat(e.target.value);
                }}
                icon="lock"
                iconPosition="left"
                type="password"
              />
              <Button type="submit" onClick={resetPwComplete} basic className="login-button" fluid>
                {i18n.t('passwordCreateButton')}
              </Button>
            </Form>
          </>
        ) : hash && token ? (
          <>
            <div className="login-text">{i18n.t('passwordResetTitle')}</div>
            <p style={{ fontSize: '12px', margin: '-10px auto', display: 'table' }}>
              {i18n.t('passwordResetConfirm')}
            </p>
            <br />
            <Button type="submit" onClick={updatePwComplete} basic className="login-button" fluid>
              {i18n.t('confirm')}
            </Button>
          </>
        ) : (
          <>
            <div className="login-text">{i18n.t('forgotPasswordButton')}</div>
            <p style={{ fontSize: '12px', margin: '-10px auto', display: 'table' }}>
              {i18n.t('passwordForgotLinkSend')}
            </p>
            <br />
            <Form loading={isLoading}>
              <Form.Input
                className="login-input"
                placeholder="E-mail"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                icon="mail"
                iconPosition="left"
              />
              <Button type="submit" onClick={resetPw} basic className="login-button" fluid>
                {i18n.t('passwordResetTitle')}
              </Button>
            </Form>
          </>
        )}
        <Confirm
          dimmer="blurring"
          size="mini"
          open={confirmOptions.open}
          content={confirmOptions.message}
          cancelButton={null}
          header={confirmOptions.title}
          confirmButton={{ basic: true, content: 'OK', positive: true }}
          onConfirm={
            confirmOptions.customOk
              ? () => confirmOptions.customOk()
              : () => {
                  setConfirmOptions({ open: false, message: '' });
                }
          }
        />
      </div>
    </>
  );
};

export default PasswordForgotScreen;
