/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import i18n from 'i18next';
import sortBy from 'lodash/sortBy';
import { Table, Grid, Search, Button, Checkbox } from 'semantic-ui-react';
import { cloneDeep } from 'lodash';
import { useMediaQuery } from '@react-hook/media-query';
import QRCodeStyling from 'qr-code-styling';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';
import { useStateValue } from '../../../core/context/StateProvider';
import './TablesScreen.css';
import { createQRPDF, deleteTables, getTables } from '../../../api/ShopAPI';
import TablesScreenModal from './TablesScreenModal';
import LazyImage from '../../../components/general/LazyImage/LazyImage';
import TableOverviewModal from './TableOverviewModal';
import logo from '../Settings/logoblack.png';
import TablesScreenUploadModal from './TablesScreenUploadModal';

const TablesScreen = () => {
  const [{ token, shop }] = useStateValue();
  const [modalOptions, setModalOptions] = useState({ table: null, open: false });
  const [tableOverviewModalOptions, setTableOverviewModalOptions] = useState({ open: false });
  const [searchValue, setSearchValue] = useState('');
  const [allTables, setAllTables] = useState(null);
  const [refreshRequired, setRefreshRequired] = useState(false);
  const [uploadModalOptions, setUploadModalOptions] = useState({ open: null });
  const [isTablesAvailable, setIsTablesAvailable] = useState(true);
  const [selectedForDeletion, setSelectedForDeletion] = useState([]);
  const [tableData, setTableData] = useState({
    tables: [],
    selectedColumn: null,
    sortDirection: 'descending',
    isSearching: false,
    searchTerm: '',
  });
  const [errorModalOptions, setErrorModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
  });
  const [warningModalOptions, setWarningModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
  });

  const matches = useMediaQuery('only screen and (min-width: 1005px)');

  useEffect(() => {
    if (tableData) {
      if (!tableData.tables.length && !tableData.isSearching && isTablesAvailable) {
        getAllTables();
      }
    }
    if (tableData == null && !refreshRequired) {
      setErrorModalOptions({
        open: true,
        title: i18n.t('error'),
        message: i18n.t('tablesLoadingError'),
        ok: true,
        onOk: () => {
          closeErrorModal();
        },
        yes: false,
        onYes: null,
        no: false,
        onNo: null,
      });
      setRefreshRequired(true);
    }
  });

  const closeErrorModal = () => {
    setErrorModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
    });
  };

  const closeWarningModal = () => {
    setWarningModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
    });
  };

  const getAllTables = async () => {
    try {
      const res = await getTables(token);
      if (res && res.success) {
        if (res.tables.length === 0) {
          setIsTablesAvailable(false);
        }
        setTableData({
          tables: res.tables,
          selectedColumn: null,
          sortDirection: 'descending',
          isSearching: false,
          searchTerm: '',
        });
        setAllTables(res.tables);
      } else {
        setTableData(null);
      }
    } catch (_) {
      setTableData(null);
    }
  };

  const onCloseModal = async (refreshNeeded) => {
    setModalOptions({ table: null, open: false });
    setUploadModalOptions({ open: false });
    if (refreshNeeded) {
      setTableData({ ...tableData, tables: [], searchTerm: '' });
      setSearchValue('');
      setIsTablesAvailable(true);
    }
  };

  const onCloseTableOverviewModal = async (refreshNeeded) => {
    setTableOverviewModalOptions({ open: false });
    if (refreshNeeded) {
      setTableData({ ...tableData, tables: [] });
      setIsTablesAvailable(true);
    }
  };

  const onSelectForDelete = (index) => {
    const indexOfSelected = selectedForDeletion.findIndex((selected) => selected === index);
    if (indexOfSelected !== -1) {
      const selectedArrayCpy = cloneDeep(selectedForDeletion);
      selectedArrayCpy.splice(indexOfSelected, 1);
      setSelectedForDeletion(selectedArrayCpy);
    } else {
      setSelectedForDeletion([...selectedForDeletion, index]);
    }
  };

  const createTableCode = async (table) => {
    const qrCodeOptions = {
      width: 800,
      height: 800,
      data: `${process.env.REACT_APP_URL_PREFIX}${process.env.REACT_APP_CUSTOMER_SUBDOMAIN}.${process.env.REACT_APP_QR_WEBSITE}/${table.code}`,
      margin: 0,
      qrOptions: { typeNumber: '0', mode: 'Byte', errorCorrectionLevel: 'Q' },
      imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
      dotsOptions: { type: 'extra-rounded', color: '#000000', gradient: null },
      backgroundOptions: { color: '#ffffff' },
      image: logo,
      dotsOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: '#000000',
          color2: '#000000',
          rotation: '0',
        },
      },
      cornersSquareOptions: { type: 'extra-rounded', color: '#000000' },
      cornersSquareOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: '#000000',
          color2: '#000000',
          rotation: '0',
        },
      },
      cornersDotOptions: { type: '', color: '#000000' },
      cornersDotOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: '#000000',
          color2: '#000000',
          rotation: '0',
        },
      },
      backgroundOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: '#ffffff',
          color2: '#ffffff',
          rotation: '0',
        },
      },
    };
    const qr = new QRCodeStyling(qrCodeOptions);
    await qr._canvasDrawingPromise;
    const qrImage = qr._canvas.toDataURL('image/png', 90);
    return qrImage;
  };

  const triggerQRCodeCreate = async (table) => {
    try {
      const tableDesc = `${table.description} ${table.number ? `(${table.number})` : ''}`;
      const code = await createTableCode(table);
      await createQRPDF(tableDesc, code, token);
    } catch (error) {
      setErrorModalOptions({
        open: true,
        title: 'Error',
        message: error,
        ok: true,
        onOk: () => closeErrorModal(),
        yes: false,
        onYes: null,
        no: false,
        onNo: null,
      });
    }
  };

  const parseTables = (tables) =>
    tables.map((table, index) => (
      <Fragment key={`${index}frag`}>
        <Table.Row
          key={table._id}
          style={{ backgroundColor: selectedForDeletion.includes(index) ? '#7268cd1f' : '' }}
        >
          <Table.Cell
            key={`${table._id}11`}
            width="1"
            selectable={false}
            verticalAlign="middle"
            textAlign="center"
            onClick={() => onSelectForDelete(index)}
          >
            <Checkbox
              onChange={() => onSelectForDelete(index)}
              checked={selectedForDeletion.includes(index)}
            />
          </Table.Cell>
          <Table.Cell
            key={`${table._id}1`}
            collapsing
            verticalAlign="middle"
            onClick={() => setModalOptions({ table, open: true })}
          >
            {table.code ? (
              <LazyImage
                key={`${table.code}1`}
                style={{ height: 100, width: 100, margin: '0 auto', display: 'table' }}
                alt=""
                size="medium"
                data={`${process.env.REACT_APP_URL_PREFIX}${process.env.REACT_APP_CUSTOMER_SUBDOMAIN}.${process.env.REACT_APP_QR_WEBSITE}/${table.code}`}
                name={`moaby-tisch-${table.number}-${shop.name}.png`}
              />
            ) : (
              <></>
            )}
          </Table.Cell>
          <Table.Cell
            key={`${table._id}2`}
            collapsing
            onClick={() => setModalOptions({ table, open: true })}
          >
            {table.description}
          </Table.Cell>
          <Table.Cell
            key={`${table._id}4`}
            collapsing
            onClick={() => setModalOptions({ table, open: true })}
          >
            {table.number}
          </Table.Cell>
          <Table.Cell key={`${table._id}5`} collapsing verticalAlign="middle" textAlign="center">
            <Button
              key={`${table._id}5bttn`}
              icon="print"
              onClick={() => {
                triggerQRCodeCreate(table);
              }}
              color="teal"
            />
          </Table.Cell>
        </Table.Row>
      </Fragment>
    ));

  const handleSort = async (selectedColumn) => {
    const isSearching = tableData.isSearching ? tableData.isSearching : false;
    if (tableData.selectedColumn !== selectedColumn) {
      const tables = sortBy(tableData.tables, [selectedColumn]);
      setTableData({
        tables,
        selectedColumn,
        sortDirection: 'ascending',
        isSearching,
        searchTerm: tableData.searchTerm,
      });
      return;
    }
    const tables = tableData.tables.slice().reverse();
    setTableData({
      tables,
      selectedColumn,
      sortDirection: tableData.sortDirection === 'ascending' ? 'descending' : 'ascending',
      isSearching,
      searchTerm: tableData.searchTerm,
    });
  };

  const handleSearch = async (searchTerm) => {
    setSearchValue(searchTerm);
    if (tableData.tables) {
      if (searchTerm.length === 0) {
        await getAllTables();
        setSearchValue('');
      } else {
        const loweredSearchTerm = searchTerm.toLowerCase();
        const searchResults = allTables.filter(
          (table) =>
            table.number.toString().includes(loweredSearchTerm) ||
            table.description.toLowerCase().includes(loweredSearchTerm)
        );
        if (searchResults) {
          setTableData({
            tables: searchResults,
            selectedColumn: tableData.selectedColumn,
            sortDirection: tableData.sortDirection,
            isSearching: true,
            searchTerm,
          });
        }
      }
    }
  };

  const onDeleteTables = async (tableIds) => {
    try {
      setWarningModalOptions({
        open: true,
        title: i18n.t('deleteTables'),
        message: i18n.t('deleteTablesWarning'),
        ok: false,
        onOk: null,
        yes: true,
        onYes: async () => {
          if (
            shop.tables.length === 1 &&
            (shop.isInHouseTableAllowed === false || shop.isTakeAwayTableAllowed === false)
          ) {
            setErrorModalOptions({
              open: true,
              title: i18n.t('error'),
              message: i18n.t('deleteTablesError1'),
              ok: true,
              onOk: () => {
                closeErrorModal();
                closeWarningModal();
              },
              yes: false,
              onYes: null,
              no: false,
              onNo: null,
            });
          } else {
            const res = await deleteTables(token, tableIds);
            setSelectedForDeletion([]);
            if (res && res.success) {
              await getAllTables();
              closeWarningModal();
            } else {
              setErrorModalOptions({
                open: true,
                title: i18n.t('error'),
                message: i18n.t('deleteTablesError2'),
                ok: true,
                onOk: () => {
                  closeErrorModal();
                  closeWarningModal();
                },
                yes: false,
                onYes: null,
                no: false,
                onNo: null,
              });
            }
          }
        },
        no: true,
        onNo: () => {
          closeWarningModal();
        },
      });
    } catch (error) {
      setErrorModalOptions({
        open: true,
        title: i18n.t('error'),
        message: i18n.t('deleteTablesError3'),
        ok: true,
        onOk: () => {
          closeErrorModal();
          closeWarningModal();
        },
        yes: false,
        onYes: null,
        no: false,
        onNo: null,
      });
    }
  };

  return (
    <>
      <div className="cockpit-title">
        <p className="cockpit-title">{i18n.t('tablesHeading')}</p>
        <p className="cockpit-subtitle">{i18n.t('tablesSubtitle')}</p>
      </div>
      <div className="tables-container">
        <Grid stackable columns={1}>
          <Grid.Column>
            <div className="tables-header-container">
              <div className="tables-search">
                <Search
                  placeholder={i18n.t('tablesSearch')}
                  className="tables-searchbar"
                  onSearchChange={(_, data) => handleSearch(data.value)}
                  open={false}
                  value={searchValue}
                />
              </div>
              <div className="items-pagination">
                <div className="items-pagination-center">
                  {selectedForDeletion.length > 0 && (
                    <Button
                      icon="trash"
                      color="teal"
                      style={{ height: 38, width: 38 }}
                      onClick={() => {
                        const tableIds = tableData.tables
                          .map((table, index) => {
                            if (selectedForDeletion.includes(index)) {
                              return table._id.toString();
                            }
                            return null;
                          })
                          .filter((table) => table !== null);
                        onDeleteTables(tableIds);
                      }}
                    />
                  )}
                </div>
              </div>
              <div
                className="tables-dropdown"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                {matches && (
                  <>
                    <Button
                      content={i18n.t('tablePlan')}
                      id="items-btn"
                      className="items-btn"
                      onClick={() => setTableOverviewModalOptions({ open: true })}
                    />
                    <div
                      style={{
                        paddingLeft: 10,
                        height: 17,
                        textAlign: 'center',
                        margin: 'auto 0',
                        borderRight: '2px solid #7268cd',
                      }}
                    />
                  </>
                )}
                <Button
                  content={i18n.t('create')}
                  id="tables-btn"
                  className="tables-btn"
                  onClick={() => setModalOptions({ open: true, item: null })}
                />
                <div
                  style={{
                    paddingLeft: 10,
                    height: 17,
                    textAlign: 'center',
                    margin: 'auto 0',
                    borderRight: '2px solid #7268cd',
                  }}
                />
                <Button
                  content={i18n.t('upload')}
                  id="tables-btn"
                  className="tables-btn"
                  onClick={() => setUploadModalOptions({ open: true })}
                />
              </div>
            </div>
            <div className="tables-table-container">
              <Table fixed celled selectable padded sortable className="tables-table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      key="1111"
                      width="1"
                      verticalAlign="middle"
                      textAlign="center"
                    >
                      <Checkbox
                        onChange={() => {
                          if (
                            tableData &&
                            tableData.tables &&
                            selectedForDeletion.length !== tableData.tables.length
                          ) {
                            setSelectedForDeletion([...Array(tableData.tables.length).keys()]);
                          } else {
                            setSelectedForDeletion([]);
                          }
                        }}
                        checked={
                          tableData &&
                          tableData.tables &&
                          selectedForDeletion.length === tableData.tables.length &&
                          tableData.tables.length !== 0
                        }
                        disabled={
                          (tableData && tableData.tables && tableData.tables.length === 0) ||
                          tableData === null
                        }
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell>{i18n.t('tablesCode')}</Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={
                        tableData
                          ? tableData.selectedColumn === 'description'
                            ? tableData.sortDirection
                            : null
                          : null
                      }
                      onClick={() => handleSort('description')}
                    >
                      {i18n.t('tableDescription')}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={
                        tableData
                          ? tableData.selectedColumn === 'number'
                            ? tableData.sortDirection
                            : null
                          : null
                      }
                      onClick={() => handleSort('number')}
                    >
                      {i18n.t('tableNumber')}
                    </Table.HeaderCell>
                    <Table.HeaderCell width="2">{i18n.t('tStand')}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {tableData && tableData.tables.length > 0 ? parseTables(tableData.tables) : []}
                </Table.Body>
              </Table>
            </div>
          </Grid.Column>
        </Grid>
        <ErrorWarningModal
          open={errorModalOptions.open}
          title={errorModalOptions.title}
          message={errorModalOptions.message}
          ok={errorModalOptions.ok}
          onOk={errorModalOptions.onOk}
          yes={errorModalOptions.yes}
          onYes={errorModalOptions.onYes}
          no={errorModalOptions.no}
          onNo={errorModalOptions.onNo}
        />
        <ErrorWarningModal
          open={warningModalOptions.open}
          title={warningModalOptions.title}
          message={warningModalOptions.message}
          ok={warningModalOptions.ok}
          onOk={warningModalOptions.onOk}
          yes={warningModalOptions.yes}
          onYes={warningModalOptions.onYes}
          no={warningModalOptions.no}
          onNo={warningModalOptions.onNo}
        />
        <TablesScreenModal
          open={modalOptions.open}
          table={modalOptions.table}
          onClose={onCloseModal}
          tableNumbers={
            tableData ? (tableData.tables ? tableData.tables.map((table) => table.number) : []) : []
          }
        />
        <TableOverviewModal
          open={tableOverviewModalOptions.open}
          onClose={onCloseTableOverviewModal}
        />
        <TablesScreenUploadModal open={uploadModalOptions.open} onClose={onCloseModal} />
      </div>
    </>
  );
};
export default TablesScreen;
