/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Loader, Popup } from 'semantic-ui-react';
import { abbreviate, asCurrency } from '../../../utils/NumberFormatter';
import './LineChartTile.css';
import i18n from '../../../i18n/config';

// LineChartsTile component.
const LineChartTile = (props) => {
  const { data, loading, details } = props;
  let chartData;
  let options;
  if (loading === false && data != null) {
    chartData = (canvas) => {
      const ctx = canvas.getContext('2d');
      const gradient = ctx.createLinearGradient(600, 0, 300, 300);
      gradient.addColorStop(0, 'rgba(120, 155, 255, 0.6)');
      gradient.addColorStop(1, 'rgba(244, 144, 128, 0.6)');
      return {
        labels: data.labels,
        datasets: [
          {
            label: data.leftLegendTitle,
            data: data.data[0],
            backgroundColor: gradient,
            borderColor: gradient,
            pointBorderColor: gradient,
            pointBackgroundColor: gradient,
            pointHoverBackgroundColor: gradient,
            pointHoverBorderColor: gradient,
            borderWidth: 2,
            pointRadius: 0,
          },
          {
            label: data.rightLegendTitle,
            data: data.data[1],
            borderColor: '#bbb',
            backgroundColor: 'rgba(187, 187, 187, 0.2)',
            borderWidth: 1,
            pointRadius: 0,
          },
        ],
      };
    };
    options = {
      maintainAspectRatio: false,
      responsive: true,
      legend: { display: false },
      tooltips: {
        displayColors: false,
        bodyFontSize: 16,
        xPadding: 10,
        yPadding: 10,
        backgroundColor: 'rgba(244, 144, 128, 0.9)',
        callbacks: {
          label: ({ value }) =>
            `${value < 1000 && value > 0 ? asCurrency(value / 100) : abbreviate(value / 100)} €`,
          title: () => '',
        },
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              color: 'rgba(0, 0, 0, 0.05)',
              drawBorder: false,
            },
            ticks: {
              callback: (value) =>
                value < 1000 && value > 0 ? asCurrency(value / 100) : abbreviate(value / 100),
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              color: '#fff',
              zeroLineColor: '#fff',
            },
          },
        ],
      },
    };
  }

  return (
    <>
      <div className="linechart-tile-container">
        <div className="linechart-tile-content-container">
          <div className="linechart-tile-header">
            <p className="linechart-tile-title">{details.title}</p>
            <div
              className="linechart-tile-image-container"
              style={{
                backgroundColor: `${details.imageBackground}1F`,
              }}
            >
              <img className="linechart-tile-image" src={details.image} alt="" />
            </div>
          </div>
          {loading ? (
            <Loader size="massive" active={loading} inverted className="bookings-create-loader" />
          ) : (
            <>
              {data ? (
                <>
                  <div className="linechart-legend">
                    <div className="linechart-legend-left">
                      <p className="linechart-legend-title">{data.leftLegendTitle}</p>
                      <Popup
                        position="bottom center"
                        hideOnScroll
                        content={`${asCurrency(data.leftLegendValue)} €`}
                        trigger={
                          <p className="linechart-legend-value-left">
                            <span className="linechart-currency-left">€ </span>
                            {Math.floor(data.leftLegendValue) < 1000 &&
                            Math.floor(data.leftLegendValue) > 0
                              ? asCurrency(data.leftLegendValue)
                              : abbreviate(data.leftLegendValue)}
                          </p>
                        }
                      />
                    </div>
                    <div className="linechart-legend-right">
                      <p className="linechart-legend-title">{data.rightLegendTitle}</p>
                      <Popup
                        position="bottom left"
                        hideOnScroll
                        content={`${asCurrency(data.rightLegendValue)} €`}
                        trigger={
                          <p className="linechart-legend-value-right">
                            <span className="linechart-currency-right">€ </span>
                            {data.rightLegendValue < 1000 && data.rightLegendValue > 0
                              ? asCurrency(data.rightLegendValue)
                              : abbreviate(data.rightLegendValue)}
                          </p>
                        }
                      />
                    </div>
                  </div>
                  <div className="linechart-tile-line">
                    <Line data={chartData} height={350} options={options} />
                  </div>
                </>
              ) : (
                <p style={{ fontWeight: 600, fontSize: 15, marginTop: 35 }}>
                  {`${i18n.t('dataError')}.`}
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default LineChartTile;
