/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { Grid } from 'semantic-ui-react';
import { useStateValue } from '../../../core/context/StateProvider';
import KPITile from '../../../components/shop/KPITile/KPITile';
import LineChartTile from '../../../components/shop/LineChartTile/LineChartTile';
import HorizontalBarChartTile from '../../../components/shop/HorizontalBarChartTile/HorizontalBarChartTile';
import {
  getKPIs,
  getDailyRevenuePerMonth,
  getTimesOfBooking,
  getTopTen,
  getCardvsCash,
  bookingsTodayProps,
  bookingsThisMonthProps,
  revenueTodayProps,
  revenueMonthProps,
  dailyRevenuePerMonthProps,
  timesOfBookingProps,
  topTenProps,
  cardVsCashProps,
} from './DashboardScreenData';
import './DashboardScreen.css';
import VerticalBarChartTile from '../../../components/shop/VerticalBarChartTile/VerticalBarChartTile';
import DoughnutChartTile from '../../../components/shop/DoughnutChartTile/DoughnutChartTile';

const DashboardScreen = () => {
  const [{ token }] = useStateValue();
  const [kpiData, setKpiData] = useState({
    bookingsToday: {},
    bookingsThisMonth: {},
    revenueToday: {},
    revenueThisMonth: {},
  });
  const [lineChartData, setLineChartData] = useState({});
  const [horBarChartData, setHorBarChartData] = useState({});
  const [verBarChartData, setVerBarChartData] = useState({});
  const [doughnutChartData, setDoughnutChartData] = useState({});

  const isEmpty = (value) =>
    Boolean(value && typeof value === 'object') && !Object.keys(value).length;

  useEffect(() => {
    if (
      isEmpty(kpiData.bookingsToday) &&
      isEmpty(kpiData.bookingsThisMonth) &&
      isEmpty(kpiData.revenueToday) &&
      isEmpty(kpiData.revenueThisMonth)
    ) {
      getKPIData();
    }
  }, [kpiData]);

  useEffect(() => {
    if (isEmpty(lineChartData)) {
      getLineChartData();
    }
  }, [lineChartData]);

  useEffect(() => {
    if (isEmpty(horBarChartData)) {
      getHorBarChartData();
    }
  }, [horBarChartData]);

  useEffect(() => {
    if (isEmpty(verBarChartData)) {
      getVerBarChartData();
    }
  }, [verBarChartData]);

  useEffect(() => {
    if (isEmpty(doughnutChartData)) {
      getDoughnutChartData();
    }
  }, [doughnutChartData]);

  const getKPIData = async () => {
    const kpis = await getKPIs(token);
    setKpiData(kpis);
  };

  const getLineChartData = async () => {
    const lineChartData = await getDailyRevenuePerMonth(token);
    setLineChartData(lineChartData);
  };

  const getHorBarChartData = async () => {
    const horBarChartData = await getTimesOfBooking(token);
    setHorBarChartData(horBarChartData);
  };

  const getVerBarChartData = async () => {
    const verBarChartData = await getTopTen(token);
    setVerBarChartData(verBarChartData);
  };

  const getDoughnutChartData = async () => {
    const doughnutChartData = await getCardvsCash(token);
    setDoughnutChartData(doughnutChartData);
  };

  return (
    <>
      <div className="cockpit-title">
        <p className="cockpit-title">{i18n.t('dashBoardTitle')}</p>
        <p className="cockpit-subtitle">{i18n.t('dashBoardSubTitle')}</p>
      </div>
      <div className="dashboard-container">
        <Grid doubling stackable columns={4}>
          <Grid.Column>
            <KPITile
              loading={isEmpty(kpiData.bookingsToday)}
              data={kpiData.bookingsToday}
              details={bookingsTodayProps}
              isCurrency={false}
            />
          </Grid.Column>
          <Grid.Column>
            <KPITile
              loading={isEmpty(kpiData.bookingsThisMonth)}
              data={kpiData.bookingsThisMonth}
              isCurrency={false}
              details={bookingsThisMonthProps}
            />
          </Grid.Column>
          <Grid.Column>
            <KPITile
              loading={isEmpty(kpiData.revenueToday)}
              data={kpiData.revenueToday}
              details={revenueTodayProps}
              isCurrency
            />
          </Grid.Column>
          <Grid.Column>
            <KPITile
              loading={isEmpty(kpiData.revenueThisMonth)}
              data={kpiData.revenueThisMonth}
              details={revenueMonthProps}
              isCurrency
            />
          </Grid.Column>
        </Grid>
        <Grid doubling stackable columns={2}>
          <Grid.Column width={10}>
            <LineChartTile
              loading={isEmpty(lineChartData)}
              data={lineChartData}
              details={dailyRevenuePerMonthProps}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <HorizontalBarChartTile
              loading={isEmpty(horBarChartData)}
              data={horBarChartData}
              details={timesOfBookingProps}
            />
          </Grid.Column>
        </Grid>
        <Grid doubling stackable columns={2}>
          <Grid.Column>
            <VerticalBarChartTile
              loading={isEmpty(verBarChartData)}
              data={verBarChartData}
              details={topTenProps}
            />
          </Grid.Column>
          <Grid.Column>
            <DoughnutChartTile
              loading={isEmpty(doughnutChartData)}
              data={doughnutChartData}
              details={cardVsCashProps}
            />
          </Grid.Column>
        </Grid>
      </div>
    </>
  );
};

export default DashboardScreen;
