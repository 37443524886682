/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import i18n from 'i18next';
import { Table, Grid, Loader, Modal, Button, Icon, Popup } from 'semantic-ui-react';
import moment from 'moment';
import { HorizontalBar, Line } from 'react-chartjs-2';
import ReactDatePicker from 'react-datepicker';
import { useMediaQuery } from '@react-hook/media-query';
import refreshIcon from './icons/refresh.png';
import downloadIcon from './icons/download.png';
import { useStateValue } from '../../../core/context/StateProvider';
import './BookingsScreen.css';
import { getCashBookExcel, getMonthlyReportPDF, getZBons } from '../../../api/BookingAPI';
import { abbreviate, asCurrency } from '../../../utils/NumberFormatter';
import ZBonScreenModal from './ZBonScreenModal';

const ReportsScreen = () => {
  const [{ token, shop }] = useStateValue();
  const [isLoadingZBons, setIsLoadingZBons] = useState(false);
  const [zBons, setZBons] = useState({ data: [] });
  const [lineChart, setLineChart] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(moment());
  const [zBonModalOptions, setZBonModalOptions] = useState({ open: false, zBon: null });
  const [horizontalChart, setHorizontalChart] = useState({});
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });

  const matches = useMediaQuery('only screen and (min-width: 1500px)');
  const mobileMatches = useMediaQuery('only screen and (min-width: 500px)');

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const onCloseZBonModal = () => {
    setZBonModalOptions({ open: false, date: null });
  };

  const isEmpty = (value) =>
    Boolean(value && typeof value === 'object') && !Object.keys(value).length;

  useEffect(() => {
    loadZBons(moment());
  }, []);

  const loadZBons = async (date) => {
    try {
      setIsLoadingZBons(true);
      setSelectedMonth(moment(date));
      const res = await getZBons(token, shop._id, moment(date).format());
      if (res && res.success) {
        setZBons({ data: res.zBons });
        const array = Array.from({ length: 31 }).fill(0);
        res.zBons.forEach((zBon) => {
          const day = Number(moment(zBon.issueDateStart).format('DD'));
          array[day - 1] = zBon.total;
        });
        setLineChart({
          labels: Array.from({ length: 31 }, (_, i) => i + 1),
          data: array,
        });
        const dataSet = [
          res.zBons.reduce((sum, { cash }) => sum + cash, 0),
          res.zBons.reduce((sum, { card }) => sum + card, 0),
        ];
        setHorizontalChart({
          topValue: Math.max(...dataSet),
          dataSet,
          labels: [i18n.t('dailySummaryBar'), i18n.t('dailySummaryKarte')],
        });
        setIsLoadingZBons(false);
      } else {
        setIsLoadingZBons(false);
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('zBonsError'),
        });
        setHorizontalChart(null);
        setLineChart(null);
      }
    } catch (error) {
      setIsLoadingZBons(false);
      setErrorWarningModalOptions({ open: true, message: i18n.t('zBonsError') });
    }
  };

  const lineChartData = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(600, 0, 300, 300);
    gradient.addColorStop(0, 'rgba(114, 104, 205, 0.5)');
    gradient.addColorStop(1, 'rgba(114, 104, 205, 1)');
    return {
      labels: lineChart.labels,
      datasets: [
        {
          data: lineChart.data,
          backgroundColor: gradient,
          borderColor: gradient,
          pointBorderColor: gradient,
          pointBackgroundColor: gradient,
          pointHoverBackgroundColor: gradient,
          pointHoverBorderColor: gradient,
          borderWidth: 2,
          pointRadius: 0,
        },
      ],
    };
  };

  const lineChartOptions = {
    maintainAspectRatio: true,
    responsive: true,
    legend: { display: false },
    tooltips: {
      displayColors: false,
      bodyFontSize: 16,
      xPadding: 10,
      yPadding: 10,
      backgroundColor: '#7268cd',
      callbacks: {
        label: ({ value }) =>
          `${value < 1000 && value > 0 ? asCurrency(value / 100) : abbreviate(value / 100)} €`,
        title: () => '',
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            color: 'rgba(0, 0, 0, 0.05)',
            drawBorder: false,
          },
          ticks: {
            callback: (value) =>
              value < 1000 && value > 0 ? asCurrency(value / 100) : abbreviate(value / 100),
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: '#fff',
            zeroLineColor: '#fff',
          },
        },
      ],
    },
  };

  const horizontalBarChartData = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 350);
    gradient.addColorStop(0, 'rgba(114, 104, 205, 0.6)');
    gradient.addColorStop(1, 'rgba(114, 104, 205, 1)');
    return {
      labels: horizontalChart.labels,
      datasets: [
        {
          data: horizontalChart.dataSet,
          backgroundColor: gradient,
          borderColor: gradient,
        },
      ],
    };
  };

  const horizontalBarChartOptions = {
    maintainAspectRatio: true,
    responsive: true,
    legend: { display: false },
    tooltips: {
      enabled: false,
    },
    scales: {
      yAxes: [
        {
          barPercentage: 0.65,
          gridLines: {
            display: false,
          },
          ticks: {
            callback: (value) =>
              value ? (value.length > 23 ? `${value.substr(0, 23)}...` : value) : value,
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 0.1,
          gridLines: {
            display: false,
          },
          ticks: {
            max: horizontalChart ? horizontalChart.topValue : null,
            callback: () => '',
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const downloadMonthlyReport = async () => {
    try {
      await getMonthlyReportPDF(token, shop._id, moment(selectedMonth).format());
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('monthlyReportError'),
      });
    }
  };

  const downloadCashBook = async () => {
    try {
      await getCashBookExcel(token, moment(selectedMonth).format());
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('monthlyReportError'),
      });
    }
  };

  const parseData = () =>
    zBons.data.map((zBon) => (
      <Fragment key={`${zBon.issueDateStart}div`}>
        <Table.Row
          onClick={() => {
            setZBonModalOptions({ open: true, zBon });
          }}
        >
          <Table.Cell style={{ borderLeft: 'none', fontWeight: '500', fontSize: 16 }}>
            {`${moment(zBon.issueDateStart).format('DD.MM.YYYY')}`}
            <p style={{ fontSize: 15, fontWeight: 500, color: '#888', marginTop: 6 }}>
              {`${moment(zBon.issueDateStart).format('HH:mm')} - ${moment(zBon.issueDateEnd).format(
                'HH:mm [Uhr]'
              )}`}
            </p>
          </Table.Cell>
          <Table.Cell
            collapsing
            verticalAlign="right"
            style={{ borderLeft: 'none', fontSize: 18, fontWeight: 700 }}
          >
            {`${asCurrency(zBon.total / 100)}€`}
            <p style={{ fontSize: 15, fontWeight: 500, color: '#888', marginTop: 6 }}>
              {`${asCurrency(zBon.taxes / 100)}€`}
            </p>
          </Table.Cell>
        </Table.Row>
      </Fragment>
    ));

  return (
    <>
      <div
        className="dashboard-container"
        style={{ margin: mobileMatches ? 20 : 0, maxWidth: 'unset' }}
      >
        <Grid columns={1} doubling stackable>
          <Grid.Column>
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 20,
                padding: 20,
                paddingTop: 20,
                height: 65,
                boxShadow: '#7268cd40 0px 4px 12px',
                textAlign: 'center',
                fontSize: 20,
                fontWeight: 700,
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Popup
                  trigger={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <p style={{ color: '#7268cd', fontSize: 16, marginTop: 3, cursor: 'pointer' }}>
                      {moment(selectedMonth).format('MMM YY').toUpperCase().replace('.', '')}
                      <Icon name="caret down" />
                    </p>
                  }
                  style={{ height: 220 }}
                  flowing
                  hoverable
                  position="bottom right"
                >
                  <Grid centered columns={1}>
                    <Grid.Column textAlign="center">
                      <p style={{ fontWeight: 600 }}>{i18n.t('monthSelection')}</p>
                      <div style={{ display: 'table', margin: '0 auto', height: 220 }}>
                        <ReactDatePicker
                          inline
                          locale="de"
                          container
                          wrapperClassName="datepicker"
                          showMonthYearPicker
                          selected={moment(selectedMonth).toDate()}
                          minDate={moment(shop && shop.created ? shop.created : null)
                            .startOf('month')
                            .toDate()}
                          maxDate={moment().toDate()}
                          onChange={async (selectedDate) => {
                            setSelectedMonth(moment(selectedDate));
                            await loadZBons(moment(selectedDate));
                          }}
                        />
                      </div>
                    </Grid.Column>
                  </Grid>
                </Popup>
                <p>{shop.name}</p>
                <div style={{ display: 'flex', gap: 10 }}>
                  <Popup
                    on="click"
                    trigger={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <img
                        src={downloadIcon}
                        alt=""
                        style={{ cursor: 'pointer', width: 22, height: 22, marginTop: 2 }}
                      />
                    }
                    flowing
                    hoverable
                  >
                    <Grid
                      centered
                      divided
                      columns={shop && shop.allowCashBook === true ? 2 : 1}
                      style={{ width: 300 }}
                    >
                      <Grid.Column textAlign="center">
                        <p style={{ fontWeight: 600, textAlign: 'center' }}>
                          {i18n.t('monthlyReport')}
                        </p>
                        <div style={{ display: 'flex', gap: 4, justifyContent: 'space-between' }}>
                          <Button
                            icon="file pdf outline"
                            color="teal"
                            fluid
                            content="PDF"
                            onClick={async () => {
                              await downloadMonthlyReport();
                            }}
                            disabled={zBons.data.length === 0}
                          />
                        </div>
                      </Grid.Column>
                      {shop && shop.allowCashBook === true ? (
                        <>
                          <Grid.Column textAlign="center">
                            <p style={{ fontWeight: 600 }}>{i18n.t('dailySummaryCashBook')}</p>
                            <div style={{ display: 'flex', gap: 4, justifyContent: 'center' }}>
                              <Button
                                icon="file pdf outline"
                                color="teal"
                                content="PDF"
                                fluid
                                onClick={async () => {
                                  await downloadCashBook();
                                }}
                                disabled={
                                  zBons.data.filter((zBon) => zBon.cashBook != null).length === 0
                                }
                              />
                            </div>
                          </Grid.Column>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Popup>
                  <div style={{ backgroundColor: '#7268cd', width: 1, height: 20, marginTop: 3 }} />
                  <img
                    src={refreshIcon}
                    alt=""
                    style={{ cursor: 'pointer', width: 22, height: 22, marginTop: 2 }}
                    onClick={async () => {
                      await loadZBons(moment());
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid.Column>
          <ZBonScreenModal
            onClose={onCloseZBonModal}
            open={zBonModalOptions.open}
            savedZBon={zBonModalOptions.zBon}
            withCoins={false}
          />
        </Grid>
        <Grid columns={matches ? 3 : 2} doubling stackable>
          <Grid.Column>
            <div
              style={{
                backgroundColor: 'white',
                paddingTop: 20,
                borderRadius: 20,
                marginRight: 6,
                marginLeft: 6,
                marginTop: 5,
                boxShadow: '#7268cd40 0px 4px 12px',
                height: 'calc(100vh - 220px)',
              }}
            >
              <p style={{ fontSize: 18, fontWeight: 700, paddingLeft: 20, marginBottom: 4 }}>
                {i18n.t('dailyReports')}
              </p>
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  paddingLeft: 20,
                  marginBottom: 10,
                  color: '#666',
                }}
              >
                {i18n.t('allZBonsTitle')}
              </p>
              <div
                style={{
                  border: 'none',
                  maxHeight: 'calc(100vh - 325px)',
                  top: 290,
                  bottom: 42,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                className="bookings-table-container-discount"
              >
                <Table selectable unstackable padded className="bookings-table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        width="2"
                        style={{ backgroundColor: 'white', borderLeft: 'none', fontSize: 16 }}
                      >
                        {i18n.t('dailySummaryDate')}
                        <p style={{ fontSize: 12, fontWeight: 500, color: '#666', marginTop: 1 }}>
                          {i18n.t('time')}
                        </p>
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        width="2"
                        verticalAlign="right"
                        textAlign="center"
                        style={{ backgroundColor: 'white', borderLeft: 'none', fontSize: 16 }}
                      >
                        {i18n.t('revenue')}
                        <p style={{ fontSize: 12, fontWeight: 500, color: '#666', marginTop: 1 }}>
                          {i18n.t('tax')}
                        </p>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <>
                      {parseData()}
                      <Loader
                        active={isLoadingZBons === true}
                        size="large"
                        style={{ marginTop: -100 }}
                        className="bookings-create-loader"
                      />
                    </>
                  </Table.Body>
                </Table>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div
              className="tax-scrollbar"
              style={{
                height: 'calc(100vh - 220px)',
                overflow: mobileMatches ? 'hidden' : 'visible',
              }}
            >
              <div
                className="tax-scrollbar"
                style={{
                  height: mobileMatches ? 'calc(100vh - 220px)' : '',
                  overflowY: mobileMatches ? 'scroll' : '',
                  marginBottom: 30,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 5,
                  paddingBottom: 90,
                }}
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    padding: 20,
                    borderRadius: 20,
                    boxShadow: '#7268cd40 0px 2px 8px',
                  }}
                >
                  <p style={{ fontSize: 18, fontWeight: 700 }}>{i18n.t('mwst')}</p>
                  {isLoadingZBons ? (
                    <>
                      <Loader
                        active={isLoadingZBons}
                        inline
                        inverted
                        className="bookings-create-loader"
                      />
                    </>
                  ) : (
                    <>
                      {zBons.data != null ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            gap: 10,
                            alignItems: 'center',
                          }}
                        >
                          <div>
                            <p
                              style={{
                                fontSize: 15,
                                fontWeight: 700,
                                color: '#7268cd',
                                textAlign: 'center',
                              }}
                            >
                              7%
                            </p>
                            <p
                              style={{
                                fontSize: 18,
                                fontWeight: 700,
                                textAlign: 'center',
                              }}
                            >
                              {`${asCurrency(
                                zBons.data.reduce(
                                  (sum, { reducedTaxesTotal }) => sum + reducedTaxesTotal,
                                  0
                                ) / 100
                              )}€`}
                            </p>
                          </div>
                          <div style={{ height: 40, width: 1, backgroundColor: '#7268cd' }} />
                          <div>
                            <p
                              style={{
                                fontSize: 15,
                                fontWeight: 700,
                                color: '#7268cd',
                                textAlign: 'center',
                              }}
                            >
                              19%
                            </p>
                            <p
                              style={{
                                fontSize: 18,
                                fontWeight: 700,
                                textAlign: 'center',
                              }}
                            >
                              {`${asCurrency(
                                zBons.data.reduce(
                                  (sum, { normalTaxesTotal }) => sum + normalTaxesTotal,
                                  0
                                ) / 100
                              )}€`}
                            </p>
                          </div>
                          <div style={{ height: 40, width: 1, backgroundColor: '#7268cd' }} />
                          <div>
                            <p
                              style={{
                                fontSize: 15,
                                fontWeight: 700,
                                color: '#7268cd',
                                textAlign: 'center',
                              }}
                            >
                              {i18n.t('total')}
                            </p>
                            <p
                              style={{
                                fontSize: 18,
                                fontWeight: 700,
                                textAlign: 'center',
                              }}
                            >
                              {`${asCurrency(
                                zBons.data.reduce((sum, { taxes }) => sum + taxes, 0) / 100
                              )}€`}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
                <br />
                <div
                  style={{
                    backgroundColor: 'white',
                    padding: 20,
                    borderRadius: 20,
                    boxShadow: '#7268cd40 0px 2px 8px',
                  }}
                >
                  <p style={{ fontSize: 18, fontWeight: 700 }}>{i18n.t('revenuePerDay')}</p>
                  {isLoadingZBons ? (
                    <>
                      <Loader
                        active={isLoadingZBons}
                        inline
                        inverted
                        className="bookings-create-loader"
                      />
                    </>
                  ) : (
                    <>
                      {isEmpty(lineChart) || lineChart == null ? (
                        <></>
                      ) : (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-around',
                              gap: 10,
                              alignItems: 'center',
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  fontSize: 14,
                                  fontWeight: 700,
                                  color: '#7268cd',
                                  textAlign: 'center',
                                }}
                              >
                                {i18n.t('lowest').toUpperCase()}
                              </p>
                              <p
                                style={{
                                  fontSize: 18,
                                  fontWeight: 700,
                                  textAlign: 'center',
                                }}
                              >
                                {`${asCurrency(
                                  Math.min(...zBons.data.map((zBon) => zBon.total), 0) / 100
                                )}€`}
                              </p>
                            </div>
                            <div style={{ height: 40, width: 1, backgroundColor: '#7268cd' }} />
                            <div>
                              <p
                                style={{
                                  fontSize: 14,
                                  fontWeight: 700,
                                  color: '#7268cd',
                                  textAlign: 'center',
                                }}
                              >
                                {i18n.t('highest').toUpperCase()}
                              </p>
                              <p
                                style={{
                                  fontSize: 18,
                                  fontWeight: 700,
                                  textAlign: 'center',
                                }}
                              >
                                {`${asCurrency(
                                  Math.max(...zBons.data.map((zBon) => zBon.total), 0) / 100
                                )}€`}
                              </p>
                            </div>
                          </div>
                          <br />
                          <Line data={lineChartData} options={lineChartOptions} height={130} />
                        </>
                      )}
                    </>
                  )}
                </div>
                <br />
                <div
                  style={{
                    backgroundColor: 'white',
                    padding: 20,
                    borderRadius: 20,
                    boxShadow: '#7268cd40 0px 2px 8px',
                  }}
                >
                  <p style={{ fontSize: 18, fontWeight: 700 }}>{i18n.t('cashVsCard')}</p>
                  {isLoadingZBons ? (
                    <>
                      <Loader
                        active={isLoadingZBons}
                        inline
                        inverted
                        className="bookings-create-loader"
                      />
                    </>
                  ) : (
                    <>
                      {isEmpty(horizontalChart) || horizontalChart == null ? (
                        <></>
                      ) : (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-around',
                              gap: 10,
                              alignItems: 'center',
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  fontSize: 14,
                                  fontWeight: 700,
                                  color: '#7268cd',
                                  textAlign: 'center',
                                }}
                              >
                                {i18n.t('dailySummaryBar').toUpperCase()}
                              </p>
                              <p
                                style={{
                                  fontSize: 18,
                                  fontWeight: 700,
                                  textAlign: 'center',
                                }}
                              >
                                {`${asCurrency(horizontalChart.dataSet[0] / 100)}€`}
                              </p>
                            </div>
                            <div style={{ height: 40, width: 1, backgroundColor: '#7268cd' }} />
                            <div>
                              <p
                                style={{
                                  fontSize: 14,
                                  fontWeight: 700,
                                  color: '#7268cd',
                                  textAlign: 'center',
                                }}
                              >
                                {i18n.t('dailySummaryKarte').toUpperCase()}
                              </p>
                              <p
                                style={{
                                  fontSize: 18,
                                  fontWeight: 700,
                                  textAlign: 'center',
                                }}
                              >
                                {`${asCurrency(horizontalChart.dataSet[1] / 100)}€`}
                              </p>
                            </div>
                          </div>
                          <br />
                          <HorizontalBar
                            data={horizontalBarChartData}
                            options={horizontalBarChartOptions}
                            height={90}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
                <br />
              </div>
            </div>
          </Grid.Column>
          {matches ? (
            <>
              <Grid.Column>
                <div
                  className="tax-scrollbar"
                  style={{
                    height: 'calc(100vh - 220px)',
                    overflow: 'hidden',
                  }}
                >
                  <div
                    className="tax-scrollbar"
                    style={{
                      height: 'calc(100vh - 220px)',
                      overflowY: 'scroll',
                      marginBottom: 30,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 5,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: 'white',
                        borderRadius: 20,
                        padding: 20,
                        boxShadow: '#7268cd40 0px 2px 8px',
                      }}
                    >
                      <p style={{ fontSize: 18, fontWeight: 700, marginBottom: 4 }}>
                        {i18n.t('general')}
                      </p>
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          marginBottom: 10,
                          color: '#666',
                        }}
                      >
                        {i18n.t('generalInfo')}
                      </p>
                      <p className="tax-key">{i18n.t('createdAt2')}</p>
                      <p className="tax-value">{moment(shop.created).format('DD.MM.YYYY')}</p>
                      <p className="tax-key">{i18n.t('vatNo')}</p>
                      <p className="tax-value">{`${shop.taxId}`}</p>
                      <p className="tax-key">{i18n.t('lastZbonNr')}</p>
                      <p className="tax-value">{`${shop.zBonNumber}`}</p>
                    </div>
                    <br />
                    <div
                      style={{
                        backgroundColor: 'white',
                        borderRadius: 20,
                        padding: 20,
                        boxShadow: '#7268cd40 0px 2px 8px',
                      }}
                    >
                      <p style={{ fontSize: 18, fontWeight: 700, marginBottom: 4 }}>
                        {i18n.t('ownerTitle')}
                      </p>
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          marginBottom: 10,
                          color: '#666',
                        }}
                      >
                        {i18n.t('ownerDesc')}
                      </p>
                      <p className="tax-key">{i18n.t('allNames')}</p>
                      <p className="tax-value">
                        {`${shop.shopOwner.firstName} ${shop.shopOwner.lastName}`}
                      </p>
                      <p className="tax-key">{i18n.t('ownerMail')}</p>
                      <p className="tax-value">{`${shop.shopOwner.email}`}</p>
                      <p className="tax-key">{i18n.t('ownerPhone')}</p>
                      <p className="tax-value">{`${shop.shopOwner.phoneNumber}`}</p>
                      <p className="tax-key">{i18n.t('shopAddressTitle')}</p>
                      <p className="tax-value">{shop.address.street}</p>
                      <p className="tax-value">
                        {`${shop.address.zip} `}
                        {shop.address.city}
                      </p>
                    </div>
                    <br />
                    <div
                      style={{
                        backgroundColor: 'white',
                        borderRadius: 20,
                        padding: 20,
                        boxShadow: '#7268cd40 0px 2px 8px',
                      }}
                    >
                      <p style={{ fontSize: 18, fontWeight: 700, marginBottom: 4 }}>
                        {i18n.t('informations')}
                      </p>
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          marginBottom: 10,
                          color: '#666',
                        }}
                      >
                        {i18n.t('moreInfo')}
                      </p>
                      <p className="tax-key">{i18n.t('sepa')}</p>
                      <p className="tax-value">
                        {`${
                          shop.hasSepa
                            ? i18n.t('bookingEditBookingWarningYes')
                            : i18n.t('bookingEditBookingWarningNo')
                        }`}
                      </p>
                      <p className="tax-key">{i18n.t('numberTables')}</p>
                      <p className="tax-value">{shop.tables ? shop.tables.length : 0}</p>
                      <p className="tax-key">{i18n.t('numberCardReaders')}</p>
                      <p className="tax-value">
                        {shop.terminalReaders ? shop.terminalReaders.length : 0}
                      </p>
                    </div>
                    <br />
                  </div>
                </div>
              </Grid.Column>
            </>
          ) : (
            <></>
          )}
        </Grid>
        <Modal dimmer="blurring" size="tiny" open={errorWarningModalOptions.open}>
          <Modal.Header style={{ borderBottom: 'none' }}>
            <p>{i18n.t('newPrinterErrorTitle')}</p>
          </Modal.Header>
          <Modal.Content className="unselectable">
            <p style={{ fontWeight: 600 }}>{errorWarningModalOptions.message}</p>
          </Modal.Content>
          <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
            <Button content="OK" color="teal" onClick={onErrorWarningModalOk} />
          </Modal.Actions>
        </Modal>
      </div>
    </>
  );
};
export default ReportsScreen;
