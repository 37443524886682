/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import React from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Button, Portal } from 'semantic-ui-react';

export const showToast = (options) => {
  const { message, title, position, duration, acknowledgable, loading, error, id } = options;
  const toastOptions = {
    duration: acknowledgable ? Infinity : duration || 4000,
    position: position || 'bottom-right',
    style: { boxShadow: `${error ? 'red' : '#7268cd'} 0px 0px 5px`, borderRadius: 10 },
    id,
  };

  if (loading) {
    toast.loading(
      (t) => <div style={{ fontWeight: 600, fontSize: 16 }}>{message}</div>,
      toastOptions
    );
  } else {
    toast(
      (t) => (
        <div>
          <div style={{ marginBottom: acknowledgable ? 14 : 0 }}>
            <p style={{ fontWeight: 700, fontSize: 16, marginBottom: 5 }}>{title}</p>
            {message}
          </div>
          {acknowledgable && (
            <Button
              floated="right"
              color={error ? 'red' : 'teal'}
              content="OK"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            />
          )}
        </div>
      ),
      toastOptions
    );
  }
};

export const Toast = () => (
  <>
    <Portal open>
      <div style={{ filter: 'unset' }}>
        <Toaster />
      </div>
    </Portal>
  </>
);
