/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import './Toggle.css';

const Toggle = (props) => {
  const { onChange, checked, readOnly, style } = props;
  return (
    <label className="switch" style={style}>
      <input type="checkbox" onChange={onChange} checked={checked} />
      <span className={readOnly ? 'slider-readOnly round' : 'slider round'} />
    </label>
  );
};

export default Toggle;
