import { get } from './Fetch';

export const getMe = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}me`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getConfig = async () => {
  const url = `${process.env.REACT_APP_SERVER_URL}config`;
  return get(url, {});
};
