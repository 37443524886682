/* eslint-disable use-isnan */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { Modal, Button, Input } from 'semantic-ui-react';
import { asCurrency } from '../../../utils/NumberFormatter';

const ErrorWarningModal = (props) => {
  const { title, message, open, ok, onOk, yes, onYes, no, onNo, amount } = props;
  const [change, setChange] = useState(0.0);
  const [current, setCurrent] = useState(0.0);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (open === false && isDisabled === true) {
      setIsDisabled(false);
    }
  });

  const calculateChange = (value) => {
    setCurrent(value);
    setChange(value - amount / 100);
  };

  return (
    <>
      <Modal open={open} dimmer="blurring" size="mini" closeOnEscape closeOnDimmerClick>
        <Modal.Header style={{ borderBottom: 'white' }}>{title}</Modal.Header>
        <Modal.Content>
          <p style={{ fontWeight: 600, wordBreak: 'break-word' }}>{message}</p>
          {amount ? (
            <>
              <br />
              <br />
              <p>{i18n.t('bookingModalPayWarningSubtitle')}</p>
              <Input
                type="number"
                placeholder={i18n.t('bookingModalPayWarningPaidAmount')}
                value={null}
                onChange={(e) => calculateChange(parseFloat(e.target.value.replace(',', '.')))}
              />
              <br />
              <br />
              <p style={{ wordBreak: 'break-all', fontWeight: 700 }}>
                {`${i18n.t('bookingModalPayWarningChange')} ${asCurrency(
                  Number.isNaN(change) ? 0 : change
                )} €`}
              </p>
            </>
          ) : (
            <></>
          )}
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
          {ok ? (
            <Button
              content="OK"
              color="teal"
              onClick={() => {
                onOk();
                setIsDisabled(true);
              }}
              disabled={isDisabled}
            />
          ) : (
            <></>
          )}
          {yes ? (
            <Button
              content={i18n.t('itemsDeleteWarningYes')}
              positive
              onClick={() => {
                setChange(0.0);
                setCurrent(0.0);
                onYes();
                setIsDisabled(true);
              }}
              disabled={isDisabled}
            />
          ) : (
            <></>
          )}
          {no ? (
            <Button
              content={i18n.t('itemsDelteWarningNo')}
              negative
              onClick={() => {
                setChange(0.0);
                setCurrent(0.0);
                onNo();
                setIsDisabled(true);
              }}
              disabled={isDisabled}
            />
          ) : (
            <></>
          )}
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ErrorWarningModal;
