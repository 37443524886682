/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { Modal, Button, Loader } from 'semantic-ui-react';
import { isEqual, countBy, findLastIndex, cloneDeep, sortBy, orderBy } from 'lodash';
import moment from 'moment';
import { useMediaQuery } from '@react-hook/media-query';
import { useStateValue } from '../../../core/context/StateProvider';
import './BookingsScreen.css';
import { asCurrency } from '../../../utils/NumberFormatter';
import { getMergeableBookings, mergeBookings, mergeBookingsPin } from '../../../api/BookingAPI';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';
import PinNumPad from '../../../components/shop/PinNumPad/PinNumPad';
import i18n from '../../../i18n/config';

const BookingsMergeModal = (props) => {
  const { onClose, open } = props;
  const [{ token, shop, user }, dispatch] = useStateValue();
  const [isLoadingBookings, setIsLoadingBookings] = useState(false);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [newBookedItems, setNewBookedItems] = useState([]);
  const [selectedBookingsTables, setSelectedBookingsTables] = useState(new Set());
  const [selectedTable, setSelectedTable] = useState(0);
  const [bookingsLoaded, setBookingsLoaded] = useState(false);
  const [tableData, setTableData] = useState({ bookings: [] });
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [pinOptions, setPinOptions] = useState({ open: false, onSendPin: null });
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const [warningModalOptions, setWarningModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
    amount: null,
  });

  useEffect(() => {
    if (open === true) {
      getBookings(token);
    }
  }, [open]);

  const matches = useMediaQuery('only screen and (max-width: 640px)');

  const getBookings = async (token) => {
    try {
      setBookingsLoaded(true);
      setIsLoadingBookings(true);
      const res = await getMergeableBookings(token);
      if (res && res.success) {
        setTableData({ ...tableData, bookings: orderBy(res.bookings, 'created', 'desc') });
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('bookingsLoadingError'),
        });
      }
      setIsLoadingBookings(false);
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('bookingsLoadingError'),
      });
    }
  };

  const closeWarningModal = () => {
    setWarningModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
      amount: null,
    });
  };

  const onClosePinModal = () => {
    setPinOptions({ open: false, onSendPin: null });
    closeWarningModal();
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const closeModal = (refreshNeeded) => {
    setBookingsLoaded(false);
    setTableData({ bookings: [] });
    setIsLoadingBookings(false);
    setSelectedBookings([]);
    setNewBookedItems([]);
    setSelectedBookingsTables(new Set());
    setSelectedTable(0);
    onClose(refreshNeeded);
  };

  const countElement = (bookedItems, index) => {
    const cleanedUpBookedItems = bookedItems.map((item) => {
      const newItem = {
        itemTitle: item.itemTitle,
        categoryName: item.categoryName,
        specialSelections: item.specialSelections.map((special) => ({
          specialSelectionId: special.specialSelectionId,
          specialSelectionTitle: special.specialSelectionTitle,
          specialSelectionPrice: special.specialSelectionPrice,
        })),
        options:
          item.options && item.options.length > 0
            ? item.options.map((option) => ({ name: option.name, price: option.price }))
            : null,
        amount: item.amount,
        taxRate: item.taxRate,
        isRefund: item.isRefund,
      };
      if (item.discount)
        newItem.discount = { amount: item.discount.amount, percentage: item.discount.percentage };
      if (item.notes) newItem.notes = item.notes;
      return newItem;
    });
    return countBy(cleanedUpBookedItems, (element) => isEqual(element, cleanedUpBookedItems[index]))
      .true;
  };

  const bookedItems = (bookedItems, paymentType, status) => {
    const cleanedUpBookedItems = bookedItems.map((item) => {
      const newItem = {
        itemTitle: item.itemTitle,
        categoryName: item.categoryName,
        specialSelections: item.specialSelections.map((special) => ({
          specialSelectionId: special.specialSelectionId,
          specialSelectionTitle: special.specialSelectionTitle,
          specialSelectionPrice: special.specialSelectionPrice,
        })),
        options:
          item.options && item.options.length > 0
            ? item.options.map((option) => ({ name: option.name, price: option.price }))
            : null,
        amount: item.amount,
        taxRate: item.taxRate,
        isRefund: item.isRefund,
      };
      if (item.discount)
        newItem.discount = { amount: item.discount.amount, percentage: item.discount.percentage };
      if (item.notes) newItem.notes = item.notes;
      return newItem;
    });
    return (
      <>
        {bookedItems.map((item, index) => (
          <div key={`${index}1divs`}>
            {index ===
            findLastIndex(cleanedUpBookedItems, (element) =>
              isEqual(element, cleanedUpBookedItems[index])
            ) ? (
              <>
                <li>
                  <span
                    style={{
                      position: 'relative',
                      left: '-5px',
                      fontWeight: 500,
                      wordBreak: 'break-all',
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>
                      {`${countElement(bookedItems, index)} × `}
                    </span>
                    {`${item.itemTitle} `}
                    {item.isRefund && status !== 'cancelled' && item.isCancelled === true && (
                      <>
                        <span
                          style={{
                            backgroundColor: 'red',
                            color: 'white',
                            border: `solid 1px red`,
                            borderRadius: '8px',
                            padding: 3,
                            paddingBottom: 3,
                            textAlign: 'center',
                            fontWeight: '700',
                            fontSize: '10px',
                          }}
                        >
                          S
                        </span>
                      </>
                    )}
                    {item.isRefund &&
                    paymentType !== 'later' &&
                    (item.isCancelled == null || item.isCancelled === false) ? (
                      <>
                        <span
                          style={{
                            backgroundColor: 'red',
                            color: 'white',
                            border: `solid 1px red`,
                            borderRadius: '8px',
                            padding: 3,
                            paddingBottom: 3,
                            textAlign: 'center',
                            fontWeight: '700',
                            fontSize: '10px',
                          }}
                        >
                          E
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                  </span>
                </li>
                <ul style={{ position: 'relative', left: 25 }}>
                  {item.notes ? (
                    <>
                      <li
                        style={{
                          fontStyle: 'italic',
                          listStyleType: 'none',
                          wordBreak: 'break-all',
                        }}
                        key={`${item.itemId}/${index}`}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: 4,
                            marginLeft: 2,
                            marginBottom: 4,
                            alignItems: 'center',
                          }}
                        >
                          <p className="bookings-extras-options-indicator">H</p>
                          <p
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            {` ${item.notes}`}
                          </p>
                        </div>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {item.options && item.options.length > 0 ? (
                    item.options.map((option, idx) => (
                      <li
                        style={{
                          fontStyle: 'italic',
                          listStyleType: 'none',
                          wordBreak: 'break-all',
                        }}
                        key={`${item.itemId}/${idx}${option.name}`}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: 4,
                            marginLeft: 2,
                            marginBottom: 4,
                            alignItems: 'center',
                          }}
                        >
                          <p className="bookings-extras-options-indicator">O</p>
                          <p
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            {` ${option.name}`}
                          </p>
                        </div>
                      </li>
                    ))
                  ) : (
                    <></>
                  )}
                  {item.specialSelections.map((specialSelection, tIndex) => (
                    <li
                      style={{
                        fontStyle: 'italic',
                        listStyleType: 'none',
                        wordBreak: 'break-all',
                      }}
                      key={`${item.itemId}/${tIndex}`}
                    >
                      <div
                        style={{
                          display: 'flex',
                          gap: 4,
                          marginLeft: 2,
                          marginBottom: 4,
                          alignItems: 'center',
                        }}
                      >
                        <p className="bookings-extras-options-indicator">E</p>
                        <p
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          {` ${specialSelection.specialSelectionTitle}`}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <></>
            )}
          </div>
        ))}
      </>
    );
  };

  const parseBookings = () =>
    tableData &&
    tableData.bookings &&
    tableData.bookings.length > 0 &&
    tableData.bookings.map((booking, index) => (
      <Fragment key={`${index}${booking._id}div`}>
        <div
          style={{
            paddingTop: 12,
            paddingBottom: 10,
            paddingLeft: 12,
            paddingRight: 12,
            cursor: 'pointer',
            backgroundColor: selectedBookings.includes(index) ? '#7268cd17' : 'white',
          }}
          onClick={() => {
            const realBookingsSelected = selectedBookings.map((index) => tableData.bookings[index]);
            const mergedIdsCountOfRealBookingsSelected = realBookingsSelected.reduce(
              (sum, { mergedIds }) => sum + mergedIds.length,
              0
            );
            const bookingsCountWithoutMergeIds = realBookingsSelected.filter(
              (booking) => booking.mergedIds.length === 0
            ).length;
            const currentSelectedBookingMergedIdsCount =
              tableData.bookings[index].mergedIds.length === 0
                ? 1
                : tableData.bookings[index].mergedIds.length;
            if (selectedBookings.length === 10 && selectedBookings.includes(index) === false) {
              setErrorWarningModalOptions({
                open: true,
                message: i18n.t('maxOrdersCombineError'),
              });
            } else if (
              newBookedItems.length + tableData.bookings[index].bookedItems.length > 150 &&
              selectedBookings.includes(index) === false
            ) {
              setErrorWarningModalOptions({
                open: true,
                message: i18n.t('maxItemsCombineError'),
              });
            } else if (
              mergedIdsCountOfRealBookingsSelected +
                bookingsCountWithoutMergeIds +
                currentSelectedBookingMergedIdsCount >
                10 &&
              selectedBookings.includes(index) === false
            ) {
              setErrorWarningModalOptions({
                open: true,
                message: i18n.t('maxCombosCombineError'),
              });
            } else if (selectedBookings.includes(index)) {
              const selectedTableNumber = Array.from(selectedBookingsTables)[selectedTable];
              const indexOfBookings = selectedBookings.indexOf(index);
              const currentTableNumber = tableData.bookings[index].table.tableNumber;
              const realSelectedBookings = selectedBookings.map(
                (index) => tableData.bookings[index]
              );
              const selectedBookingsWithCurrentTableNumber = realSelectedBookings.filter(
                (booking) => booking.table.tableNumber === currentTableNumber
              );
              if (
                selectedBookingsTables.has(tableData.bookings[index].table.tableNumber) &&
                selectedBookingsWithCurrentTableNumber.length === 1
              ) {
                selectedBookingsTables.delete(tableData.bookings[index].table.tableNumber);
                setSelectedBookingsTables(new Set(selectedBookingsTables));
                if (
                  Array.from(selectedBookingsTables)[selectedTable] !== selectedTableNumber &&
                  selectedTable - 1 >= 0
                ) {
                  Array.from(selectedBookingsTables)[selectedTable - 1] !== null
                    ? setSelectedTable(selectedTable - 1)
                    : setSelectedTable(0);
                }
              }
              if (selectedBookingsTables.size === 0 || selectedTable < 0) {
                setSelectedTable(0);
              }
              selectedBookings.splice(indexOfBookings, 1);
              setSelectedBookings([...selectedBookings]);
              const tNewBookedItems = selectedBookings.flatMap(
                (bookingsIndex) => tableData.bookings[bookingsIndex].bookedItems
              );
              setNewBookedItems(sortBy(tNewBookedItems, ['itemTitle']));
            } else {
              setSelectedBookings([...selectedBookings, index]);
              const newSelectedBookingsIndices = [...cloneDeep(selectedBookings), index];
              const tNewBookedItems = newSelectedBookingsIndices.flatMap(
                (bookingsIndex) => tableData.bookings[bookingsIndex].bookedItems
              );
              setNewBookedItems(sortBy(tNewBookedItems, ['itemTitle']));
              selectedBookingsTables.add(tableData.bookings[index].table.tableNumber);
              setSelectedBookingsTables(new Set(selectedBookingsTables));
            }
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 5,
              height: 26,
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                color: '#7268cd',
                fontWeight: 600,
                padding: 2,
                minWidth: 40,
                textAlign: 'center',
                fontSize: 14,
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
                border: '2px solid #7268cd',
                borderRadius: 6,
              }}
            >
              {booking.table
                ? booking.table.tableNumber
                  ? `${booking.table.tableNumber}`
                  : ''
                : '-'}
            </p>
            <p
              style={{
                color: '#7268cd',
                fontWeight: 600,
                padding: 2,
                paddingLeft: 5,
                paddingRight: 5,
                minWidth: 40,
                textAlign: 'center',
                fontSize: 14,
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
                border: '2px solid #7268cd',
                borderRadius: 6,
              }}
            >
              {moment(booking.created.toString()).format(`HH:mm[${i18n.t('uhrGerman')}]`)}
            </p>
          </div>
          <div style={{ display: 'flex', marginTop: 12, justifyContent: 'space-between' }}>
            <div>
              {bookedItems(
                booking.bookedItems.filter((item) => item.isRefund === false),
                booking.paymentType,
                booking.status
              )}
            </div>
            <p
              style={{
                fontSize: 15,
                textAlign: 'right',
                minWidth: 65,
                fontWeight: 600,
                color: '#7268cd',
              }}
            >
              {`${asCurrency(booking.amount / 100)} €`}
            </p>
          </div>
        </div>
        {index !== tableData.bookings.length - 1 && (
          <div
            style={{
              paddingLeft: 14,
              paddingRight: 14,
              backgroundColor: selectedBookings.includes(index) ? '#7268cd17' : 'white',
            }}
          >
            <div
              style={{
                height: 1,
                width: '100%',
                backgroundColor: '#ccc',
              }}
            />
          </div>
        )}
      </Fragment>
    ));

  const onSave = async () => {
    const data = {
      tableNumber:
        Array.from(selectedBookingsTables)[selectedTable] !== null
          ? Array.from(selectedBookingsTables)[selectedTable]
          : Array.from(selectedBookingsTables)[0],
      bookingIds: selectedBookings.map((selectedIndex) => tableData.bookings[selectedIndex]._id),
    };
    try {
      setWarningModalOptions({
        open: true,
        title: i18n.t('combineOrders'),
        message: i18n.t('combineOrdersWarning'),
        ok: false,
        onOk: null,
        yes: true,
        onYes: async () => {
          setIsLoadingSave(true);
          if (
            shop.isPinRequired === true &&
            user.role !== 'owner' &&
            user.role !== 'employee' &&
            (user.isOrdering === true || user.isPaying === true)
          ) {
            setPinOptions({
              open: true,
              onSendPin: async (pin) => {
                try {
                  const res = await mergeBookingsPin(token, data, pin);
                  if (res && res.success) {
                    closeModal(true);
                  } else {
                    setErrorWarningModalOptions({
                      open: true,
                      message: i18n.t('combineOrdersError'),
                    });
                  }
                  onClosePinModal();
                  closeWarningModal();
                } catch (error) {
                  setErrorWarningModalOptions({
                    open: true,
                    message: i18n.t('combineOrdersError'),
                  });
                  onClosePinModal();
                  closeWarningModal();
                }
              },
            });
          } else {
            const res = await mergeBookings(token, data);
            closeWarningModal();
            if (res && res.success) {
              closeModal(true);
            } else {
              setErrorWarningModalOptions({
                open: true,
                message: i18n.t('combineOrdersError'),
              });
            }
          }
          setIsLoadingSave(false);
        },
        no: true,
        onNo: () => closeWarningModal(),
      });
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('combineOrdersError'),
      });
      setIsLoadingSave(false);
    }
  };

  const parseAvailableTables = () =>
    Array.from(selectedBookingsTables).map((table, index) => (
      <div
        key={`${index}tTables`}
        style={{
          color: index === selectedTable ? 'white' : '#7268cd',
          backgroundColor: index === selectedTable ? '#7268cd' : 'white',
          fontWeight: 600,
          padding: 5,
          paddingLeft: 5,
          paddingRight: 5,
          minWidth: 50,
          textAlign: 'center',
          fontSize: 16,
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-all',
          border: '2px solid #7268cd',
          borderRadius: 6,
          cursor: 'pointer',
        }}
        onClick={() => setSelectedTable(index)}
      >
        {table}
      </div>
    ));

  return (
    <>
      <Modal key={12333} open={open} dimmer="blurring" closeOnEscape closeOnDimmerClick>
        <Modal.Header style={{ borderBottom: 'none', backgroundColor: '#f5f5f9' }}>
          {i18n.t('combineOrders')}
          <Button
            key={1234}
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal(false)}
            floated="right"
            circular
            className="settings-close"
          />
        </Modal.Header>
        <Modal.Content key={12345} style={{ backgroundColor: '#f5f5f9' }} scrolling>
          <div style={{ display: 'flex', gap: 20, flexDirection: matches ? 'column' : 'row' }}>
            <div style={{ width: '100%' }}>
              {selectedBookings.length > 0 ? (
                <div style={{ display: 'flex', gap: 5 }}>
                  <p style={{ fontWeight: 600, fontSize: 16 }}>{i18n.t('orders')}</p>
                  <p
                    style={{
                      width: 23,
                      height: 23,
                      border: '1px solid #7268cd',
                      borderRadius: 11,
                      textAlign: 'center',
                      backgroundColor: '#7268cd',
                      color: 'white',
                      paddingTop: 1,
                      fontWeight: 500,
                    }}
                  >
                    {selectedBookings.length}
                  </p>
                </div>
              ) : (
                <p style={{ fontWeight: 600, fontSize: 16 }}>{i18n.t('orders')}</p>
              )}
              <div
                style={{
                  height: '400px',
                  width: '100%',
                  borderRadius: 14,
                  boxShadow: 'rgb(114 104 205 / 40%) 0px 3px 7px',
                  backgroundColor: 'white',
                  overflow: 'scroll',
                  overflowX: 'hidden',
                }}
                className="bookings-merge-scrollbar"
              >
                {isLoadingBookings && (
                  <Loader
                    active={isLoadingBookings}
                    inline="centered"
                    inverted
                    className="bookings-create-loader"
                    style={{ margin: '20px auto' }}
                  />
                )}
                {isLoadingBookings === false && tableData.bookings.length > 0 && (
                  <>{parseBookings()}</>
                )}
                {isLoadingBookings === false && tableData.bookings.length === 0 && (
                  <p style={{ padding: 20, fontWeight: 400, fontSize: 13, color: 'grey' }}>
                    {i18n.t('combineOrderDescription')}
                  </p>
                )}
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <p style={{ fontWeight: 600, fontSize: 16 }}>{i18n.t('newOrder')}</p>
              <div
                style={{
                  height: '400px',
                  width: '100%',
                  borderRadius: 14,
                  boxShadow: 'rgb(114 104 205 / 40%) 0px 3px 7px',
                  backgroundColor: 'white',
                  padding: 12,
                  paddingTop: 10,
                }}
              >
                <p style={{ textAlign: 'center', fontWeight: 700 }}>{i18n.t('appCheckout')}</p>
                <div
                  className="bookings-merge-scrollbar"
                  style={{
                    overflow: 'scroll',
                    overflowX: 'hidden',
                    height: '300px',
                  }}
                >
                  {newBookedItems.length > 0 && <>{bookedItems(newBookedItems, null, null)}</>}
                </div>
                {selectedBookings.length > 0 && (
                  <>
                    <div
                      style={{ width: '100%', height: 1, backgroundColor: 'grey', marginTop: 4 }}
                    />
                    <p
                      style={{
                        marginTop: 10,
                        width: '100%',
                        textAlign: 'right',
                        fontWeight: 600,
                        fontSize: 17,
                      }}
                    >
                      {`${asCurrency(
                        tableData.bookings
                          .filter((booking, index) => selectedBookings.includes(index))
                          .reduce((sum, booking) => sum + booking.amount, 0) / 100
                      )} €`}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div>
            <p style={{ marginTop: 22, fontWeight: 600, fontSize: 16 }}>
              {i18n.t('bookingsTable')}
            </p>
            <div
              style={{
                height: 60,
                width: '100%',
                backgroundColor: 'white',
                boxShadow: 'rgb(114 104 205 / 40%) 0px 3px 7px',
                padding: 12,
                borderRadius: 14,
                overflowY: 'hidden',
                overflowX: 'scroll',
                display: 'flex',
                gap: 10,
              }}
              className="bookings-merge-scrollbar"
            >
              {parseAvailableTables()}
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: '#f5f5f9' }}>
          <Button
            content={i18n.t('bookingModalEditSave')}
            color="teal"
            onClick={() => onSave()}
            floated="right"
            loading={isLoadingSave}
            disabled={
              newBookedItems.length === 0 ||
              selectedBookings.length <= 1 ||
              selectedBookings > 10 ||
              isLoadingSave === true
            }
          />
        </Modal.Actions>
      </Modal>
      <Modal dimmer="blurring" size="tiny" open={errorWarningModalOptions.open}>
        <Modal.Header style={{ borderBottom: 'none' }}>
          <p>{i18n.t('newPrinterErrorTitle')}</p>
        </Modal.Header>
        <Modal.Content>
          <p style={{ fontWeight: 600 }}>{errorWarningModalOptions.message}</p>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
          <Button content="OK" color="teal" onClick={onErrorWarningModalOk} />
        </Modal.Actions>
      </Modal>
      <PinNumPad
        open={pinOptions.open}
        onClose={onClosePinModal}
        onSendPin={pinOptions.onSendPin}
      />
      <ErrorWarningModal
        open={warningModalOptions.open}
        title={warningModalOptions.title}
        message={warningModalOptions.message}
        ok={warningModalOptions.ok}
        onOk={warningModalOptions.onOk}
        yes={warningModalOptions.yes}
        onYes={warningModalOptions.onYes}
        no={warningModalOptions.no}
        onNo={warningModalOptions.onNo}
        amount={warningModalOptions.amount}
      />
    </>
  );
};

export default BookingsMergeModal;
