/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './Sidebar.css';
import { useStateValue } from '../../../core/context/StateProvider';
import i18n from '../../../i18n/config';

/// Sidebar component.
/// Takes props as an array of SidebarItem objects, used as 'items'
const Sidebar = (props) => {
  const { items, currentIndex, isAdmin } = props;
  const [selectedIndex, setSelectedIndex] = useState(currentIndex);
  const [click, setClick] = useState(false);
  const [{ token, user, shop }, dispatch] = useStateValue();

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  let sidebar = click ? 'sidebar active' : 'sidebar';
  const sidebarMenuIcon =
    user && (user.role === 'employee' || user.role === 'manager' || user.role === 'tax')
      ? 'sidebar-menu-icon-employee'
      : 'sidebar-menu-icon';
  if (user && (user.role === 'employee' || user.role === 'manager' || user.role === 'tax')) {
    sidebar = click ? 'sidebar-employee active' : 'sidebar-employee';
  }

  return (
    <>
      <nav>
        <div className={sidebarMenuIcon} onClick={handleClick}>
          <Icon name={click ? 'close' : 'bars'} />
        </div>
        <div className={sidebar}>
          <ul className={click ? 'sidebar-list active' : 'sidebar-list'}>
            {items.map((item, index) => (
              <li key={item.title} className="sidebar-item">
                <Link
                  to={item.linkTo}
                  className="sidebar-link"
                  onClick={() => {
                    setSelectedIndex(index);
                    closeMobileMenu();
                  }}
                  style={
                    selectedIndex === index
                      ? { borderLeft: '5px solid #7268cd' }
                      : { borderLeft: '5px solid transparent' }
                  }
                >
                  <img
                    src={selectedIndex === index ? item.iconSelected : item.iconUnselected}
                    alt={item.title}
                    className="sidebar-icon"
                  />
                </Link>
                {isAdmin === true && (
                  <>
                    <p
                      style={{
                        textAlign: 'center',
                        fontSize: 10,
                        cursor: 'pointer',
                        wordBreak: 'break-all',
                        borderLeft:
                          selectedIndex === index ? '5px solid #7268cd' : '5px solid transparent',
                      }}
                    >
                      {item.title}
                    </p>
                  </>
                )}
              </li>
            ))}
            <div
              className="mobile-buttons"
              style={{
                width: '100%',
                overflow: 'hidden',
              }}
            >
              <li key="logout1" className="sidebar-item">
                <Button
                  id="sidebar-btn"
                  size="large"
                  className="sidebar-btn"
                  content={(
                    sessionStorage.getItem('language') ||
                    navigator.language.substring(0, 2) ||
                    ''
                  ).toUpperCase()}
                  onClick={() => {
                    const currentLang =
                      sessionStorage.getItem('language') || navigator.language.substring(0, 2);
                    i18n.changeLanguage(currentLang === 'en' ? 'de' : 'en');
                    sessionStorage.setItem('language', currentLang === 'en' ? 'de' : 'en');
                    window.location.reload();
                  }}
                />
              </li>
              {user.role === 'owner' && !user.isAdmin ? (
                <>
                  <li key="help" className="sidebar-item">
                    <Button
                      size="huge"
                      id="sidebar-btn"
                      className="sidebar-btn"
                      onClick={() => {
                        window.location.href = `mailto:info@moaby.app?subject=Hilfe%3A%20%5BDein%20Thema%5D&body=Hallo%20moaby%20Team%2C%0D%0A%0D%0AIch%20brauche%20hilfe%20mit%20%5BDeine%20Beschreibung%5D.%0D%0A%0D%0AShop%3A%20${
                          shop ? shop.name : ''
                        }%0D%0AShop%20ID%3A%20${
                          shop ? shop._id : ''
                        }%0D%0A%0D%0AVielen%20Dank%2C%0D%0A${
                          shop ? `${shop.shopOwner.firstName} ${shop.shopOwner.lastName}` : ''
                        }`;
                      }}
                      icon
                    >
                      <Icon name="help circle" />
                    </Button>
                  </li>
                </>
              ) : (
                <></>
              )}
              <li key="logout" className="sidebar-item">
                <Button
                  id="sidebar-btn"
                  size="huge"
                  className="sidebar-btn"
                  onClick={() => {
                    dispatch({
                      type: 'logout',
                    });
                    window.location.href = `${process.env.REACT_APP_URL_PREFIX}${process.env.REACT_APP_SHOPS_SUBDOMAIN}.${process.env.REACT_APP_QR_WEBSITE}/login`;
                  }}
                  icon
                >
                  <Icon name="sign out" />
                </Button>
              </li>
            </div>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
