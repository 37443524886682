import { del, get, post, put } from './Fetch';
import { useStateValue } from '../core/context/StateProvider';

// GET

export const getStripeAccount = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/accounts`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getStripeAccountLink = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/accounts/accountLink`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getIfShopHasStripeAccount = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/hasStripeAccount`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getPayLaterBookingOfTable = async (token, shopId, tableId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/terminal/table/${shopId}/${tableId}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getPayLaterBookingOfTableQR = async (token, shopId, tableId, bookingId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/terminal/table/qr/${shopId}/${tableId}/${bookingId}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

// POST

export const acceptStripeToS = async () => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/accounts/acceptTos`;
  const [{ token }] = useStateValue();
  const headers = { Authorization: token };
  return post(url, headers, {});
};

export const createStripeAccount = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/accounts`;
  const headers = { Authorization: token };
  return post(url, headers, {});
};

export const createCardPayment = async (shopId, bookedItems, scannedCode, table) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/card`;
  const body = {
    shopId,
    bookedItems,
    scannedCode,
    table,
  };
  return post(url, {}, body);
};

export const createCardTerminalPayment = async (
  shopId,
  bookedItems,
  scannedCode,
  table,
  payLaterId,
  token
) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/terminal/card`;
  const body = {
    shopId,
    bookedItems,
    scannedCode,
    table,
    payLaterId,
  };
  const headers = { Authorization: token };
  return post(url, headers, body);
};

export const createCardTerminalPaymentPin = async (
  shopId,
  bookedItems,
  scannedCode,
  table,
  payLaterId,
  token,
  pin
) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/terminal/card/pin`;
  const body = {
    shopId,
    bookedItems,
    scannedCode,
    table,
    payLaterId,
  };
  const headers = { Authorization: token, Pin: pin };
  return post(url, headers, body);
};

export const fulfillCardPayment = async (
  shopId,
  scannedCode,
  notes,
  table,
  paymentIntentId,
  paymentMethodId,
  bookedItems,
  customerEmail
) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/card/fulfill`;
  const body = {
    shopId,
    scannedCode,
    notes,
    table,
    paymentIntentId,
    paymentMethodId,
    bookedItems,
    customerEmail,
  };
  return post(url, {}, body);
};

export const fulfillCardTerminalPayment = async (
  shopId,
  table,
  paymentIntentId,
  paymentMethod,
  paymentMethodId,
  bookedItems,
  payLaterId,
  tipAmount,
  toAdd,
  toCancel,
  token
) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/terminal/card/fulfill`;
  const body = {
    shopId,
    table,
    paymentIntentId,
    paymentMethod,
    paymentMethodId,
    bookedItems,
    payLaterId,
    tipAmount,
    toAdd,
    toCancel,
  };
  const headers = { Authorization: token };
  return post(url, headers, body);
};

export const fulfillCardTerminalPaymentPin = async (
  shopId,
  table,
  paymentIntentId,
  paymentMethod,
  paymentMethodId,
  bookedItems,
  payLaterId,
  tipAmount,
  toAdd,
  toCancel,
  token,
  pin
) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/terminal/card/fulfill/pin`;
  const body = {
    shopId,
    table,
    paymentIntentId,
    paymentMethod,
    paymentMethodId,
    bookedItems,
    payLaterId,
    tipAmount,
    toAdd,
    toCancel,
  };
  const headers = { Authorization: token, Pin: pin };
  return post(url, headers, body);
};

export const createCashPayment = async (
  shopId,
  scannedCode,
  notes,
  table,
  bookedItems,
  customerEmail
) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/cash`;
  const body = {
    shopId,
    scannedCode,
    notes,
    table,
    bookedItems,
    customerEmail,
  };
  return post(url, {}, body);
};

export const createTerminal = async (shopId, registrationCode, price, description, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/terminal/card/reader`;
  const body = {
    shopId,
    registrationCode,
    price,
    description,
  };
  const headers = { Authorization: token };
  return post(url, headers, body);
};

export const createTerminalReaderConnectionToken = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/terminal/card/connectiontoken`;
  const headers = { Authorization: token };
  return post(url, headers, {});
};

export const createCashPaymentTerminal = async (
  shopId,
  scannedCode,
  notes,
  table,
  bookedItems,
  customerEmail,
  payLaterId,
  toAdd,
  toCancel,
  tipAmount,
  token
) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/terminal/cash`;
  const body = {
    shopId,
    scannedCode,
    notes,
    table,
    bookedItems,
    customerEmail,
    payLaterId,
    toAdd,
    toCancel,
    tipAmount,
  };
  const headers = { Authorization: token };
  return post(url, headers, body);
};

export const createCashPaymentTerminalPin = async (
  shopId,
  scannedCode,
  notes,
  table,
  bookedItems,
  customerEmail,
  payLaterId,
  toAdd,
  toCancel,
  tipAmount,
  token,
  pin
) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/terminal/cash/pin`;
  const body = {
    shopId,
    scannedCode,
    notes,
    table,
    bookedItems,
    customerEmail,
    payLaterId,
    toAdd,
    toCancel,
    tipAmount,
  };
  const headers = { Authorization: token, Pin: pin };
  return post(url, headers, body);
};

export const createCardPaymentTerminalOther = async (
  shopId,
  scannedCode,
  notes,
  table,
  bookedItems,
  customerEmail,
  payLaterId,
  toAdd,
  toCancel,
  tipAmount,
  token
) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/terminal/card/not/moaby`;
  const body = {
    shopId,
    scannedCode,
    notes,
    table,
    bookedItems,
    customerEmail,
    payLaterId,
    toAdd,
    toCancel,
    tipAmount,
  };
  const headers = { Authorization: token };
  return post(url, headers, body);
};

export const createCardPaymentTerminalOtherPin = async (
  shopId,
  scannedCode,
  notes,
  table,
  bookedItems,
  customerEmail,
  payLaterId,
  toAdd,
  toCancel,
  tipAmount,
  token,
  pin
) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/terminal/card/not/moaby/pin`;
  const body = {
    shopId,
    scannedCode,
    notes,
    table,
    bookedItems,
    customerEmail,
    payLaterId,
    toAdd,
    toCancel,
    tipAmount,
  };
  const headers = { Authorization: token, Pin: pin };
  return post(url, headers, body);
};

export const createPayLaterPaymentTerminal = async (
  shopId,
  scannedCode,
  notes,
  table,
  bookedItems,
  customerEmail,
  token
) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/terminal/later`;
  const body = {
    shopId,
    scannedCode,
    notes,
    table,
    bookedItems,
    customerEmail,
  };
  const headers = { Authorization: token };
  return post(url, headers, body);
};

export const createPayLaterPaymentTerminalPin = async (
  shopId,
  scannedCode,
  notes,
  table,
  bookedItems,
  customerEmail,
  token,
  pin
) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/terminal/later/pin`;
  const body = {
    shopId,
    scannedCode,
    notes,
    table,
    bookedItems,
    customerEmail,
  };
  const headers = { Authorization: token, Pin: pin };
  return post(url, headers, body);
};

export const acceptQRRequest = async (bookingId, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/cash/qr/accept/${bookingId}`;
  const headers = { Authorization: token };
  const body = {};
  return post(url, headers, body);
};

export const acceptQRRequestPin = async (bookingId, token, pin) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/cash/qr/accept/${bookingId}/pin`;
  const headers = { Authorization: token, Pin: pin };
  const body = {};
  return post(url, headers, body);
};

export const declineQRRequest = async (bookingId, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/cash/qr/decline/${bookingId}`;
  const headers = { Authorization: token };
  const body = {};
  return post(url, headers, body);
};

export const declineQRRequestPin = async (bookingId, token, pin) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/cash/qr/decline/${bookingId}/pin`;
  const headers = { Authorization: token, Pin: pin };
  const body = {};
  return post(url, headers, body);
};

// PUT

export const updateAllowedPaymentMethods = async (token, card, cash) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/paymentMethods`;
  const headers = { Authorization: token };
  const body = {
    card,
    cash,
  };
  return put(url, headers, body);
};

export const updateCardPayment = async (paymentIntentId, shopId, bookedItems) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/card`;
  const body = {
    paymentIntentId,
    shopId,
    bookedItems,
  };
  return put(url, {}, body);
};

export const updateLaterPayment = async (payLaterId, bookedItems, toAdd, toCancel, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/terminal/later`;
  const headers = { Authorization: token };
  const body = {
    bookedItems,
    payLaterId,
    toAdd,
    toCancel,
  };
  return put(url, headers, body);
};

export const updateLaterPaymentPin = async (
  payLaterId,
  bookedItems,
  toAdd,
  toCancel,
  token,
  pin
) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/terminal/later/pin`;
  const headers = { Authorization: token, Pin: pin };
  const body = {
    bookedItems,
    payLaterId,
    toAdd,
    toCancel,
  };
  return put(url, headers, body);
};

export const updateStripeTerminal = async (readerId, shopId, data, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/terminals/${readerId}/${shopId}`;
  const body = data;
  const headers = { Authorization: token };
  return put(url, headers, body);
};

export const updateApplicationFees = async (
  paymentIntentId,
  paymentMethodCountry,
  amount,
  shopId
) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/card/update/appfees/${shopId}`;
  const body = { paymentIntentId, paymentMethodCountry, amount };
  const headers = {};
  return put(url, headers, body);
};

// DELETE
export const deleteTerminalReader = async (shopId, readerId, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}payment/terminals/${readerId}/${shopId}`;
  const headers = { Authorization: token };
  return del(url, headers);
};
