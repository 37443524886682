/* eslint-disable no-useless-escape */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import i18n from 'i18next';
import { Form } from 'semantic-ui-react';
import moment from 'moment';
import { useStateValue } from '../../../core/context/StateProvider';
import './CoronaCheckInModal.css';
import { checkInCoronaData } from '../../../api/CoronaAPI';

const CoronaCheckInModal = (props) => {
  const { isOpen, onClose, color } = props;
  const [{ shop }, dispatch] = useStateValue();
  const [emailError, setEmailError] = useState(false);
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: { city: '', country: 'Deutschland', street: '', zip: '' },
  });

  const closeModal = () => {
    setEmailError(false);
    onClose();
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.replaceAll(/\s/g, ''));
  };

  const onSave = async () => {
    if (validateEmail(data.email)) {
      const coronaData = { corona: { ...data, shopId: shop.id } };
      try {
        const res = await checkInCoronaData(coronaData);
        if (res && res.success) {
          dispatch({
            type: 'update',
            coronaCheckIn: Date.now(),
          });
          closeModal();
        }
      } catch (error) {
        dispatch({
          type: 'update',
          coronaCheckIn: Date.now(),
        });
        closeModal();
      }
    } else {
      setEmailError(true);
    }
  };

  return (
    <>
      {isOpen ? (
        <>
          <div className="corona-checkin-container">
            <p
              style={{ margin: '12px auto', display: 'table', fontSize: '17px', fontWeight: '600' }}
            >
              Corona Check-In
            </p>
            <p style={{ fontSize: '13px', fontWeight: '700' }}>{i18n.t('coronaCheckInTime')}</p>
            <p>{`${moment().format('DD.MM.YYYY, HH:mm')} Uhr`}</p>
            <Form onSubmit={() => onSave()}>
              <Form.Input
                required
                fluid
                label={i18n.t('coronaCheckInFirstName')}
                placeholder={i18n.t('coronaCheckInFirstName')}
                value={data.firstName}
                onChange={(e) => setData({ ...data, firstName: e.target.value })}
              />
              <Form.Input
                required
                fluid
                label={i18n.t('coronaCheckInLastName')}
                placeholder={i18n.t('coronaCheckInLastName')}
                value={data.lastName}
                onChange={(e) => setData({ ...data, lastName: e.target.value })}
              />
              <Form.Input
                required
                fluid
                label={i18n.t('coronaCheckInMail')}
                placeholder={i18n.t('coronaCheckInMail')}
                value={data.email}
                onChange={(e) => setData({ ...data, email: e.target.value.replaceAll(/\s/g, '') })}
                error={emailError}
              />
              <Form.Input
                required
                fluid
                label={i18n.t('coronaCheckInPhoneNumber')}
                placeholder={i18n.t('coronaCheckInPhoneNumber')}
                value={data.phoneNumber}
                type="tel"
                onChange={(e) => setData({ ...data, phoneNumber: e.target.value })}
              />
              <Form.Input
                required
                fluid
                label={i18n.t('coronaCheckInStreet')}
                placeholder={i18n.t('coronaCheckInStreet')}
                value={data.address.street}
                onChange={(e) => {
                  setData({ ...data, address: { ...data.address, street: e.target.value } });
                }}
              />
              <Form.Input
                required
                fluid
                label={i18n.t('coronaCheckInZip')}
                placeholder={i18n.t('coronaCheckInZip')}
                value={data.address.zip}
                type="number"
                onChange={(e) => {
                  setData({ ...data, address: { ...data.address, zip: e.target.value } });
                }}
              />
              <Form.Input
                required
                fluid
                label={i18n.t('coronaCheckInCity')}
                placeholder={i18n.t('coronaCheckInCity')}
                value={data.address.city}
                onChange={(e) => {
                  setData({ ...data, address: { ...data.address, city: e.target.value } });
                }}
              />
              <Form.Button
                content={i18n.t('coronaCheckInSave')}
                fluid
                style={{ backgroundColor: color, color: 'white' }}
              />
            </Form>
            <br />
            <p style={{ fontSize: '13px', fontWeight: '700' }}>Rechtsgrundlage</p>
            <p style={{ fontSize: '11px' }}>
              Rechtsgrundlage hierfür ist Artikel 6 Absatz 1 Buchstabe c) der
              Datenschutz-Grundverordnung (DS-GVO) i.V.m. § 8 Abs. 1 CoronaVO (Verordnung der
              Landesregierung über infektionsschützende Maßnahmen gegen die Ausbreitung des Virus
              SARS-CoV-2) in ihrer aktuellen Version.
            </p>
            <p style={{ fontSize: '11px' }}>
              Im Falle eines konkreten Infektionsverdachtes sind die zuständigen Behörden nach dem
              Bundes-infektionsschutzgesetz Empfänger dieser Daten.
            </p>
            <p style={{ fontSize: '11px' }}>
              Ihre personenbezogenen Daten werden von uns vier Wochen nach Erhalt gelöscht.
            </p>
            <p style={{ fontSize: '11px' }}>
              Sie haben nach der DS-GVO folgende Rechte: Auskunft über die personenbezogenen Daten,
              die wir von Ihnen verarbeiten; Berichtigung, wenn die Daten falsch sind oder
              Einschränkung unserer Verarbeitung; Löschung, sofern wir nicht mehr zur Speicherung
              verpflichtet sind.
            </p>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CoronaCheckInModal;
