/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import './Input.css';

const Input = (props) => {
  const { placeholder, title, id, type, onChangeTitle, onChangePrice } = props;
  const [labelTitle, setLabelTitle] = useState(title || placeholder);
  const [inputText, setInputText] = useState('');

  const handleInput = (value, isTitle) => {
    setInputText(value);
    if (isTitle) {
      if (onChangeTitle) {
        onChangeTitle(value);
      }
    }
  };

  return (
    <div className="form__group field">
      {type === 'currency' ? (
        <CurrencyInput
          name={id}
          id={id}
          intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
          className="form__field"
          placeholder={placeholder}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          onClick={() => setLabelTitle(placeholder)}
          onBlur={() => {
            if (inputText.trim().replaceAll('€', '') !== '') {
              setLabelTitle(placeholder);
            } else {
              setLabelTitle(title || placeholder);
            }
          }}
          onValueChange={(value) => (value ? onChangePrice(value) : onChangePrice(null))}
          onChange={(e) => handleInput(e.target.value, false)}
        />
      ) : (
        <input
          type={type === 'number' ? 'number' : 'input'}
          className="form__field"
          placeholder={placeholder}
          name={id}
          id={id}
          required
          onClick={() => setLabelTitle(placeholder)}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          onBlur={() => {
            if (inputText !== '') {
              setLabelTitle(placeholder);
            } else {
              setLabelTitle(title || placeholder);
              if (onChangeTitle) {
                onChangeTitle(title);
              }
            }
          }}
          onChange={(e) => handleInput(e.target.value, true)}
        />
      )}

      <label htmlFor={id} className="form__label">
        {labelTitle}
      </label>
    </div>
  );
};

export default Input;
