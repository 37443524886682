/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Button, Dropdown, Form, Grid, Input, Modal, Table } from 'semantic-ui-react';
import {
  getAllShops,
  triggerMonthlyInvoiceDownload,
  triggerMonthlyInvoiceEmail,
  triggerWeeklyInvoiceDownload,
  triggerWeeklyInvoiceEmail,
  updateIsClosed,
  updatePricing,
  updateSepa,
} from '../../../api/ShopAPI';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';
import { useStateValue } from '../../../core/context/StateProvider';
import './InvoiceScreen.css';
import Toggle from '../../../components/general/Toggle/Toggle';

const InvoiceScreen = () => {
  const [{ token }, dispatch] = useStateValue();
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [selectedShop, setSelectedShop] = useState(null);
  const [isShopsLoaded, setIsShopsLoaded] = useState(false);
  const [shops, setShops] = useState([]);
  const [hasSepa, setHasSepa] = useState(
    selectedShop && selectedShop.hasSepa != null ? selectedShop.hasSepa : false
  );
  const [isClosed, setIsClosed] = useState(
    selectedShop && selectedShop.isClosed != null ? selectedShop.isClosed : false
  );
  const [modalOptions, setModalOptions] = useState({
    open: false,
    options: null,
  });
  const [warningModalOptions, setWarningModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
    amount: null,
  });
  const [errorModalOptions, setErrorModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
  });
  const [pricing, setPricing] = useState({
    monthly: null,
    yearly: null,
    cashAppMultiplier: null,
    cashAppAdder: null,
    cashTerminalMultiplier: null,
    cashTerminalAdder: null,
    cardAppMultiplier: null,
    cardAppAdder: null,
    cardTerminalMultiplier: null,
    cardTerminalAdder: null,
  });
  const [monthly, setMonthly] = useState('');
  const [yearly, setYearly] = useState('');
  const [cashAppMultiplier, setCashAppMultiplier] = useState('');
  const [cashAppAdder, setCashAppAdder] = useState('');
  const [cashTerminalMultiplier, setCashTerminalMultiplier] = useState('');
  const [cashTerminalAdder, setCashTerminalAdder] = useState('');
  const [cardAppMultiplier, setCardAppMultiplier] = useState('');
  const [cardAppAdder, setCardAppAdder] = useState('');
  const [cardTerminalMultiplier, setCardTerminalMultiplier] = useState('');
  const [cardTerminalAdder, setCardTerminalAdder] = useState('');

  useEffect(() => {
    if (dropDownOptions.length === 0 && isShopsLoaded === false) {
      getShops();
    }
    if (selectedShop && selectedShop.hasSepa !== hasSepa) {
      setHasSepa(selectedShop.hasSepa);
    }
    if (selectedShop && selectedShop.isClosed !== isClosed) {
      setIsClosed(selectedShop.isClosed);
    }
  });

  const getShops = async () => {
    const res = await getAllShops(token);
    if (res && res.success === true) {
      setIsShopsLoaded(true);
      setShops(res.shops);
      setDropDownOptions(
        res.shops.map((shop) => ({
          key: shop._id.toString(),
          text: `${shop.name} ${shop._id.toString()}`,
          value: shop._id.toString(),
        }))
      );
      if (selectedShop) {
        setSelectedShop(
          res.shops.find((shop) => shop._id.toString() === selectedShop._id.toString())
        );
        setHasSepa(selectedShop.hasSepa);
        setIsClosed(selectedShop.isClosed);
      }
    }
  };

  const closeWarningModal = () => {
    setWarningModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
      amount: null,
    });
  };

  const closeErrorModal = () => {
    setErrorModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
    });
  };

  const onViaEmail = async (options) => {
    if (options && options.date !== null && options.date !== undefined) {
      try {
        const res = await triggerMonthlyInvoiceEmail(
          token,
          options.shopId,
          moment(options.date).format()
        );
        if (res && res.success) {
          setModalOptions({ open: false, options: null });
        } else {
          setErrorModalOptions({
            open: true,
            title: 'Error',
            message: 'Error sending e-mail',
            ok: true,
            onOk: () => closeErrorModal(),
          });
        }
      } catch (error) {
        setErrorModalOptions({
          open: true,
          title: 'Error',
          message: 'Error sending e-mail',
          ok: true,
          onOk: () => closeErrorModal(),
        });
      }
    }
    if (
      options &&
      options.dateFrom !== null &&
      options.dateFrom !== undefined &&
      options.dateTo !== null &&
      options.dateTo !== undefined
    ) {
      try {
        const res = await triggerWeeklyInvoiceEmail(
          token,
          options.shopId,
          moment(options.dateFrom).format(),
          moment(options.dateTo).format()
        );
        if (res && res.success) {
          setModalOptions({ open: false, options: null });
        } else {
          setErrorModalOptions({
            open: true,
            title: 'Error',
            message: 'Error sending e-mail',
            ok: true,
            onOk: () => closeErrorModal(),
          });
        }
      } catch (error) {
        setErrorModalOptions({
          open: true,
          title: 'Error',
          message: 'Error sending e-mail',
          ok: true,
          onOk: () => closeErrorModal(),
        });
      }
    }
  };

  const onViaPDF = async (options) => {
    if (options && options.date !== null && options.date !== undefined) {
      try {
        await triggerMonthlyInvoiceDownload(token, options.shopId, moment(options.date).format());
        setModalOptions({ open: false, options: null });
      } catch (error) {
        setErrorModalOptions({
          open: true,
          title: 'Error',
          message: 'Error sending e-mail',
          ok: true,
          onOk: () => closeErrorModal(),
        });
      }
    }
    if (
      options &&
      options.dateFrom !== null &&
      options.dateFrom !== undefined &&
      options.dateTo !== null &&
      options.dateTo !== undefined
    ) {
      try {
        await triggerWeeklyInvoiceDownload(
          token,
          options.shopId,
          moment(options.dateFrom).format(),
          moment(options.dateTo).format()
        );
        setModalOptions({ open: false, options: null });
      } catch (error) {
        setErrorModalOptions({
          open: true,
          title: 'Error',
          message: 'Error sending e-mail',
          ok: true,
          onOk: () => closeErrorModal(),
        });
      }
    }
  };

  const onSavePricing = async () => {
    const data = {};
    data.yearly =
      pricing.yearly !== null
        ? pricing.yearly
        : selectedShop && selectedShop.pricing && selectedShop.pricing.yearly
        ? selectedShop.pricing.yearly
        : 0;
    data.monthly =
      pricing.monthly !== null
        ? pricing.monthly
        : selectedShop && selectedShop.pricing && selectedShop.pricing.monthly
        ? selectedShop.pricing.monthly
        : 0;
    data.cashAppMultiplier =
      pricing.cashAppMultiplier !== null
        ? pricing.cashAppMultiplier
        : selectedShop && selectedShop.pricing && selectedShop.pricing.cashAppMultiplier
        ? selectedShop.pricing.cashAppMultiplier
        : 0;
    data.cashAppAdder =
      pricing.cashAppAdder !== null
        ? pricing.cashAppAdder
        : selectedShop && selectedShop.pricing && selectedShop.pricing.cashAppAdder
        ? selectedShop.pricing.cashAppAdder
        : 0;
    data.cashTerminalMultiplier =
      pricing.cashTerminalMultiplier !== null
        ? pricing.cashTerminalMultiplier
        : selectedShop && selectedShop.pricing && selectedShop.pricing.cashTerminalMultiplier
        ? selectedShop.pricing.cashTerminalMultiplier
        : 0;
    data.cashTerminalAdder =
      pricing.cashTerminalAdder !== null
        ? pricing.cashTerminalAdder
        : selectedShop && selectedShop.pricing && selectedShop.pricing.cashTerminalAdder
        ? selectedShop.pricing.cashTerminalAdder
        : 0;
    data.cardAppMultiplier =
      pricing.cardAppMultiplier !== null
        ? pricing.cardAppMultiplier
        : selectedShop && selectedShop.pricing && selectedShop.pricing.cardAppMultiplier
        ? selectedShop.pricing.cardAppMultiplier
        : 0;
    data.cardAppAdder =
      pricing.cardAppAdder !== null
        ? pricing.cardAppAdder
        : selectedShop && selectedShop.pricing && selectedShop.pricing.cardAppAdder
        ? selectedShop.pricing.cardAppAdder
        : 0;
    data.cardTerminalMultiplier =
      pricing.cardTerminalMultiplier !== null
        ? pricing.cardTerminalMultiplier
        : selectedShop && selectedShop.pricing && selectedShop.pricing.cardTerminalMultiplier
        ? selectedShop.pricing.cardTerminalMultiplier
        : 0;
    data.cardTerminalAdder =
      pricing.cardTerminalAdder !== null
        ? pricing.cardTerminalAdder
        : selectedShop && selectedShop.pricing && selectedShop.pricing.cardTerminalAdder
        ? selectedShop.pricing.cardTerminalAdder
        : 0;
    try {
      if (selectedShop && selectedShop !== null) {
        const res = await updatePricing(token, data, selectedShop._id.toString());
        if (res && res.success === true) {
          setErrorModalOptions({
            open: true,
            title: 'Success',
            message: 'Pricing has been updated.',
            ok: true,
            onOk: () => closeErrorModal(),
          });
        }
      }
    } catch (error) {
      setErrorModalOptions({
        open: true,
        title: 'Error',
        message: error,
        ok: true,
        onOk: () => closeErrorModal(),
      });
    }
  };

  const parseTable = (type) => (
    <>
      <div className="settings-table-container" style={{ height: 'calc(100vh - 460px)' }}>
        <Table fixed celled selectable padded sortable className="settings-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {type === 'weekly' ? parseWeeklyTableBody() : parseMonthlyTableBody()}
          </Table.Body>
        </Table>
      </div>
    </>
  );

  const parseWeeklyTableBody = () => {
    if (selectedShop && selectedShop !== null) {
      const weeklyInvoiceDates = [];
      const today = new Date();
      const shopCreatedDate = new Date(selectedShop.created);
      const startOfWeekCreatedDate = moment(shopCreatedDate).startOf('isoWeek').startOf('day');
      const differenceInWeeks =
        Math.abs(
          moment(shopCreatedDate, 'YYYY-MM-DD')
            .startOf('day')
            .diff(moment(today, 'YYYY-MM-DD').startOf('day'), 'weeks')
        ) + 2;
      for (let i = 0; i < differenceInWeeks; i++) {
        const invoiceWeekDate = moment(startOfWeekCreatedDate)
          .add(i, 'weeks')
          .startOf('isoWeek')
          .startOf('day')
          .add(1, 'days');
        const isTriggered = !!selectedShop.invoicesSentWeekly.find((invoice) =>
          moment(invoice)
            .subtract(1, 'weeks')
            .isBetween(invoiceWeekDate, moment(invoiceWeekDate).endOf('isoWeek').endOf('day'))
        );
        const isScheduled =
          moment(today).isBetween(
            invoiceWeekDate,
            moment(invoiceWeekDate).endOf('isoWeek').endOf('day')
          ) || moment(invoiceWeekDate).isAfter(moment(today));
        weeklyInvoiceDates.push({
          dateFrom: invoiceWeekDate.format('DD.MM.'),
          dateTo: moment(invoiceWeekDate).add(1, 'weeks').startOf('day').format('DD.MM.YYYY'),
          status:
            isTriggered === true ? 'TRIGGERED' : isScheduled === true ? 'SCHEDULED' : 'NOT RUN',
          jsDateFrom: invoiceWeekDate.toDate(),
          jsDateTo: moment(invoiceWeekDate).add(1, 'weeks').startOf('day').toDate(),
        });
      }
      return weeklyInvoiceDates.reverse().map((invoice) => (
        <>
          <Table.Row>
            <Table.Cell collapsing>{`${invoice.dateFrom} - ${invoice.dateTo}`}</Table.Cell>
            <Table.Cell verticalAlign="middle" textAlign="center">
              <p
                style={{
                  fontWeight: 600,
                  color:
                    invoice.status === 'TRIGGERED'
                      ? 'green'
                      : invoice.status === 'SCHEDULED'
                      ? 'orange'
                      : 'red',
                }}
              >
                {invoice.status}
              </p>
            </Table.Cell>
            <Table.Cell verticalAlign="middle" textAlign="center">
              <Button
                icon="redo"
                size="large"
                basic
                color="blue"
                onClick={() => {
                  setModalOptions({
                    open: true,
                    options: {
                      shopId: selectedShop._id.toString(),
                      dateFrom: invoice.jsDateFrom,
                      dateTo: invoice.jsDateTo,
                    },
                  });
                }}
                // disabled={invoice.status === 'SCHEDULED'}
              />
            </Table.Cell>
          </Table.Row>
        </>
      ));
    }
    return <></>;
  };

  const parseMonthlyTableBody = () => {
    if (selectedShop && selectedShop !== null) {
      const monthlyInvoiceDates = [];
      const today = new Date();
      const shopCreatedDate = new Date(selectedShop.created);
      const startOfMonthCreatedDate = moment(shopCreatedDate).startOf('month').startOf('day');
      const differenceInMonths =
        Math.abs(
          moment(shopCreatedDate, 'YYYY-MM-DD')
            .startOf('day')
            .startOf('month')
            .diff(moment(today, 'YYYY-MM-DD').startOf('day'), 'months')
        ) + 2;
      for (let i = 0; i < differenceInMonths; i++) {
        const invoiceMonthDate = moment(startOfMonthCreatedDate)
          .add(i, 'months')
          .startOf('month')
          .startOf('day');
        const isTriggered = !!selectedShop.invoicesSentMonthly.find((invoice) =>
          moment(invoice)
            .subtract(1, 'months')
            .isBetween(invoiceMonthDate, moment(invoiceMonthDate).endOf('month').endOf('day'))
        );
        const isScheduled =
          moment(today).isBetween(
            invoiceMonthDate,
            moment(invoiceMonthDate).endOf('month').endOf('day')
          ) || moment(invoiceMonthDate).isAfter(moment(today));
        monthlyInvoiceDates.push({
          date: invoiceMonthDate.locale('de').format('MMMM YY'),
          status:
            isTriggered === true ? 'TRIGGERED' : isScheduled === true ? 'SCHEDULED' : 'NOT RUN',
          jsDate: invoiceMonthDate.toDate(),
        });
      }
      return monthlyInvoiceDates.reverse().map((invoice) => (
        <>
          <Table.Row>
            <Table.Cell collapsing>{`${invoice.date}`}</Table.Cell>
            <Table.Cell verticalAlign="middle" textAlign="center">
              <p
                style={{
                  fontWeight: 600,
                  color:
                    invoice.status === 'TRIGGERED'
                      ? 'green'
                      : invoice.status === 'SCHEDULED'
                      ? 'orange'
                      : 'red',
                }}
              >
                {invoice.status}
              </p>
            </Table.Cell>
            <Table.Cell verticalAlign="middle" textAlign="center">
              <Button
                icon="redo"
                size="large"
                basic
                color="blue"
                onClick={() => {
                  setModalOptions({
                    open: true,
                    options: {
                      shopId: selectedShop._id.toString(),
                      date: invoice.jsDate,
                    },
                  });
                }}
                // disabled={invoice.status === 'SCHEDULED'}
              />
            </Table.Cell>
          </Table.Row>
        </>
      ));
    }
    return <></>;
  };

  const parsePricing = () => (
    <>
      <div style={{ display: 'flex' }}>
        <p>SEPA</p>
        <Toggle
          checked={hasSepa}
          onChange={async () => {
            const res = await updateSepa(token, !hasSepa, selectedShop._id.toString());
            if (res && res.success) {
              setHasSepa(!hasSepa);
              await getShops();
            }
          }}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <p>IS CLOSED?</p>
        <Toggle
          checked={isClosed}
          onChange={async () => {
            const res = await updateIsClosed(token, !isClosed, selectedShop._id.toString());
            if (res && res.success) {
              setIsClosed(!isClosed);
              await getShops();
            }
          }}
        />
      </div>
      {isClosed === true ? (
        <p style={{ fontSize: 12, fontWeight: 700, color: 'orange' }}>
          Check if TSE is deactivated
        </p>
      ) : (
        <></>
      )}
      <Form>
        <p>
          {`Yearly ${
            selectedShop &&
            selectedShop.pricing &&
            selectedShop.pricing.yearly &&
            pricing.yearly === null
              ? `- ${selectedShop.pricing.yearly / 100} €`
              : pricing.yearly
              ? `- ${pricing.yearly / 100} €`
              : ''
          }`}
        </p>
        <Form.Group className="items-specialSelections-group" key="yearly1">
          <CurrencyInput
            className="items-specialSelections-price"
            placeholder="Jährlich"
            name="yearly2"
            key="yearly2"
            disableGroupSeparators
            allowNegativeValue={false}
            intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
            onValueChange={(value) => {
              setYearly(value);
            }}
            value={yearly}
          />
          <Form.Button
            color="green"
            basic
            key="yearly3"
            icon="add"
            disabled={yearly === ''}
            onClick={() => {
              let tmpYearly = yearly;
              if (tmpYearly.includes(',')) {
                tmpYearly = yearly.replace(',', '.');
              }
              setPricing({ ...pricing, yearly: parseFloat(tmpYearly) * 100 });
            }}
          />
        </Form.Group>
        <p>
          {`Monthtly ${
            selectedShop &&
            selectedShop.pricing &&
            selectedShop.pricing.monthly &&
            pricing.monthly === null
              ? `- ${selectedShop.pricing.monthly / 100} €`
              : pricing.monthly
              ? `- ${pricing.monthly / 100} €`
              : ''
          }`}
        </p>
        <Form.Group className="items-specialSelections-group" key="monthly1">
          <CurrencyInput
            className="items-specialSelections-price"
            placeholder="Monatlich"
            name="monthly2"
            key="monthly2"
            disableGroupSeparators
            allowNegativeValue={false}
            intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
            onValueChange={(value) => {
              setMonthly(value);
            }}
            value={monthly}
          />
          <Form.Button
            color="green"
            basic
            key="monthly3"
            icon="add"
            disabled={monthly === ''}
            onClick={() => {
              let tmp = monthly;
              if (tmp.includes(',')) {
                tmp = monthly.replace(',', '.');
              }
              setPricing({ ...pricing, monthly: parseFloat(tmp) * 100 });
            }}
          />
        </Form.Group>
        <p>
          {`Cash App Costs ${
            selectedShop &&
            selectedShop.pricing &&
            (selectedShop.pricing.cashAppAdder || selectedShop.pricing.cashAppMultiplier) &&
            pricing.cashAppAdder === null &&
            pricing.cashAppMultiplier === null
              ? `- Add.: ${selectedShop.pricing.cashAppAdder}, Mult.: ${selectedShop.pricing.cashAppMultiplier}`
              : pricing.cashAppAdder && pricing.cashAppMultiplier
              ? `- Add.: ${pricing.cashAppAdder}, Mult.: ${pricing.cashAppMultiplier}`
              : ''
          }`}
        </p>
        <Form.Group className="items-specialSelections-group" key="cac1">
          <Form.Input
            placeholder="Multiplier"
            key="cac2"
            name="cac2"
            value={cashAppMultiplier}
            type="number"
            onChange={(e) => {
              setCashAppMultiplier(e.target.value);
            }}
          />
          <Form.Input
            placeholder="Adder"
            key="cac3"
            name="cac3"
            type="number"
            value={cashAppAdder}
            onChange={(e) => {
              setCashAppAdder(e.target.value);
            }}
          />
          <Form.Button
            color="green"
            basic
            key="cac4"
            icon="add"
            disabled={cashAppAdder === '' || cashAppMultiplier === ''}
            onClick={() => {
              setPricing({ ...pricing, cashAppMultiplier, cashAppAdder });
            }}
          />
        </Form.Group>
        <p>
          {`Cash Terminal Costs ${
            selectedShop &&
            selectedShop.pricing &&
            (selectedShop.pricing.cashTerminalAdder ||
              selectedShop.pricing.cashTerminalMultiplier) &&
            pricing.cashTerminalAdder === null &&
            pricing.cashTerminalMultiplier === null
              ? `- Add.: ${selectedShop.pricing.cashTerminalAdder}, Mult.: ${selectedShop.pricing.cashTerminalMultiplier}`
              : pricing.cashTerminalAdder && pricing.cashTerminalMultiplier
              ? `- Add.: ${pricing.cashTerminalAdder}, Mult.: ${pricing.cashTerminalMultiplier}`
              : ''
          }`}
        </p>
        <Form.Group className="items-specialSelections-group" key="ctc1">
          <Form.Input
            placeholder="Multiplier"
            key="ctc2"
            name="ctc2"
            value={cashTerminalMultiplier}
            type="number"
            onChange={(e) => {
              setCashTerminalMultiplier(e.target.value);
            }}
          />
          <Form.Input
            placeholder="Adder"
            key="ctc3"
            name="ctc3"
            type="number"
            value={cashTerminalAdder}
            onChange={(e) => {
              setCashTerminalAdder(e.target.value);
            }}
          />
          <Form.Button
            color="green"
            basic
            key="ctc4"
            icon="add"
            disabled={cashTerminalAdder === '' || cashTerminalMultiplier === ''}
            onClick={() => {
              setPricing({ ...pricing, cashTerminalAdder, cashTerminalMultiplier });
            }}
          />
        </Form.Group>
        <p>
          {`Card App Costs ${
            selectedShop &&
            selectedShop.pricing &&
            (selectedShop.pricing.cardAppAdder || selectedShop.pricing.cardAppMultiplier) &&
            pricing.cardAppAdder === null &&
            pricing.cardAppMultiplier === null
              ? `- Add.: ${selectedShop.pricing.cardAppAdder}, Mult.: ${selectedShop.pricing.cardAppMultiplier}`
              : pricing.cardAppAdder && pricing.cardAppMultiplier
              ? `- Add.: ${pricing.cardAppAdder}, Mult.: ${pricing.cardAppMultiplier}`
              : ''
          }`}
        </p>
        <Form.Group className="items-specialSelections-group" key="caac1">
          <Form.Input
            placeholder="Multiplier"
            key="caac2"
            name="caac2"
            value={cardAppMultiplier}
            type="number"
            onChange={(e) => {
              setCardAppMultiplier(e.target.value);
            }}
          />
          <Form.Input
            placeholder="Adder"
            key="caac3"
            name="caac3"
            type="number"
            value={cardAppAdder}
            onChange={(e) => {
              setCardAppAdder(e.target.value);
            }}
          />
          <Form.Button
            color="green"
            basic
            key="caac4"
            icon="add"
            disabled={cardAppAdder === '' || cardAppMultiplier === ''}
            onClick={() => {
              setPricing({ ...pricing, cardAppMultiplier, cardAppAdder });
            }}
          />
        </Form.Group>
        <p>
          {`Card Terminal Costs ${
            selectedShop &&
            selectedShop.pricing &&
            (selectedShop.pricing.cardTerminalAdder ||
              selectedShop.pricing.cardTerminalMultiplier) &&
            pricing.cardTerminalAdder === null &&
            pricing.cardTerminalMultiplier === null
              ? `- Add.: ${selectedShop.pricing.cardTerminalAdder}, Mult.: ${selectedShop.pricing.cardTerminalMultiplier}`
              : pricing.cardTerminalAdder && pricing.cardTerminalMultiplier
              ? `- Add.: ${pricing.cardTerminalAdder}, Mult.: ${pricing.cardTerminalMultiplier}`
              : ''
          }`}
        </p>
        <Form.Group className="items-specialSelections-group" key="catc1">
          <Form.Input
            placeholder="Multiplier"
            key="catc2"
            name="catc2"
            value={cardTerminalMultiplier}
            type="number"
            onChange={(e) => {
              setCardTerminalMultiplier(e.target.value);
            }}
          />
          <Form.Input
            placeholder="Adder"
            key="catc3"
            name="catc3"
            type="number"
            value={cardTerminalAdder}
            onChange={(e) => {
              setCardTerminalAdder(e.target.value);
            }}
          />
          <Form.Button
            color="green"
            basic
            key="catc4"
            icon="add"
            disabled={cardTerminalAdder === '' || cardTerminalMultiplier === ''}
            onClick={() => {
              setPricing({ ...pricing, cardTerminalMultiplier, cardTerminalAdder });
            }}
          />
        </Form.Group>
        <Button fluid content="Save" color="teal" onClick={onSavePricing} />
      </Form>
    </>
  );

  return (
    <>
      <div className="cockpit-title">
        <p className="cockpit-title">Invoices</p>
      </div>
      <div className="tss-container">
        <Dropdown
          selection
          clearable
          fluid
          placeholder="Select Shop"
          options={dropDownOptions}
          onChange={(_, data) => {
            setSelectedShop(shops.filter((shop) => shop._id.toString() === data.value)[0]);
            setPricing({
              monthly: null,
              yearly: null,
              cashAppMultiplier: null,
              cashAppAdder: null,
              cashTerminalMultiplier: null,
              cashTerminalAdder: null,
              cardAppMultiplier: null,
              cardAppAdder: null,
              cardTerminalMultiplier: null,
              cardTerminalAdder: null,
            });
            setMonthly('');
            setYearly('');
            setCashAppMultiplier('');
            setCashAppAdder('');
            setCashTerminalMultiplier('');
            setCashTerminalAdder('');
            setCardAppMultiplier('');
            setCardAppAdder('');
            setCardTerminalMultiplier('');
            setCardTerminalAdder('');
          }}
        />
        <br />
        {selectedShop && selectedShop !== null && (
          <>
            <p style={{ fontWeight: 600 }}>
              Creation Date:
              {` ${moment(selectedShop.created).format('DD.MM.YYYY')}`}
            </p>
          </>
        )}
      </div>
      {selectedShop && selectedShop !== null && (
        <Grid stackable doubling columns={2}>
          <Grid.Column>
            <div className="tss-container">
              <p style={{ fontWeight: 600 }}>Weekly</p>
              {parseTable('weekly')}
              <br />
              <p style={{ fontWeight: 600 }}>Monthly</p>
              {parseTable('monthly')}
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="tss-container">
              <p style={{ fontWeight: 600 }}>Pricing</p>
              {parsePricing()}
            </div>
          </Grid.Column>
        </Grid>
      )}
      <ErrorWarningModal
        open={warningModalOptions.open}
        title={warningModalOptions.title}
        message={warningModalOptions.message}
        ok={warningModalOptions.ok}
        onOk={warningModalOptions.onOk}
        yes={warningModalOptions.yes}
        onYes={warningModalOptions.onYes}
        no={warningModalOptions.no}
        onNo={warningModalOptions.onNo}
        amount={warningModalOptions.amount}
      />
      <ErrorWarningModal
        open={errorModalOptions.open}
        title={errorModalOptions.title}
        message={errorModalOptions.message}
        ok={errorModalOptions.ok}
        onOk={errorModalOptions.onOk}
        yes={errorModalOptions.yes}
        onYes={errorModalOptions.onYes}
        no={errorModalOptions.no}
        onNo={errorModalOptions.onNo}
      />
      <Modal open={modalOptions.open} dimmer="blurring">
        <Modal.Header>Select Invoice Method</Modal.Header>
        <Modal.Content>
          <Modal.Description>Please select how you want to trigger the invoice.</Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            floated="left"
            onClick={() => setModalOptions({ open: false, options: null })}
          >
            Cancel
          </Button>
          <Button basic color="blue" onClick={() => onViaEmail(modalOptions.options)}>
            {`Via E-mail to ${
              selectedShop && selectedShop !== null ? selectedShop.shopOwner.email : ''
            }`}
          </Button>
          <Button basic color="blue" onClick={() => onViaPDF(modalOptions.options)}>
            Download as PDF
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default InvoiceScreen;
