/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */

import { AES, enc } from 'crypto-js';
import moment from 'moment';

const decryptedData =
  sessionStorage.getItem('moaby') !== null &&
  process.env.REACT_APP_ENV !== 'dev' &&
  process.env.REACT_APP_ENV !== 'test'
    ? JSON.parse(
        AES.decrypt(sessionStorage.getItem('moaby'), process.env.REACT_APP_SESSIONSTORAGE).toString(
          enc.Utf8
        )
      )
    : null;

export const initialState =
  process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'test'
    ? JSON.parse(sessionStorage.getItem('moaby')) || {}
    : sessionStorage.getItem('moaby') !== null
    ? decryptedData
    : {};

export const reducer = (state, action) => {
  const newState = state;

  if (newState.coronaCheckIn) {
    const now = moment();
    const then = moment(newState.coronaCheckIn);
    if (now.isAfter(then.add(2, 'hours'))) {
      delete newState.coronaCheckIn;
    }
  }

  switch (action.type) {
    case 'update': {
      // update the moaby object inside global state and sessionStorage
      if (action.shop) {
        newState.shop = action.shop;
      }
      if (action.table) {
        newState.table = action.table;
      }
      if (action.token) {
        newState.token = action.token;
      }
      if (action.qrCode) {
        if (newState.qrCode !== action.qrCode) {
          newState.cart = [];
        }
        newState.qrCode = action.qrCode;
      }
      if (action.config) {
        newState.config = action.config;
      }
      if (action.user) {
        newState.user = action.user;
      }
      if (action.toCart) {
        newState.cart = newState.cart ? [...newState.cart, action.toCart] : [action.toCart];
      }
      if (action.overrideCart) {
        newState.cart = action.overrideCart;
      }
      if (action.coronaCheckIn) {
        newState.coronaCheckIn = action.coronaCheckIn;
      }
      if (action.loginAttempt) {
        newState.loginAttempt = action.loginAttempt;
      }
      if (action.clearLoginAttempts) {
        newState.loginAttempt = 0;
      }

      if (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'test') {
        sessionStorage.setItem('moaby', JSON.stringify(newState));
      } else {
        sessionStorage.setItem(
          'moaby',
          AES.encrypt(JSON.stringify(newState), process.env.REACT_APP_SESSIONSTORAGE).toString()
        );
      }

      return newState;
    }
    case 'logout': {
      // clear the local storage and global state
      const emptyState = {};
      sessionStorage.clear();
      return emptyState;
    }
    default: {
      return state;
    }
  }
};
