/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { Form, Button, Confirm, Message } from 'semantic-ui-react';
import validator from 'validator';
import { login } from '../../../api/UserAPI';
import { useStateValue } from '../../../core/context/StateProvider';
import Navbar from '../../../components/general/Navbar/Navbar';
import './LoginScreen.css';
import { getMe } from '../../../api/AppInfoAPI';

const LoginScreen = () => {
  const [{ shop, token, config, loginAttempt }, dispatch] = useStateValue();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [confirmOptions, setConfirmOptions] = useState({ open: false, message: '' });
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    if (shop && token && config) {
      history.push('/dashboard');
    }
    if (loginAttempt && loginAttempt > 10 && !isBlocked) {
      setIsBlocked(true);
      setTimeout(() => {
        dispatch({
          type: 'update',
          clearLoginAttempts: 'true',
        });
        setIsBlocked(false);
      }, 5000);
    }
  });

  const handleChange = (e, { name, value }) => {
    if (name === 'email') {
      setEmail(value);
    }
    if (name === 'password') {
      setPassword(value);
    }
  };

  const history = useHistory();
  const showErrorForEmailPassword = () => {
    setEmailError(true);
    setPasswordError(true);
  };

  const loginUser = async () => {
    if (email.length === 0 || password.length === 0 || !validator.isEmail(email)) {
      if (email.length === 0 || !validator.isEmail(email)) {
        setEmailError(true);
      }
      if (password.length === 0) {
        setPasswordError(true);
      }
    } else {
      setIsLoading(true);
      try {
        const res = await login(email, password);
        if (res) {
          if (res.success) {
            const res2 = await getMe(res.token);
            if (res2.success) {
              dispatch({
                type: 'update',
                shop: res2.me.shop,
                config: res2.me.config,
                token: res.token,
                user: res2.me.user,
              });
              history.go(0);
            }
          } else {
            setIsLoading(false);
            showErrorForEmailPassword();
            dispatch({
              type: 'update',
              loginAttempt: attempts + 1,
            });
            setAttempts(attempts + 1);
          }
        } else {
          showErrorForEmailPassword();
          dispatch({
            type: 'update',
            loginAttempt: attempts + 1,
          });
          setAttempts(attempts + 1);
        }
      } catch (_) {
        setConfirmOptions({
          open: true,
          message: i18n.t('loginError'),
        });
      }
    }
  };

  return (
    <>
      <div className="login-navbar">
        <Navbar isShop items={[]} isLogin />
      </div>
      <div className="container">
        <div className="login-text">{i18n.t('loginTitle')}</div>
        <Form loading={isLoading} error={isBlocked}>
          <Message error header={i18n.t('loginAttempts1')} content={i18n.t('loginAttempts2')} />
          <Form.Input
            className="login-input"
            placeholder="E-Mail"
            name="email"
            value={email}
            onChange={handleChange}
            error={emailError}
            icon="mail"
            iconPosition="left"
            disabled={isBlocked}
          />
          <Form.Input
            className="login-input"
            placeholder={i18n.t('newEmployeePassword')}
            name="password"
            value={password}
            onChange={handleChange}
            error={passwordError}
            icon="lock"
            iconPosition="left"
            type="password"
            disabled={isBlocked}
          />
          <Button
            disabled={isBlocked}
            type="submit"
            onClick={loginUser}
            basic
            className="login-button"
            fluid
          >
            {i18n.t('signInButton')}
          </Button>
          <div style={{ marginTop: '15px', textAlign: 'center', color: '#7268cd' }}>
            <Link className="login-link" to="/forgot">
              {i18n.t('forgotPasswordButton')}
            </Link>
          </div>
        </Form>
        <Confirm
          dimmer="blurring"
          open={confirmOptions.open}
          content={confirmOptions.message}
          cancelButton={null}
          header="Error"
          confirmButton={{ basic: true, content: 'OK', positive: true }}
          onConfirm={() => {
            setConfirmOptions({ open: false, message: '' });
          }}
        />
      </div>
    </>
  );
};

export default LoginScreen;
