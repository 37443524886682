import moment from 'moment';
import { get, post, put } from './Fetch';

// GET

export const getSummary = async (token, shopId, date, cardorcash) => {
  const downloadUrl = `${process.env.REACT_APP_SERVER_URL}bookings/summary/${shopId}/${cardorcash}/${date}`;
  const headers = { Authorization: token, 'Content-Type': 'application/pdf' };
  const title = cardorcash === 'cash' ? 'Bargeldbuchungen' : 'Kreditkartenbuchungen';
  const response = await (await fetch(downloadUrl, { method: 'GET', headers })).blob();
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${title}-${moment(date).format('DD-MM-YYYY')}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const getBookedItemsSummaryPDF = async (token, date, isMonth) => {
  const downloadUrl = `${process.env.REACT_APP_SERVER_URL}bookings/items/summary/pdf/${date}/${isMonth}`;
  const headers = { Authorization: token, 'Content-Type': 'application/pdf' };
  const title = 'Produktzusammenfassung';
  const response = await (await fetch(downloadUrl, { method: 'GET', headers })).blob();
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    `${title}-${isMonth ? moment(date).format('MM-YYYY') : moment(date).format('DD-MM-YYYY')}.pdf`
  );
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const getZBonPDF = async (token, shopId, startDate, endDate) => {
  const downloadUrl = `${process.env.REACT_APP_SERVER_URL}bookings/zbon/download/pdf/${shopId}/${startDate}/${endDate}`;
  const headers = { Authorization: token, 'Content-Type': 'application/pdf' };
  const title = `ZBon-${moment(startDate).format('DD-MM-YY')}`;
  const response = await (await fetch(downloadUrl, { method: 'GET', headers })).blob();
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${title}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const getMonthlyReportPDF = async (token, shopId, date) => {
  const downloadUrl = `${process.env.REACT_APP_SERVER_URL}bookings/report/monthly/download/pdf/${date}/${shopId}`;
  const headers = { Authorization: token, 'Content-Type': 'application/pdf' };
  const title = `Monatsbericht-${moment(date).format('MM-YY')}`;
  const response = await (await fetch(downloadUrl, { method: 'GET', headers })).blob();
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${title}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const getEmployeesReportPDF = async (token, shopId, start, end) => {
  const downloadUrl = `${process.env.REACT_APP_SERVER_URL}bookings/employees/summary/pdf/download/${start}/${end}/${shopId}`;
  const headers = { Authorization: token, 'Content-Type': 'application/pdf' };
  const title = `Mitarbeiter-${moment(start).format('DD-MM-YY')}`;
  const response = await (await fetch(downloadUrl, { method: 'GET', headers })).blob();
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${title}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const getCashBookExcel = async (token, date) => {
  const downloadUrl = `${process.env.REACT_APP_SERVER_URL}bookings/cashbook/monthly/excel/${date}`;
  const headers = {
    Authorization: token,
    'Content-Type': 'application/pdf',
  };
  const title = `Kassenbuch-${moment(date).format('MM-YY')}.pdf`;
  const response = await (await fetch(downloadUrl, { method: 'GET', headers })).blob();
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', title);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const getBookedItemsSummaryPrintable = async (token, date, isMonth) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/items/summary/print/${date}/${isMonth}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getZBon = async (token, shopId, startDate, endDate, isXBon) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/zbon/${shopId}/${startDate}/${endDate}/${isXBon}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getZBons = async (token, shopId, page) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/tax/zbons/${shopId}/${page}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getDSFinVK = async (token, shopId, date) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/cashpointclosing/${shopId}/${date}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getBookingsPage = async (token, page, entity, direction) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/all/${page}/${entity}/${direction}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getExistingZBon = async (shopId, date, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/zbons/find/${shopId}/${date}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getBookingsByEmployee = async (token, startDate, endDate, shopId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/employees/summary/${startDate}/${endDate}/${shopId}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getBookingsPageEmployee = async (token, page, entity, direction) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/all/employee/${page}/${entity}/${direction}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getBookingsStatusFilter = async (token, status, page, direction) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/all/filter/status/${status}/${page}/${direction}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getBookingsStatusFilterEmployee = async (token, status, page, direction) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/all/filter/employee/status/${status}/${page}/${direction}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getBookingsPaymentTypeFilter = async (token, paymentType, page, direction) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/all/filter/paymentType/${paymentType}/${page}/${direction}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getBookingsPaymentTypeFilterEmployee = async (token, paymentType, page, direction) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/all/filter/employee/paymentType/${paymentType}/${page}/${direction}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getBookingsTypeTypeFilter = async (token, type, page, direction) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/all/filter/typeType/${type}/${page}/${direction}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getBookingsTypeTypeFilterEmployee = async (token, type, page, direction) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/all/filter/employee/typeType/${type}/${page}/${direction}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getBookingsByTableFilter = async (token, table, page, direction) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/all/filter/byTable/${table}/${page}/${direction}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getBookingsByTableFilterEmployee = async (token, table, page, direction) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/all/filter/employee/byTable/${table}/${page}/${direction}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const searchBookings = async (token, searchTerm, page) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/search/${encodeURIComponent(
    searchTerm
  )}/${page}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getUnpaidBookings = async (token, shopId, startDate, endDate) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/unpaid/${shopId}/${startDate}/${endDate}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getLatestZBon = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/lastzbon`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getZBonDates = async (token, page) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/zbons/page/${page}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const searchBookingsEmployee = async (token, searchTerm, page) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/employee/search/${encodeURIComponent(
    searchTerm
  )}/${page}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getBookingsOfToday = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/count/today`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getBookingsOfThisMonth = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/count/monthly`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getRevenueOfToday = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/revenue/today`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getDailyPerMonth = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/dailyPerMonth`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getRevenueOfThisMonth = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/revenue/monthly`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getBookingTimes = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/times`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getTopTenBookedItems = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/topten/items`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getCardVSCash = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/cardvscash`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getMergeableBookings = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/merge`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getCustomerInvoiceCard = async (shopId, paymentIntentId) => {
  const downloadUrl = `${process.env.REACT_APP_SERVER_URL}bookings/invoice/card/${shopId}/${paymentIntentId}`;
  const headers = { 'Content-Type': 'application/pdf' };
  const title = 'moaby-order';
  const response = await (await fetch(downloadUrl, { method: 'GET', headers })).blob();
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${title}-${moment(new Date()).format('DD-MM-YYYY')}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const getCustomerInvoiceCash = async (shopId, bookingId) => {
  const downloadUrl = `${process.env.REACT_APP_SERVER_URL}bookings/invoice/cash/${shopId}/${bookingId}`;
  const headers = { 'Content-Type': 'application/pdf' };
  const title = 'moaby-order';
  const response = await (await fetch(downloadUrl, { method: 'GET', headers })).blob();
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${title}-${moment(new Date()).format('DD-MM-YYYY')}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const triggerCashPointClosingExport = async (shopId, date, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/cashpointclosing/triggerexport/${shopId}/${date}/`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const downloadCashPointClosingExport = async (exportId, shopId, token) => {
  const downloadUrl = `${process.env.REACT_APP_SERVER_URL}bookings/cashpointclosing/export/download/${exportId}/${shopId}`;
  const headers = { Authorization: token };
  const stream = await fetch(downloadUrl, { method: 'GET', headers });
  if (stream.status > 199 && stream.status < 400) {
    const text = await stream.text();
    const element = document.createElement('a');
    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
    element.setAttribute('download', `moaby-cpc-export-${moment().format('MMMM-YYYY')}.tar`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
};

export const triggerTSEExport = async (shopId, date, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/tse/export/trigger/${shopId}/${date}/`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const downloadTSEExport = async (exportId, tssId, shopId, token) => {
  const downloadUrl = `${process.env.REACT_APP_SERVER_URL}bookings/tse/export/download/${tssId}/${exportId}/${shopId}`;
  const headers = { Authorization: token };
  const download = await fetch(downloadUrl, { method: 'GET', headers });
  if (download.status > 199 && download.status < 400) {
    const file = await download.blob();
    const url = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `moaby-tse-export-${moment().format('MMMM-YYYY')}.tar`);
    document.body.appendChild(link);
    link.click();
  }
};

export const getMonthlyReport = async (selectedMonth, shopId, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/report/monthly/${selectedMonth}/${shopId}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getEmployeeDailyReport = async (startDate, endDate, pin, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/employee/summary/${startDate}/${endDate}/${pin}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

// POST

export const refundCardBooking = async (bookingId, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/refund/card`;
  const headers = { Authorization: token };
  const body = { bookingId };
  return post(url, headers, body);
};

export const refundCashBooking = async (bookingId, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/refund/cash`;
  const headers = { Authorization: token };
  const body = { bookingId };
  return post(url, headers, body);
};

export const refundLaterBooking = async (bookingId, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/refund/later`;
  const headers = { Authorization: token };
  const body = { bookingId };
  return post(url, headers, body);
};

export const refundCardBookingPin = async (bookingId, token, pin) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/refund/card/pin`;
  const headers = { Authorization: token, Pin: pin };
  const body = { bookingId };
  return post(url, headers, body);
};

export const refundCashBookingPin = async (bookingId, token, pin) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/refund/cash/pin`;
  const headers = { Authorization: token, Pin: pin };
  const body = { bookingId };
  return post(url, headers, body);
};

export const refundLaterBookingPin = async (bookingId, token, pin) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/refund/later/pin`;
  const headers = { Authorization: token, Pin: pin };
  const body = { bookingId };
  return post(url, headers, body);
};

export const closeAllBookings = async (tableId, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/tables/close/${tableId}`;
  const headers = { Authorization: token };
  return post(url, headers, {});
};

export const closeAllBookingsPin = async (tableId, token, pin) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/tables/close/${tableId}/pin`;
  const headers = { Authorization: token, Pin: pin };
  return post(url, headers, {});
};

export const splitBooking = async (bookingId, oldBookedItems, newBookedItems, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/split/${bookingId}`;
  const headers = { Authorization: token };
  const body = { oldBookedItems, newBookedItems };
  return post(url, headers, body);
};

export const splitBookingPin = async (bookingId, oldBookedItems, newBookedItems, token, pin) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/split/${bookingId}/pin`;
  const headers = { Authorization: token, Pin: pin };
  const body = { oldBookedItems, newBookedItems };
  return post(url, headers, body);
};

export const mergeBookings = async (token, data) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/merge`;
  const body = data;
  const headers = { Authorization: token };
  return post(url, headers, body);
};

export const mergeBookingsPin = async (token, data, pin) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/merge/pin`;
  const body = data;
  const headers = { Authorization: token, Pin: pin };
  return post(url, headers, body);
};

export const zBonSaveCashBook = async (token, shopId, date, cashBook) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/zbons/protocol/${shopId}/${date}`;
  const body = cashBook;
  const headers = { Authorization: token };
  return post(url, headers, body);
};

// PUT

export const updateBookingStatus = async (token, status, bookingId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/status/${bookingId}`;
  const headers = { Authorization: token };
  const body = { status };
  return put(url, headers, body);
};

export const updateBookingStatusPin = async (token, status, bookingId, pin) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/status/pin/${bookingId}`;
  const headers = { Authorization: token, Pin: pin };
  const body = { status };
  return put(url, headers, body);
};

export const resetZbon = async (shopId, date, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/zbons/reset/${shopId}/${date}`;
  const headers = { Authorization: token };
  return put(url, headers, {});
};

export const editCashBooking = async (token, bookingId, bookedItemIds) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/edit/cash`;
  const headers = { Authorization: token };
  const body = { bookingId, bookedItemIds };
  return put(url, headers, body);
};

export const editLaterBooking = async (token, bookingId, bookedItemIds) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/edit/later`;
  const headers = { Authorization: token };
  const body = { bookingId, bookedItemIds };
  return put(url, headers, body);
};

export const editCardBooking = async (token, bookingId, bookedItemIds) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/edit/card`;
  const headers = { Authorization: token };
  const body = { bookingId, bookedItemIds };
  return put(url, headers, body);
};

export const editCashBookingPin = async (token, bookingId, bookedItemIds, pin) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/edit/cash/pin`;
  const headers = { Authorization: token, Pin: pin };
  const body = { bookingId, bookedItemIds };
  return put(url, headers, body);
};

export const editLaterBookingPin = async (token, bookingId, bookedItemIds, pin) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/edit/later/pin`;
  const headers = { Authorization: token, Pin: pin };
  const body = { bookingId, bookedItemIds };
  return put(url, headers, body);
};

export const editCardBookingPin = async (token, bookingId, bookedItemIds, pin) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/edit/card/pin`;
  const headers = { Authorization: token, Pin: pin };
  const body = { bookingId, bookedItemIds };
  return put(url, headers, body);
};

export const addEmailToBookingCard = async (paymentIntentId, email) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/email/card/${paymentIntentId}`;
  const headers = {};
  const body = { email };
  return put(url, headers, body);
};

export const addEmailToBookingCash = async (bookingId, email) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/email/cash/${bookingId}`;
  const headers = {};
  const body = { email };
  return put(url, headers, body);
};

export const updateTableOfBooking = async (bookingId, tableNumber, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/table/${bookingId}/${tableNumber}`;
  const headers = { Authorization: token };
  const body = {};
  return put(url, headers, body);
};

export const updateTableOfBookingPin = async (bookingId, tableNumber, token, pin) => {
  const url = `${process.env.REACT_APP_SERVER_URL}bookings/table/${bookingId}/${tableNumber}/pin`;
  const headers = { Authorization: token, Pin: pin };
  const body = {};
  return put(url, headers, body);
};
