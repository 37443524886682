/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import i18n from 'i18next';
import { Modal, Button } from 'semantic-ui-react';
import moment from 'moment';
import { updateShop } from '../../../api/ShopAPI';
import { useStateValue } from '../../../core/context/StateProvider';
import './SettingsScreen.css';

const OpeningHoursModal = (props) => {
  const { onClose, open } = props;
  const [{ token, shop }, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [newMon, setNewMon] = useState({ from: null, to: null });
  const [newMonFrom, setNewMonFrom] = useState(null);
  const [newMonTo, setNewMonTo] = useState(null);
  const [openingHours, setOpeningHours] = useState({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  });
  const [newTue, setNewTue] = useState({ from: null, to: null });
  const [newTueFrom, setNewTueFrom] = useState(null);
  const [newTueTo, setNewTueTo] = useState(null);
  const [newWed, setNewWed] = useState({ from: null, to: null });
  const [newWedFrom, setNewWedFrom] = useState(null);
  const [newWedTo, setNewWedTo] = useState(null);
  const [newThu, setNewThu] = useState({ from: null, to: null });
  const [newThuFrom, setNewThuFrom] = useState(null);
  const [newThuTo, setNewThuTo] = useState(null);
  const [newFri, setNewFri] = useState({ from: null, to: null });
  const [newFriFrom, setNewFriFrom] = useState(null);
  const [newFriTo, setNewFriTo] = useState(null);
  const [newSat, setNewSat] = useState({ from: null, to: null });
  const [newSatFrom, setNewSatFrom] = useState(null);
  const [newSatTo, setNewSatTo] = useState(null);
  const [newSun, setNewSun] = useState({ from: null, to: null });
  const [newSunFrom, setNewSunFrom] = useState(null);
  const [newSunTo, setNewSunTo] = useState(null);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });

  useEffect(() => {
    if (
      open === true &&
      shop &&
      shop.openingHours &&
      shop.openingHours.monday !== openingHours.monday &&
      shop.openingHours.tuesday !== openingHours.tuesday &&
      shop.openingHours.wednesday !== openingHours.wednesday &&
      shop.openingHours.thursday !== openingHours.thursday &&
      shop.openingHours.friday !== openingHours.friday &&
      shop.openingHours.saturday !== openingHours.saturday &&
      shop.openingHours.sunday !== openingHours.sunday
    ) {
      setOpeningHours({
        monday: shop.openingHours.monday,
        tuesday: shop.openingHours.tuesday,
        wednesday: shop.openingHours.wednesday,
        thursday: shop.openingHours.thursday,
        friday: shop.openingHours.friday,
        saturday: shop.openingHours.saturday,
        sunday: shop.openingHours.sunday,
      });
    }
  });

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const closeModal = (refreshNeeded) => {
    setNewMonFrom(null);
    setNewMonTo(null);
    setNewMon({ from: null, to: null });
    setNewTueFrom(null);
    setNewTueTo(null);
    setNewTue({ from: null, to: null });
    setNewWedFrom(null);
    setNewWedTo(null);
    setNewWed({ from: null, to: null });
    setNewThuFrom(null);
    setNewThuTo(null);
    setNewThu({ from: null, to: null });
    setNewFriFrom(null);
    setNewFriTo(null);
    setNewFri({ from: null, to: null });
    setNewSatFrom(null);
    setNewSatTo(null);
    setNewSat({ from: null, to: null });
    setNewSunFrom(null);
    setNewSunTo(null);
    setNewSun({ from: null, to: null });
    setIsSaveDisabled(true);
    setOpeningHours({
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    });
    onClose(refreshNeeded);
  };

  const onSave = async () => {
    setIsLoading(true);
    const data = { openingHours };
    const res = await updateShop(token, data);
    if (res && res.success) {
      setIsLoading(false);
      closeModal(true);
    } else {
      setIsLoading(false);
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('openingHoursUpdatError'),
      });
    }
  };

  const parseDays = (day) =>
    // eslint-disable-next-line no-unused-expressions
    openingHours &&
    openingHours[day] &&
    openingHours[day].map((dayTime, index) => (
      <Fragment key={`${index}frag`}>
        <div style={{ margin: '0' }}>
          <input type="time" value={calculateBETimeToTimeInputFormat(dayTime).from} readOnly />
          <span
            style={{
              marginLeft: '8px',
              marginRight: '8px',
              fontSize: '8px',
              fontWeight: 'bold',
            }}
          >
            -
          </span>
          <input type="time" value={calculateBETimeToTimeInputFormat(dayTime).to} readOnly />
          <span style={{ marginRight: '8px' }} />
          <Button
            icon="delete"
            basic
            color="red"
            className="time-button"
            onClick={() => {
              openingHours[day].splice(index, 1);
              setOpeningHours({ ...openingHours });
              setIsSaveDisabled(false);
            }}
          />
        </div>
      </Fragment>
    ));

  const calculateBETimeToTimeInputFormat = (time) => {
    if (time.from === null || time.to === null) {
      return { from: null, to: null };
    }
    const from = moment().startOf('day').add(time.from, 'minutes').format('HH:mm');
    const to = moment().startOf('day').add(time.to, 'minutes').format('HH:mm');
    return { from, to };
  };

  return (
    <>
      <Modal
        open={open}
        dimmer="blurring"
        size="mini"
        closeOnEscape
        closeOnDimmerClick
        className="square-corners"
      >
        <Modal.Header style={{ borderBottom: 'none' }}>
          {i18n.t('openingHoursTitle')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal(true)}
            floated="right"
            circular
            className="settings-close"
          />
        </Modal.Header>
        <Modal.Content scrolling>
          <div className="time-picker">
            <p>{i18n.t('openingHoursMonday')}</p>
            {parseDays('monday')}
            <div className="time-range">
              <input
                type="time"
                onChange={(e) => {
                  if (e.target.valueAsDate) {
                    const hours = (e.target.valueAsDate.getHours() - 1) * 60;
                    const minutes = e.target.valueAsDate.getMinutes();
                    const value = hours + minutes;
                    setNewMon({ from: value, to: newMon.to });
                    setNewMonFrom(e.target.value);
                  }
                }}
                value={newMonFrom}
              />
              <span
                style={{
                  marginLeft: '8px',
                  marginRight: '8px',
                  fontSize: '8px',
                  fontWeight: 'bold',
                }}
              >
                -
              </span>
              <input
                type="time"
                onChange={(e) => {
                  if (e.target.valueAsDate) {
                    const hours = (e.target.valueAsDate.getHours() - 1) * 60;
                    const minutes = e.target.valueAsDate.getMinutes();
                    const value = hours + minutes;
                    setNewMon({ from: newMon.from, to: value });
                    setNewMonTo(e.target.value);
                  }
                }}
                value={newMonTo}
              />
              <span style={{ marginRight: '8px' }} />
              <Button
                icon="add"
                basic
                color="green"
                className="time-button"
                disabled={newMon.from !== null && newMon.to !== null ? false : true}
                onClick={() => {
                  openingHours.monday.push(newMon);
                  setNewMon({ from: null, to: null });
                  setNewMonFrom('00:00');
                  setNewMonTo('00:00');
                  setIsSaveDisabled(false);
                }}
              />
            </div>
            <p>{i18n.t('openingHoursTuesday')}</p>
            {parseDays('tuesday')}
            <div className="time-range">
              <input
                type="time"
                onChange={(e) => {
                  if (e.target.valueAsDate) {
                    const hours = (e.target.valueAsDate.getHours() - 1) * 60;
                    const minutes = e.target.valueAsDate.getMinutes();
                    const value = hours + minutes;
                    setNewTue({ from: value, to: newTue.to });
                    setNewTueFrom(e.target.value);
                  }
                }}
                value={newTueFrom}
              />
              <span
                style={{
                  marginLeft: '8px',
                  marginRight: '8px',
                  fontSize: '8px',
                  fontWeight: 'bold',
                }}
              >
                -
              </span>
              <input
                type="time"
                onChange={(e) => {
                  if (e.target.valueAsDate) {
                    const hours = (e.target.valueAsDate.getHours() - 1) * 60;
                    const minutes = e.target.valueAsDate.getMinutes();
                    const value = hours + minutes;
                    setNewTue({ from: newTue.from, to: value });
                    setNewTueTo(e.target.value);
                  }
                }}
                value={newTueTo}
              />
              <span style={{ marginRight: '8px' }} />
              <Button
                icon="add"
                basic
                color="green"
                className="time-button"
                disabled={newTue.from !== null && newTue.to !== null ? false : true}
                onClick={() => {
                  openingHours.tuesday.push(newTue);
                  setNewTue({ from: null, to: null });
                  setNewTueFrom('00:00');
                  setNewTueTo('00:00');
                  setIsSaveDisabled(false);
                }}
              />
            </div>
            <p>{i18n.t('openingHoursWednesday')}</p>
            {parseDays('wednesday')}
            <div className="time-range">
              <input
                type="time"
                onChange={(e) => {
                  if (e.target.valueAsDate) {
                    const hours = (e.target.valueAsDate.getHours() - 1) * 60;
                    const minutes = e.target.valueAsDate.getMinutes();
                    const value = hours + minutes;
                    setNewWed({ from: value, to: newWed.to });
                    setNewWedFrom(e.target.value);
                  }
                }}
                value={newWedFrom}
              />
              <span
                style={{
                  marginLeft: '8px',
                  marginRight: '8px',
                  fontSize: '8px',
                  fontWeight: 'bold',
                }}
              >
                -
              </span>
              <input
                type="time"
                onChange={(e) => {
                  if (e.target.valueAsDate) {
                    const hours = (e.target.valueAsDate.getHours() - 1) * 60;
                    const minutes = e.target.valueAsDate.getMinutes();
                    const value = hours + minutes;
                    setNewWed({ from: newWed.from, to: value });
                    setNewWedTo(e.target.value);
                  }
                }}
                value={newWedTo}
              />
              <span style={{ marginRight: '8px' }} />
              <Button
                icon="add"
                basic
                color="green"
                className="time-button"
                disabled={newWed.from !== null && newWed.to !== null ? false : true}
                onClick={() => {
                  openingHours.wednesday.push(newWed);
                  setNewWed({ from: null, to: null });
                  setNewWedFrom('00:00');
                  setNewWedTo('00:00');
                  setIsSaveDisabled(false);
                }}
              />
            </div>
            <p>{i18n.t('openingHoursThursday')}</p>
            {parseDays('thursday')}
            <div className="time-range">
              <input
                type="time"
                onChange={(e) => {
                  if (e.target.valueAsDate) {
                    const hours = (e.target.valueAsDate.getHours() - 1) * 60;
                    const minutes = e.target.valueAsDate.getMinutes();
                    const value = hours + minutes;
                    setNewThu({ from: value, to: newThu.to });
                    setNewThuFrom(e.target.value);
                  }
                }}
                value={newThuFrom}
              />
              <span
                style={{
                  marginLeft: '8px',
                  marginRight: '8px',
                  fontSize: '8px',
                  fontWeight: 'bold',
                }}
              >
                -
              </span>
              <input
                type="time"
                onChange={(e) => {
                  if (e.target.valueAsDate) {
                    const hours = (e.target.valueAsDate.getHours() - 1) * 60;
                    const minutes = e.target.valueAsDate.getMinutes();
                    const value = hours + minutes;
                    setNewThu({ from: newThu.from, to: value });
                    setNewThuTo(e.target.value);
                  }
                }}
                value={newThuTo}
              />
              <span style={{ marginRight: '8px' }} />
              <Button
                icon="add"
                basic
                color="green"
                className="time-button"
                disabled={newThu.from !== null && newThu.to !== null ? false : true}
                onClick={() => {
                  openingHours.thursday.push(newThu);
                  setNewThu({ from: null, to: null });
                  setNewThuFrom('00:00');
                  setNewThuTo('00:00');
                  setIsSaveDisabled(false);
                }}
              />
            </div>
            <p>{i18n.t('openingHoursFriday')}</p>
            {parseDays('friday')}
            <div className="time-range">
              <input
                type="time"
                onChange={(e) => {
                  if (e.target.valueAsDate) {
                    const hours = (e.target.valueAsDate.getHours() - 1) * 60;
                    const minutes = e.target.valueAsDate.getMinutes();
                    const value = hours + minutes;
                    setNewFri({ from: value, to: newFri.to });
                    setNewFriFrom(e.target.value);
                  }
                }}
                value={newFriFrom}
              />
              <span
                style={{
                  marginLeft: '8px',
                  marginRight: '8px',
                  fontSize: '8px',
                  fontWeight: 'bold',
                }}
              >
                -
              </span>
              <input
                type="time"
                onChange={(e) => {
                  if (e.target.valueAsDate) {
                    const hours = (e.target.valueAsDate.getHours() - 1) * 60;
                    const minutes = e.target.valueAsDate.getMinutes();
                    const value = hours + minutes;
                    setNewFri({ from: newFri.from, to: value });
                    setNewFriTo(e.target.value);
                  }
                }}
                value={newFriTo}
              />
              <span style={{ marginRight: '8px' }} />
              <Button
                icon="add"
                basic
                color="green"
                className="time-button"
                disabled={newFri.from !== null && newFri.to !== null ? false : true}
                onClick={() => {
                  openingHours.friday.push(newFri);
                  setNewFri({ from: null, to: null });
                  setNewFriFrom('00:00');
                  setNewFriTo('00:00');
                  setIsSaveDisabled(false);
                }}
              />
            </div>
            <p>{i18n.t('openingHoursSaturday')}</p>
            {parseDays('saturday')}
            <div className="time-range">
              <input
                type="time"
                onChange={(e) => {
                  if (e.target.valueAsDate) {
                    const hours = (e.target.valueAsDate.getHours() - 1) * 60;
                    const minutes = e.target.valueAsDate.getMinutes();
                    const value = hours + minutes;
                    setNewSat({ from: value, to: newSat.to });
                    setNewSatFrom(e.target.value);
                  }
                }}
                value={newSatFrom}
              />
              <span
                style={{
                  marginLeft: '8px',
                  marginRight: '8px',
                  fontSize: '8px',
                  fontWeight: 'bold',
                }}
              >
                -
              </span>
              <input
                type="time"
                onChange={(e) => {
                  if (e.target.valueAsDate) {
                    const hours = (e.target.valueAsDate.getHours() - 1) * 60;
                    const minutes = e.target.valueAsDate.getMinutes();
                    const value = hours + minutes;
                    setNewSat({ from: newSat.from, to: value });
                    setNewSatTo(e.target.value);
                  }
                }}
                value={newSatTo}
              />
              <span style={{ marginRight: '8px' }} />
              <Button
                icon="add"
                basic
                color="green"
                className="time-button"
                disabled={newSat.from !== null && newSat.to !== null ? false : true}
                onClick={() => {
                  openingHours.saturday.push(newSat);
                  setNewSat({ from: null, to: null });
                  setNewSatFrom('00:00');
                  setNewSatTo('00:00');
                  setIsSaveDisabled(false);
                }}
              />
            </div>
            <p>{i18n.t('openingHoursSunday')}</p>
            {parseDays('sunday')}
            <div className="time-range">
              <input
                type="time"
                onChange={(e) => {
                  if (e.target.valueAsDate) {
                    const hours = (e.target.valueAsDate.getHours() - 1) * 60;
                    const minutes = e.target.valueAsDate.getMinutes();
                    const value = hours + minutes;
                    setNewSun({ from: value, to: newSun.to });
                    setNewSunFrom(e.target.value);
                  }
                }}
                value={newSunFrom}
              />
              <span
                style={{
                  marginLeft: '8px',
                  marginRight: '8px',
                  fontSize: '8px',
                  fontWeight: 'bold',
                }}
              >
                -
              </span>
              <input
                type="time"
                onChange={(e) => {
                  if (e.target.valueAsDate) {
                    const hours = (e.target.valueAsDate.getHours() - 1) * 60;
                    const minutes = e.target.valueAsDate.getMinutes();
                    const value = hours + minutes;
                    setNewSun({ from: newSun.from, to: value });
                    setNewSunTo(e.target.value);
                  }
                }}
                value={newSunTo}
              />
              <span style={{ marginRight: '8px' }} />
              <Button
                icon="add"
                basic
                color="green"
                className="time-button"
                disabled={newSun.from !== null && newSun.to !== null ? false : true}
                onClick={() => {
                  openingHours.sunday.push(newSun);
                  setNewSun({ from: null, to: null });
                  setNewSunFrom('00:00');
                  setNewSunTo('00:00');
                  setIsSaveDisabled(false);
                }}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
          <Button
            loading={isLoading}
            content={i18n.t('shopEditSave')}
            disabled={isSaveDisabled}
            floated="right"
            onClick={onSave}
            color="teal"
          />
        </Modal.Actions>
      </Modal>
      <Modal dimmer="blurring" size="tiny" open={errorWarningModalOptions.open}>
        <Modal.Header style={{ borderBottom: 'none' }}>
          <p>{i18n.t('newPrinterErrorTitle')}</p>
        </Modal.Header>
        <Modal.Content>
          <p style={{ fontWeight: 600 }}>{errorWarningModalOptions.message}</p>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
          <Button content="OK" color="teal" onClick={onErrorWarningModalOk} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default OpeningHoursModal;
