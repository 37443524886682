/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-useless-escape */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import i18n from 'i18next';
import { Icon, Grid, Loader, Input } from 'semantic-ui-react';
import { useInView } from 'react-cool-inview';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { useStateValue } from '../../../core/context/StateProvider';
import { hexToRgb } from '../../../utils/ColorFormatter';
import './CustomerHome.css';
import { getItemsPageSortedQr } from '../../../api/ItemAPI';
import { getAllCategoriesOfShopQr } from '../../../api/CategoryAPI';
import { asCurrency } from '../../../utils/NumberFormatter';
import CustomerItem from '../CustomerItem/CustomerItem';
import Checkout from '../Checkout/Checkout';
import CoronaCheckInModal from '../../../components/customer/CoronaCheckInModal/CoronaCheckInModal';
import { createCardPayment, fulfillCardPayment, updateCardPayment } from '../../../api/PaymentAPI';
import SuccessErrorAnimation from '../../../components/customer/SuccessErrorAnimation/SuccessErrorAnimation';
import {
  getCustomerInvoiceCard,
  getCustomerInvoiceCash,
  addEmailToBookingCard,
  addEmailToBookingCash,
} from '../../../api/BookingAPI';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const CustomerHome = () => {
  const [{ shop, config, qrCode, cart, coronaCheckIn, table }, dispatch] = useStateValue();
  const [titleClass, setTitleClass] = useState('customer-title');
  const [data, setData] = useState({ categories: [], items: [], totalPages: 1, currentPage: 1 });
  const [isCheckoutDisabled, setIsCheckoutDisabled] = useState(true);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [itemModalOptions, setItemModalOptions] = useState({ isOpen: false, item: null });
  const [checkoutModalOptions, setCheckoutModalOptions] = useState({ isOpen: false });
  const [coronaCheckInModalOptions, setCoronaCheckInModalOptions] = useState({ isOpen: true });
  const [stripeData, setStripeData] = useState({ clientSecret: '', paymentIntentId: '' });
  const [isCheckingStripe, setIsCheckingStripe] = useState(false);
  const [isGettingInitialData, setIsGettingInitialData] = useState(false);
  const [isCheckingStripePayment, setIsCheckingStripePayment] = useState(false);
  const [paymentModalSettings, setPaymentModalSettings] = useState({
    open: false,
    success: false,
    booking: null,
  });
  const [customerEmail, setCustomerEmail] = useState('');
  const [inputError, setInputError] = useState(false);
  const [showLoadingEmailReceipt, setShowLoadingEmailReceipt] = useState(false);
  const [showLoadingDownloadReceipt, setShowLoadingDownloadReceipt] = useState(false);
  const [isCheckoutLoading, setCheckoutLoading] = useState(false);
  const [isCardPaymentAllowed, setIsCardPaymentAllowed] = useState(true);
  const [booking, setBooking] = useState(null);
  const [downloadedReceipt, setDownloadedReceipt] = useState(false);
  const [downloadReceiptError, setDownloadReceiptError] = useState(null);
  const [emailReceipt, setEmailReceipt] = useState(false);
  const [emailReceiptError, setEmailReceiptError] = useState(null);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(false);

  const darkMode =
    (shop && shop.darkMode) || window.matchMedia('(prefers-color-scheme: dark)').matches;
  const color =
    (shop.color === '#ffffff' || shop.color === '#fff') && darkMode === false
      ? '#000000'
      : (shop.color === '#ffffff' ||
          shop.color === '#fff' ||
          shop.color === '#000000' ||
          shop.color === '#000') &&
        darkMode
      ? '#bebebe'
      : shop.color;
  const rgbShopColor = hexToRgb(color);
  const darkModeBackground = '#0a0a0af2';

  let stripeURLParams = new URLSearchParams(window.location.search).get(
    'payment_intent_client_secret'
  );

  const { observe } = useInView({
    rootMargin: '10px 0px',
    onEnter: ({ unobserve, observe }) => {
      unobserve();
      getMoreItems().then((res) => {
        observe();
      });
    },
  });

  useEffect(() => {
    if (
      (data.totalPages === 0 || data.totalPages === 1 || data.currentPage === data.totalPages) &&
      hasMoreItems === true
    ) {
      setHasMoreItems(false);
    }
    if (data.currentPage < data.totalPages && hasMoreItems === false) {
      setHasMoreItems(true);
    }
    if (
      data.categories.length === 0 &&
      data.items.length === 0 &&
      shop.isOpen &&
      !isGettingInitialData
    ) {
      setIsGettingInitialData(true);
      getInitialData();
    }
    if (cart && cart.length > 0 && isCheckoutDisabled && !stripeURLParams && shop.isOpen) {
      setIsCheckoutDisabled(false);
    }
    if (!cart || (cart && cart.length === 0 && !isCheckoutDisabled) || stripeURLParams) {
      setIsCheckoutDisabled(true);
      if (stripeData.clientSecret.length > 0 && stripeData.paymentIntentId.length > 0) {
        setStripeData({ clientSecret: '', paymentIntentId: '' });
      }
    }
    if (
      cart &&
      cart.length > 0 &&
      !isCheckoutDisabled &&
      shop.allowedPaymentMethods.card === true &&
      stripeData.paymentIntentId.length === 0 &&
      stripeData.clientSecret.length === 0 &&
      !isCardPaymentAllowed &&
      shop.isOpen
    ) {
      setIsCardPaymentAllowed(true);
    }
    if (
      cart &&
      cart.length > 0 &&
      cart.reduce((sum, { amount }) => sum + amount, 0) > 50 &&
      !isCheckoutDisabled &&
      shop.allowedPaymentMethods.card === true &&
      !isCheckingStripe &&
      !stripeURLParams &&
      shop.isOpen &&
      isCardPaymentAllowed &&
      stripeData.paymentIntentId.length === 0 &&
      stripeData.clientSecret.length === 0
    ) {
      setIsCheckingStripe(true);
      createPaymentIntent();
    }
    if (stripeURLParams && stripePromise && cart && cart.length === 0) {
      const currURL = window.location.href;
      const url = currURL.split(window.location.host)[1].split('?')[0];
      window.history.pushState({}, document.title, url);
      stripeURLParams = null;
      dispatch({
        type: 'update',
        overrideCart: [],
      });
      setPaymentModalSettings({ open: true, success: false, booking: null });
    }
    if (
      stripeURLParams &&
      stripePromise &&
      cart &&
      cart.length > 0 &&
      !isCheckingStripePayment &&
      shop.isOpen
    ) {
      setIsCheckingStripePayment(true);
      checkStripePayment();
      const currURL = window.location.href;
      const url = currURL.split(window.location.host)[1].split('?')[0];
      window.history.pushState({}, document.title, url);
      stripeURLParams = null;
      dispatch({
        type: 'update',
        overrideCart: [],
      });
    }
  });

  const { clientSecret } = stripeData;
  const stripeOptions = {
    locale: sessionStorage.getItem('language') || 'en',
    clientSecret,
    paymentMethodCreation: 'manual',
    appearance: {
      variables: {
        colorPrimary: color,
        fontFamily: 'Montserrat, sans-serif',
        borderRadius: '12px',
        colorText: darkMode ? color : '#30313d',
      },
    },
  };

  const onCloseItemScreen = async (shouldUpdateStripe, bookedItems) => {
    setItemModalOptions({ isOpen: false, item: null });
    setCheckoutLoading(true);
    if (
      shouldUpdateStripe &&
      shop.allowedPaymentMethods.card === true &&
      stripeData.paymentIntentId.length > 0 &&
      bookedItems.reduce((sum, { amount }) => sum + amount, 0) > 50
    ) {
      await updatePaymentIntent(bookedItems);
    }
    setCheckoutLoading(false);
  };

  const onCloseCheckoutScreen = () => {
    if (cart.length === 0) setIsCheckoutDisabled(true);
    setCheckoutModalOptions({ isOpen: false });
  };

  const onCloseCoronaCheckInModal = () => {
    setCoronaCheckInModalOptions({ isOpen: false });
  };

  const getCategoryTranslation = (category) => {
    const lang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
    if (
      category.name !== i18n.t('appAllCategories') &&
      lang &&
      category.translations &&
      category.translations.length > 0
    ) {
      const name = category.translations.find((translation) => translation.lang === lang);
      if (name) {
        return name.val;
      }
      const englishName = category.translations.find((translation) => translation.lang === 'en');
      if (englishName) {
        return englishName.val;
      }
      return category.name;
    }
    return category.name;
  };

  const getInitialData = async () => {
    try {
      setIsLoadingInitialData(true);
      const itemsResponse = await getItemsPageSortedQr(shop.id, 1, 20, 'title', 'ascending', null);
      const categoryResponse = await getAllCategoriesOfShopQr(shop.id);
      if (itemsResponse && itemsResponse.success && categoryResponse && categoryResponse.success) {
        const categoriesTmp =
          categoryResponse.categories && categoryResponse.categories.length > 0
            ? categoryResponse.categories.sort((a, b) =>
                getCategoryTranslation(a).localeCompare(getCategoryTranslation(b))
              )
            : [];
        const firstCategory = {
          name: i18n.t('appAllCategories'),
          key: 0,
          image: 'image',
        };
        const allCategories = [firstCategory].concat(categoriesTmp);
        setData({
          categories: allCategories,
          items: itemsResponse.items,
          totalPages: itemsResponse.totalPages,
          currentPage: 1,
        });
        data.totalPages = itemsResponse.totalPages;
        setIsLoadingInitialData(false);
      } else {
        setIsGettingInitialData(false);
        setIsLoadingInitialData(false);
      }
    } catch (error) {
      setIsGettingInitialData(false);
      setIsLoadingInitialData(false);
    }
  };

  const getMoreItems = async () => {
    data.currentPage += 1;
    if (data.currentPage > data.totalPages) {
      setHasMoreItems(false);
    } else {
      let res;
      if (selectedCategoryIndex === 0) {
        res = await getItemsPageSortedQr(shop.id, data.currentPage, 20, 'title', 'ascending');
      } else {
        res = await getItemsPageSortedQr(
          shop.id,
          data.currentPage,
          20,
          'title',
          'ascending',
          data.categories[selectedCategoryIndex]._id
        );
      }
      setData({
        categories: data.categories,
        items: data.items.concat(res.items),
        totalPages: res.totalPages,
        currentPage: data.currentPage,
      });
    }
  };

  const createPaymentIntent = async () => {
    setCheckoutLoading(true);
    try {
      const tableDTO = table
        ? {
            tableId: table.id,
            tableNumber: table.number,
            tableDescription: table.description,
            isTakeAway: table.isTakeAway,
          }
        : null;
      const res = await createCardPayment(shop.id, cart, qrCode, tableDTO);
      if (res && res.success) {
        setStripeData({ clientSecret: res.clientSecret, paymentIntentId: res.paymentIntentId });
        setIsCheckingStripe(false);
        setIsCardPaymentAllowed(true);
      } else {
        setIsCardPaymentAllowed(false);
        setIsCheckingStripe(false);
      }
    } catch (error) {
      setIsCheckingStripe(false);
      setIsCardPaymentAllowed(false);
    }
    setCheckoutLoading(false);
  };

  const updatePaymentIntent = async (bookedItems) => {
    try {
      const res = await updateCardPayment(stripeData.paymentIntentId, shop.id, bookedItems);
      if (res && res.success === true) {
        // nothing to do here
      } else {
        setIsCardPaymentAllowed(false);
      }
    } catch (error) {
      setIsCardPaymentAllowed(false);
    }
  };

  const checkStripePayment = async () => {
    const clientSecret = stripeURLParams;
    try {
      const stripeAsync = await loadStripe(process.env.REACT_APP_STRIPE_PK);
      const result = await stripeAsync.retrievePaymentIntent(clientSecret);
      switch (result.paymentIntent.status) {
        case 'succeeded': {
          const tableDTO = table
            ? {
                tableId: table.id,
                tableNumber: table.number,
                tableDescription: table.description,
                isTakeAway: table.isTakeAway,
              }
            : null;
          const res = await fulfillCardPayment(
            shop.id,
            qrCode,
            null,
            tableDTO,
            result.paymentIntent.id,
            result.paymentIntent.payment_method,
            cart,
            null
          );
          if (res && res.success) {
            setBooking(null);
            setBooking({ ...res.booking });
            setBooking(cloneDeep(res.booking));
            setPaymentModalSettings({ open: true, success: true, booking: res.booking });
          } else {
            setPaymentModalSettings({ open: true, success: false, booking: null });
          }
          break;
        }
        default:
          setPaymentModalSettings({ open: true, success: false, booking: null });
          break;
      }
    } catch (error) {
      setPaymentModalSettings({ open: true, success: false, booking: null });
    }
  };

  const addShadowToTitle = (e) => {
    if (e.target.scrollTop > 0) {
      setTitleClass('customer-title-shadow');
    }
    if (e.target.scrollTop === 0) setTitleClass('customer-title');
  };

  const selectCategory = async (index) => {
    if (index === 0) {
      const res = await getItemsPageSortedQr(shop.id, 1, 20, 'title', 'ascending');
      setData({
        categories: data.categories,
        items: res.items,
        totalPages: res.totalPages,
        currentPage: 1,
      });
    } else {
      const res = await getItemsPageSortedQr(
        shop.id,
        1,
        20,
        'title',
        'ascending',
        data.categories[index]._id
      );
      setData({
        categories: data.categories,
        items: res.items,
        totalPages: res.totalPages,
        currentPage: 1,
      });
    }
    if (data.totalPages === 0 || data.totalPages === 1) setHasMoreItems(false);
    setSelectedCategoryIndex(index);
    document.getElementsByClassName('customer-item-list')[0].scrollTo(0, 0);
  };

  const parseCategories = () => (
    <div
      className="customer-category-wrapper unselectable"
      style={{
        backgroundColor: darkMode
          ? darkModeBackground
          : `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.07)`,
      }}
    >
      {data.categories.map((category, index) => (
        <Fragment key={`cat-custhome-${index}`}>
          <div onClick={() => selectCategory(index)} style={{ cursor: 'pointer' }}>
            <div
              className="customer-category-image"
              style={{
                backgroundColor:
                  index === selectedCategoryIndex ? color : darkMode ? '#1e1f21' : '',
                boxShadow:
                  index === selectedCategoryIndex
                    ? `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.5) 0px 4px 12px`
                    : '',
                textAlign: 'center',
              }}
            >
              {index === 0 ? (
                <Icon
                  name="food"
                  size="large"
                  style={{
                    display: 'table-cell',
                    verticalAlign: 'middle',
                    textAlign: 'center',
                    color: 'white',
                  }}
                />
              ) : (
                <img
                  src={config.cloudinaryUrlPrefix + category.image}
                  style={{
                    height: 30,
                    marginTop: 20,
                  }}
                />
              )}
            </div>
            <p
              className="customer-category-text"
              style={{
                color: index === selectedCategoryIndex ? color : darkMode ? '#5a5959' : '#333',
              }}
            >
              {getCategoryTranslation(category)}
            </p>
          </div>
        </Fragment>
      ))}
    </div>
  );

  const parseItems = () => (
    <>
      <div
        className="customer-item-list unselectable"
        onScroll={addShadowToTitle}
        style={{
          backgroundColor: darkMode
            ? darkModeBackground
            : `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.07)`,
        }}
      >
        <Grid stackable columns={1}>
          {data.items.map((item, index) => {
            const isLastItem = data.items.length === index + 1;
            return (
              <Fragment key={`items-custhome-${index}`}>
                {item.availability === 'qr' || item.availability === 'all' ? (
                  <>
                    <Grid.Column className="customer-home-grid-column">
                      <div
                        ref={observe}
                        onClick={() => {
                          setItemModalOptions({ isOpen: true, item });
                        }}
                      >
                        <div
                          className="customer-item-container"
                          style={{
                            backgroundColor: darkMode ? '#101010' : '',
                            boxShadow: darkMode
                              ? `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.2) 0px 2px 8px`
                              : `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.2) 0px 2px 8px`,
                          }}
                        >
                          <img
                            src={`${config.cloudinaryUrlPrefix + item.picture}`}
                            alt="Logo"
                            className="customer-item-image"
                          />
                          <div className="customer-item-texts">
                            <p
                              className="customer-item-title"
                              style={{ color: darkMode ? '#e1dfe6' : '' }}
                            >
                              {item.title}
                            </p>
                            {item.description ? (
                              <p
                                className="customer-item-description"
                                style={{ color: darkMode ? '#e1dfe6' : '' }}
                              >
                                {item.description}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                          <p
                            className="customer-item-price"
                            style={{
                              color: darkMode
                                ? `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 1)`
                                : `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.75)`,
                            }}
                          >
                            {`${asCurrency(item.price / 100)} €`}
                          </p>
                        </div>
                      </div>
                    </Grid.Column>
                  </>
                ) : (
                  <></>
                )}
              </Fragment>
            );
          })}
        </Grid>
        {hasMoreItems && data.items && data.items.length > 0 ? (
          <>
            <Loader active inline="centered" size="small" style={{ marginTop: 20 }} />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  const parseCategoryName = () => (
    <>
      <p
        className={titleClass}
        style={{
          color: darkMode ? color : '',
          backgroundColor: darkMode
            ? darkModeBackground
            : `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.07)`,
          boxShadow:
            titleClass === 'customer-title-shadow'
              ? darkMode
                ? `0 8px 6px -6px rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.65)`
                : `0 8px 6px -6px rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.35)`
              : '',
        }}
      >
        {data.categories.length > 0
          ? getCategoryTranslation(data.categories[selectedCategoryIndex])
          : ''}
      </p>
    </>
  );

  const parseCheckoutButton = () => (
    <div className="customer-checkout-button-container unselectable">
      <div
        className="customer-checkout-button"
        style={{
          cursor: 'pointer',
          backgroundColor: darkMode ? '#0a0a0a' : color,
          boxShadow: darkMode
            ? `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 1) 0px 1px 10px`
            : `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.95) 0px 4px 12px`,
        }}
        onClick={() => (!isCheckoutLoading ? setCheckoutModalOptions({ isOpen: true }) : null)}
      >
        {isCheckoutLoading ? (
          <>
            <Loader inverted active={isCheckoutLoading} />
          </>
        ) : (
          <>{`${i18n.t('appCheckout')} (${cart.length})`}</>
        )}
      </div>
    </div>
  );

  const closePaymentModal = () => {
    setPaymentModalSettings({ open: false, success: false, booking: null });
    dispatch({
      type: 'update',
      overrideCart: [],
    });
    setIsCheckingStripePayment(false);
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_URL_PREFIX}${process.env.REACT_APP_CUSTOMER_SUBDOMAIN}.${process.env.REACT_APP_QR_WEBSITE}/${qrCode}`;
    }, 500);
  };

  const downloadReceipt = async () => {
    setShowLoadingDownloadReceipt(true);
    try {
      if (paymentModalSettings.booking.paymentType === 'card') {
        await getCustomerInvoiceCard(shop.id, paymentModalSettings.booking.paymentIntentId);
      }
      if (
        paymentModalSettings.booking.paymentType === 'cash' ||
        paymentModalSettings.booking.paymentType === 'later'
      ) {
        await getCustomerInvoiceCash(shop.id, paymentModalSettings.booking._id.toString());
      }
      setTimeout(() => {
        setShowLoadingDownloadReceipt(false);
        setDownloadedReceipt(true);
      }, 1000);
    } catch (error) {
      setDownloadReceiptError(i18n.t('downloadErrorQR'));
      setDownloadedReceipt(true);
      setShowLoadingDownloadReceipt(false);
    }
  };

  const sendReceipt = async () => {
    const validationResult = validateEmail(customerEmail);
    setShowLoadingEmailReceipt(validationResult);
    if (validationResult) {
      setInputError(false);
      try {
        let res;
        if (paymentModalSettings.booking.paymentType === 'card') {
          res = await addEmailToBookingCard(
            paymentModalSettings.booking.paymentIntentId,
            customerEmail
          );
        }
        if (
          paymentModalSettings.booking.paymentType === 'cash' ||
          paymentModalSettings.booking.paymentType === 'later'
        ) {
          res = await addEmailToBookingCash(
            paymentModalSettings.booking._id.toString(),
            customerEmail
          );
        }

        if (res && res.success) {
          setTimeout(() => {
            setShowLoadingEmailReceipt(false);
            setEmailReceipt(true);
          }, 1000);
        } else {
          setEmailReceiptError(i18n.t('emailReceiptError'));
          setEmailReceipt(true);
          setShowLoadingEmailReceipt(false);
        }
      } catch (error) {
        setEmailReceiptError(i18n.t('emailReceiptError'));
        setEmailReceipt(true);
        setShowLoadingEmailReceipt(false);
      }
    } else {
      setInputError(true);
    }
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.replaceAll(/\s/g, ''));
  };

  const parseCardPaymentSuccessModal = () => (
    <>
      {dispatch({
        type: 'update',
        overrideCart: [],
      })}
      <SuccessErrorAnimation success />
      <br />
      <p
        style={{
          fontSize: 16,
          fontWeight: 500,
          textAlign: 'center',
          color: darkMode ? 'white' : '',
        }}
      >
        {i18n.t('appPaySuccess1')}
      </p>
      <p
        style={{
          fontSize: 16,
          fontWeight: 500,
          textAlign: 'center',
          color: darkMode ? 'white' : '',
        }}
      >
        {i18n.t('appPaySuccess2')}
      </p>
      <p style={{ color: darkMode ? 'white' : '' }}>{i18n.t('appPaySuccess3')}</p>
      <Input
        placeholder={i18n.t('appPaySuccess4')}
        name="email"
        value={customerEmail}
        onChange={(e) => {
          setCustomerEmail(e.target.value.replaceAll(/\s/g, ''));
        }}
        className="email-checkout-input"
        error={inputError}
      >
        <input style={{ borderRadius: '15px', border: `1px solid ${color}` }} />
      </Input>
      <div
        className="customer-checkout-payment-button"
        style={{
          cursor: 'pointer',
          backgroundColor: emailReceipt ? '#ccc' : color,
          boxShadow: emailReceipt
            ? ''
            : darkMode
            ? `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.55) 0px 2px 8px`
            : `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.95) 0px 4px 12px`,
          marginTop: 15,
        }}
        onClick={
          () =>
            !showLoadingEmailReceipt && !showLoadingDownloadReceipt && !emailReceipt
              ? sendReceipt()
              : null
          // eslint-disable-next-line react/jsx-curly-newline
        }
      >
        {showLoadingEmailReceipt && !inputError ? (
          <>
            <Loader inline inverted size="tiny" active={showLoadingEmailReceipt && !inputError} />
          </>
        ) : (
          <>
            {emailReceiptError && emailReceiptError.length > 0 ? (
              <>{emailReceiptError}</>
            ) : (
              <>{i18n.t('appPaySuccess5')}</>
            )}
          </>
        )}
      </div>
      <br />
      <p style={{ color: darkMode ? 'white' : '' }}>{i18n.t('appPaySuccess6')}</p>
      <div
        className="customer-checkout-payment-button"
        style={{
          cursor: 'pointer',
          backgroundColor: downloadedReceipt ? '#ccc' : color,
          boxShadow: downloadedReceipt
            ? ''
            : darkMode
            ? `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.55) 0px 2px 8px`
            : `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.95) 0px 4px 12px`,
          marginTop: 15,
        }}
        onClick={
          () =>
            !showLoadingEmailReceipt && !showLoadingDownloadReceipt && !downloadedReceipt
              ? downloadReceipt()
              : null
          // eslint-disable-next-line react/jsx-curly-newline
        }
      >
        {showLoadingDownloadReceipt ? (
          <>
            <Loader inline inverted size="tiny" active />
          </>
        ) : (
          <>
            {downloadReceiptError && downloadReceiptError.length > 0 ? (
              <p style={{ color: darkMode ? 'white' : '' }}>{downloadReceiptError}</p>
            ) : (
              <p style={{ color: darkMode ? 'white' : '' }}>{i18n.t('appPaySuccess7')}</p>
            )}
          </>
        )}
      </div>
      <br />
    </>
  );

  const parseCardPaymentErrorModal = () => (
    <>
      <SuccessErrorAnimation success={false} />
      <br />
      <p
        style={{
          fontSize: 16,
          fontWeight: 500,
          textAlign: 'center',
          color: darkMode ? 'white' : '',
        }}
      >
        {i18n.t('appPayError1')}
      </p>
      <p
        style={{
          fontSize: 16,
          fontWeight: 500,
          textAlign: 'center',
          color: darkMode ? 'white' : '',
        }}
      >
        {i18n.t('appPayError2')}
      </p>
    </>
  );

  return (
    <>
      <Helmet
        meta={[
          {
            name: `theme-color`,
            content: darkMode ? darkModeBackground : color,
          },
          {
            name: 'apple-mobile-web-app-capable',
            content: 'yes',
          },
          {
            name: 'apple-mobile-web-app-status-bar-style',
            content: darkMode ? darkModeBackground : color,
          },
        ]}
      >
        <meta name="theme-color" content={darkMode ? darkModeBackground : color} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content={darkMode ? darkModeBackground : color}
        />
      </Helmet>
      <header
        className="customer-home-header unselectable"
        style={{
          borderBottom: darkMode
            ? ''
            : `1px solid rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.07)`,
        }}
      >
        <div
          className="customer-home-nav"
          style={{
            backgroundColor: darkMode
              ? darkModeBackground
              : `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.07)`,
            color: darkMode ? color : '',
          }}
        >
          <p className="customer-home-nav-title">{shop.name}</p>
        </div>
        <div>
          <nav>
            {shop.isOpen ? (
              <>
                {parseCategories()}
                {parseCategoryName()}
              </>
            ) : (
              <></>
            )}
          </nav>
        </div>
      </header>
      {shop.isOpen ? (
        <>
          {parseItems()}
          {!isCheckoutDisabled ? parseCheckoutButton() : null}
        </>
      ) : (
        <>
          <div
            className="customer-item-list-closed unselectable"
            style={{
              backgroundColor: darkMode
                ? darkModeBackground
                : `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.07)`,
            }}
          >
            <p
              style={{
                textAlign: 'center',
                fontSize: 17,
                fontWeight: 500,
                color: darkMode ? 'white' : '',
              }}
            >
              Ooops...
            </p>
            <p
              style={{
                textAlign: 'center',
                fontSize: 16,
                fontWeight: 500,
                color: darkMode ? 'white' : '',
              }}
            >
              {`${shop.name} ${i18n.t('unfortunatelyClosed')}`}
            </p>
            {shop.openingHours ? (
              <>
                <br />
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: 16,
                    fontWeight: 500,
                    color: darkMode ? 'white' : '',
                  }}
                >
                  {i18n.t('openingHoursTitle')}
                </p>
                {Object.keys(shop.openingHours).map((key, index) => (
                  <Fragment key={`openingh-custhome-${index}`}>
                    {key !== '_id' && shop.openingHours[key].length > 0 ? (
                      <>
                        <p
                          className="opening-hours-keys"
                          style={{
                            fontWeight: 500,
                            textAlign: 'center',
                            color: darkMode ? 'white' : '',
                          }}
                        >
                          {i18n.t(key)}
                          {shop.openingHours[key].map((opening) => (
                            <p>
                              {`${moment
                                .utc()
                                .startOf('day')
                                .add(opening.from, 'minutes')
                                .format('HH:mm')} - ${moment
                                .utc()
                                .startOf('day')
                                .add(opening.to, 'minutes')
                                .format('HH:mm')}`}
                            </p>
                          ))}
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                  </Fragment>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
      <div
        className={`customer-item-screen-overlay ${itemModalOptions.isOpen ? 'show-item' : ''}`}
        onClick={() => onCloseItemScreen()}
      />
      <div className={`customer-item-screen-modal ${itemModalOptions.isOpen ? 'show-item' : ''}`}>
        <CustomerItem
          isOpen={itemModalOptions.isOpen}
          item={itemModalOptions.item}
          allCategories={data ? data.categories : null}
          onClose={
            (shouldUpdateStripe, bookedItems) => onCloseItemScreen(shouldUpdateStripe, bookedItems)
            // eslint-disable-next-line react/jsx-curly-newline
          }
        />
      </div>
      <div
        className={`customer-stripe-overlay ${
          isCheckingStripePayment || paymentModalSettings.open ? 'show-stripe-overlay' : ''
        }`}
      >
        <Loader
          size="big"
          inverted
          active={isCheckingStripePayment && !paymentModalSettings.open}
        />
      </div>
      <div
        className={`customer-stripe-modal ${paymentModalSettings.open ? 'show-stripe-modal' : ''}`}
      >
        <>
          <div
            className="checkin-cash-container unselectable"
            style={{
              backgroundColor: darkMode ? 'black' : '',
              borderTop: darkMode ? `1px solid ${color}` : '',
            }}
          >
            <div
              style={{
                backgroundColor: darkMode ? 'black' : '#fff',
                position: 'absolute',
                top: 10,
                left: 20,
                height: 30,
                width: 30,
                color: `#333`,
                borderRadius: 15,
                cursor: 'pointer',
                border: '1px solid #aaa',
                zIndex: 1072,
              }}
            >
              <div
                style={{
                  backgroundColor: `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.06)`,
                  borderRadius: 13,
                }}
                onClick={() => closePaymentModal()}
              >
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: 24,
                    fontWeight: 500,
                    marginTop: -2,
                    color: darkMode ? color : '',
                  }}
                >
                  ×
                </p>
              </div>
            </div>
            {paymentModalSettings.success && paymentModalSettings.open
              ? parseCardPaymentSuccessModal()
              : parseCardPaymentErrorModal()}
          </div>
        </>
      </div>
      <div
        className={`customer-checkout-screen-overlay ${
          checkoutModalOptions.isOpen ? 'show-checkout' : ''
        }`}
        onClick={() => onCloseCheckoutScreen()}
      />
      <div
        className={`customer-checkout-screen-modal ${
          checkoutModalOptions.isOpen ? 'show-checkout' : ''
        }`}
      >
        {cart &&
        cart.length > 0 &&
        !isCheckoutDisabled &&
        shop.allowedPaymentMethods.card === true &&
        isCardPaymentAllowed &&
        clientSecret.length > 0 ? (
          <>
            <Elements stripe={stripePromise} options={stripeOptions}>
              <Checkout
                isOpen={checkoutModalOptions.isOpen}
                onClose={() => onCloseCheckoutScreen()}
                withStripe={isCardPaymentAllowed}
                paymentIntentId={stripeData.paymentIntentId}
              />
            </Elements>
          </>
        ) : (
          <>
            <Checkout
              isOpen={checkoutModalOptions.isOpen}
              onClose={() => onCloseCheckoutScreen()}
            />
          </>
        )}
      </div>
      {!coronaCheckIn && shop.coronaCheckIn ? (
        <>
          <div
            className={`corona-checkin-modal-overlay ${
              coronaCheckInModalOptions.isOpen ? 'show-corona' : ''
            }`}
          />
          <div
            className={`corona-checkin-modal ${
              coronaCheckInModalOptions.isOpen ? 'show-corona' : ''
            }`}
          >
            <CoronaCheckInModal
              color={color}
              isOpen={coronaCheckInModalOptions.isOpen}
              onClose={() => onCloseCoronaCheckInModal()}
            />
          </div>
        </>
      ) : (
        <></>
      )}
      {isLoadingInitialData === true && (
        <>
          <Loader active={isLoadingInitialData} />
        </>
      )}
    </>
  );
};
export default CustomerHome;
