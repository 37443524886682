/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-plusplus */
export const abbreviate = (value) => {
  const rValue = Math.floor(value);
  let newValue = rValue;
  if (value >= 1000) {
    const suffixes = ['', 'k', 'm', 'b', 't'];
    const suffixNum = Math.floor(`${rValue}`.length / 3);
    let shortValue = '';
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0 ? rValue / Math.pow(1000, suffixNum) : rValue).toPrecision(precision)
      );
      const dotLessShortValue = `${shortValue}`.replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
};

export const round = (num, decimalPlaces = 0) => {
  const number = Math.round(`${num}e${decimalPlaces}`);
  return Number(`${number}e${-decimalPlaces}`);
};

export const asCurrency = (value) =>
  value.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 });

export const normalizeZero = (value) => {
  if (Object.is(value, -0)) {
    return 0;
  }
  return value;
};
