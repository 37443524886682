/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import i18n from 'i18next';
import { isEqual, countBy, findLastIndex, sortBy } from 'lodash';
import { Input, Loader } from 'semantic-ui-react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useStateValue } from '../../../core/context/StateProvider';
import { hexToRgb } from '../../../utils/ColorFormatter';
import './Checkout.css';
import { asCurrency } from '../../../utils/NumberFormatter';
import {
  createCashPayment,
  updateApplicationFees,
  updateCardPayment,
} from '../../../api/PaymentAPI';
import SuccessErrorAnimation from '../../../components/customer/SuccessErrorAnimation/SuccessErrorAnimation';
import {
  getCustomerInvoiceCard,
  getCustomerInvoiceCash,
  addEmailToBookingCash,
} from '../../../api/BookingAPI';
import consumeCode from '../../../api/QRAPI';

const Checkout = (props) => {
  const [{ shop, cart, qrCode, table }, dispatch] = useStateValue();
  const { isOpen, onClose, withStripe, paymentIntentId } = props;
  const stripe = withStripe ? useStripe() : null;
  const elements = withStripe ? useElements() : null;
  const [items, setItems] = useState([]);
  const [showCashPaymentModal, setShowCashPaymentModal] = useState(false);
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showLoading2, setShowLoading2] = useState(false);
  const [showCashSuccess, setShowCashSuccess] = useState(false);
  const [showCashError, setShowCashError] = useState(false);
  const [customerEmail, setCustomerEmail] = useState('');
  const [inputError, setInputError] = useState(false);
  const [isStripeAllowed, setIsStripeAllowed] = useState(true);
  const [isUpdatingPaymentIntent, setIsUpdatingPaymentIntent] = useState(false);
  const [isPaymentOngoing, setIsPaymentOngoing] = useState(false);
  const [showStripeError, setShowStripeError] = useState(false);
  const [booking, setBooking] = useState(null);
  const [downloadedReceipt, setDownloadedReceipt] = useState(false);
  const [downloadReceiptError, setDownloadReceiptError] = useState(null);
  const [emailReceipt, setEmailReceipt] = useState(false);
  const [emailReceiptError, setEmailReceiptError] = useState(null);
  const [payByCardEnabled, setPayByCardEnabled] = useState(false);
  const [isElementInitiallyUpdated, setIsElementInitiallyUpdated] = useState(false);

  const darkMode =
    (shop && shop.darkMode) || window.matchMedia('(prefers-color-scheme: dark)').matches;
  const color =
    (shop.color === '#ffffff' || shop.color === '#fff') && darkMode === false
      ? '#000000'
      : (shop.color === '#ffffff' ||
          shop.color === '#fff' ||
          shop.color === '#000000' ||
          shop.color === '#000') &&
        darkMode
      ? '#bebebe'
      : shop.color;
  const rgbShopColor = hexToRgb(color);

  useEffect(() => {
    if (cart && items.length !== cart.length && cart.length > 0) {
      setItems(sortBy([...cart], ['itemTitle', 'amount']));
    }
    if (
      cart &&
      cart.length === 0 &&
      isOpen &&
      showCashSuccess === false &&
      showCashError === false
    ) {
      closeModal();
    }
    if ((!isOpen || isOpen === false) && isStripeAllowed === false) {
      setIsStripeAllowed(true);
    }
    if (
      isOpen === true &&
      isStripeAllowed === true &&
      withStripe === true &&
      isElementInitiallyUpdated === false
    ) {
      updateStripeElements();
    }
  });

  const closeModal = (deleteCart) => {
    if (showCashSuccess === true || showCashError === true) {
      window.location.href = `${process.env.REACT_APP_URL_PREFIX}${process.env.REACT_APP_CUSTOMER_SUBDOMAIN}.${process.env.REACT_APP_QR_WEBSITE}/${qrCode}`;
    }
    if (items.length > 0) {
      setItems([]);
    }
    setShowCashPaymentModal(false);
    setShowStripeModal(false);
    setShowLoading(false);
    setShowLoading2(false);
    setShowCashSuccess(false);
    setShowCashError(false);
    setCustomerEmail('');
    setInputError(false);
    setIsStripeAllowed(true);
    setIsElementInitiallyUpdated(false);
    setIsUpdatingPaymentIntent(false);
    setIsPaymentOngoing(false);
    setShowStripeError(false);
    setBooking(null);
    setDownloadedReceipt(false);
    setDownloadReceiptError(null);
    setEmailReceipt(false);
    setEmailReceiptError(null);
    setPayByCardEnabled(false);
    if (deleteCart) {
      dispatch({
        type: 'update',
        overrideCart: [],
      });
    }
    if (isOpen) {
      onClose();
    }
  };

  const updateStripeElements = async () => {
    try {
      await elements.fetchUpdates();
      setIsElementInitiallyUpdated(true);
    } catch (error) {
      setIsStripeAllowed(false);
    }
  };

  const updatePaymentIntent = async (bookedItems) => {
    if (bookedItems.reduce((sum, { amount }) => sum + amount, 0) > 50) {
      setIsUpdatingPaymentIntent(true);
      try {
        const res = await updateCardPayment(paymentIntentId, shop.id, bookedItems);
        if (res && res.success === true) {
          setIsStripeAllowed(true);
          await elements.fetchUpdates();
        } else {
          setIsStripeAllowed(false);
        }
      } catch (error) {
        setIsStripeAllowed(false);
      }
      setIsUpdatingPaymentIntent(false);
    } else {
      setIsStripeAllowed(false);
    }
  };

  const getTotalAmount = () => items.reduce((sum, { amount }) => sum + amount, 0);

  const subtractBookedItem = async (index) => {
    if (!isUpdatingPaymentIntent) {
      items.splice(
        items.findIndex((element) => isEqual(element, items[index])),
        1
      );
      dispatch({
        type: 'update',
        overrideCart: items,
      });
      setItems(sortBy([...items], ['itemTitle', 'amount']));
      if (withStripe && shop.allowedPaymentMethods.card === true) await updatePaymentIntent(items);
    }
  };

  const addSameBookedItem = async (index) => {
    if (!isUpdatingPaymentIntent && items && items.length < 150) {
      const bookedItem = items[index];
      const bookedItems = items;
      dispatch({
        type: 'update',
        toCart: bookedItem,
      });
      setItems(sortBy([...items], ['itemTitle', 'amount']));
      if (withStripe && shop.allowedPaymentMethods.card === true)
        await updatePaymentIntent([...bookedItems, bookedItem]);
    }
  };

  const payByCash = async () => {
    const bookedItems = cart;
    const tableDTO = table
      ? {
          tableId: table.id,
          tableNumber: table.number,
          tableDescription: table.description,
          isTakeAway: table.isTakeAway,
        }
      : null;
    try {
      setShowLoading(true);
      const res = await createCashPayment(shop.id, qrCode, null, tableDTO, bookedItems, null);
      if (res && res.success) {
        setShowCashSuccess(true);
        setShowLoading(false);
        setBooking(res.booking);
        dispatch({
          type: 'update',
          overrideCart: [],
        });
      } else {
        setShowCashError(true);
        setShowLoading(false);
      }
    } catch (error) {
      setShowCashError(true);
      setShowLoading(false);
    }
  };

  const payByCard = async () => {
    try {
      if (!stripe || !elements) {
        setShowStripeError(true);
      } else {
        setIsPaymentOngoing(true);
        const res = await consumeCode(qrCode);
        if (res && res.success && res.shop.allowedPaymentMethods.card === true) {
          const { error: submitError } = await elements.submit();
          if (submitError) {
            setShowStripeError(true);
            setIsPaymentOngoing(false);
            return;
          }
          const { paymentMethod, error: paymentMethodError } = await stripe.createPaymentMethod({
            elements,
          });
          if (paymentMethodError) {
            setShowStripeError(true);
            setIsPaymentOngoing(false);
            return;
          }
          const res = await updateApplicationFees(
            paymentIntentId,
            paymentMethod.card.country,
            getTotalAmount(),
            shop.id
          );
          if (res && res.success) {
            const { error: updateError } = await elements.fetchUpdates();
            if (updateError) {
              setShowStripeError(true);
              setIsPaymentOngoing(false);
              return;
            }
            const { error } = await stripe.confirmPayment({
              elements,
              confirmParams: {
                return_url: `${process.env.REACT_APP_URL_PREFIX}${
                  process.env.REACT_APP_CUSTOMER_SUBDOMAIN
                }.${process.env.REACT_APP_QR_WEBSITE}/${encodeURIComponent(qrCode)}`,
              },
            });
            if (error) {
              setShowStripeError(true);
              setIsPaymentOngoing(false);
            }
          } else {
            setShowStripeError(true);
            setIsPaymentOngoing(false);
            return;
          }
        } else {
          setShowStripeError(true);
          setIsPaymentOngoing(false);
        }
      }
    } catch (error) {
      setShowStripeError(true);
      setIsPaymentOngoing(false);
    }
  };

  const countElement = (bookedItems, index) => {
    const cleanedUpBookedItems = bookedItems.map((item) => {
      const newItem = {
        itemTitle: item.itemTitle,
        categoryName: item.categoryName,
        specialSelections: item.specialSelections.map((special) => ({
          specialSelectionId: special.specialSelectionId,
          specialSelectionTitle: special.specialSelectionTitle,
          specialSelectionPrice: special.specialSelectionPrice,
        })),
        options:
          item.options && item.options.length > 0
            ? item.options.map((option) => ({ name: option.name, price: option.price }))
            : null,
        amount: item.amount,
        taxRate: item.taxRate,
        isRefund: item.isRefund,
      };
      if (item.notes) newItem.notes = item.notes;
      return newItem;
    });
    return countBy(cleanedUpBookedItems, (element) => isEqual(element, cleanedUpBookedItems[index]))
      .true;
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.replaceAll(/\s/g, ''));
  };

  const sendReceipt = async () => {
    const validationResult = validateEmail(customerEmail);
    setShowLoading(validationResult);
    if (validationResult) {
      setInputError(false);
      try {
        let res;
        if (booking.paymentType === 'card') {
          res = await addEmailToBookingCash(booking.paymentIntentId, customerEmail);
        }
        if (booking.paymentType === 'cash' || booking.paymentType === 'later') {
          res = await addEmailToBookingCash(booking._id.toString(), customerEmail);
        }
        if (res && res.success) {
          setTimeout(() => {
            setShowLoading(false);
            setEmailReceipt(true);
          }, 1000);
        } else {
          setEmailReceiptError(i18n.t('emailReceiptError'));
          setEmailReceipt(true);
          setShowLoading(false);
        }
      } catch (error) {
        setEmailReceiptError(i18n.t('emailReceiptError'));
        setEmailReceipt(true);
        setShowLoading(false);
      }
    } else {
      setInputError(true);
    }
  };

  const downloadReceipt = async () => {
    setShowLoading2(true);
    try {
      if (booking.paymentType === 'card') {
        await getCustomerInvoiceCard(shop.id, booking.paymentIntentId);
      }
      if (booking.paymentType === 'cash' || booking.paymentType === 'later') {
        await getCustomerInvoiceCash(shop.id, booking._id.toString());
      }
      setTimeout(() => {
        setShowLoading2(false);
        setDownloadedReceipt(true);
      }, 1000);
    } catch (error) {
      setDownloadReceiptError(i18n.t('downloadErrorQR'));
      setDownloadedReceipt(true);
      setShowLoading2(false);
    }
  };

  const parseCart = () => {
    const cleanedUpBookedItems = items.map((item) => {
      const newItem = {
        itemTitle: item.itemTitle,
        categoryName: item.categoryName,
        specialSelections: item.specialSelections.map((special) => ({
          specialSelectionId: special.specialSelectionId,
          specialSelectionTitle: special.specialSelectionTitle,
          specialSelectionPrice: special.specialSelectionPrice,
        })),
        options:
          item.options && item.options.length > 0
            ? item.options.map((option) => ({ name: option.name, price: option.price }))
            : null,
        amount: item.amount,
        taxRate: item.taxRate,
        isRefund: item.isRefund,
      };
      if (item.notes) newItem.notes = item.notes;
      return newItem;
    });
    return items.map((bookedItem, index) => (
      <Fragment key={`cart-checkout-${index}`}>
        {index ===
        findLastIndex(cleanedUpBookedItems, (element) =>
          isEqual(element, cleanedUpBookedItems[index])
        ) ? (
          <>
            <div className="checkout-screen-bookedItem-flex" style={{ fontWeight: 500 }}>
              <div style={{ display: 'flex', width: '74%' }}>
                <p
                  style={{
                    fontSize: '12px',
                    fontWeight: 600,
                    width: 13,
                    color: `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.8)`,
                    paddingTop: 1,
                  }}
                >
                  {countElement(items, index)}
                </p>
                <p
                  style={{
                    paddingLeft: 6,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: darkMode ? '#e1dfe6' : '',
                  }}
                >
                  {` ${bookedItem.itemTitle}`}
                </p>
              </div>
              <div style={{ textAlign: 'right', color: darkMode ? '#e1dfe6' : '' }}>
                {`${asCurrency(bookedItem.amount / 100)} €`}
              </div>
            </div>
            <div className="checkout-screen-specialSelections-count-flex-qr">
              <div className="checkout-screen-specialSelections">
                {bookedItem.options &&
                  bookedItem.options.map((option, idxx) => (
                    <p
                      key={`opt-cart-${option.name}${idxx}`}
                      style={{
                        marginBottom: 0,
                        color: darkMode ? 'white' : '',
                      }}
                    >
                      {`· ${option.name}`}
                    </p>
                  ))}
                {bookedItem.specialSelections.map((specialSelection, idxxx) => (
                  <p
                    key={`opt-cart-${specialSelection.specialSelectionTitle}${idxxx}`}
                    style={{
                      marginBottom: 0,
                      color: darkMode ? 'white' : '',
                    }}
                  >
                    {`· ${specialSelection.specialSelectionTitle}`}
                  </p>
                ))}
                {bookedItem.notes ? (
                  <p style={{ marginBottom: 0, color: darkMode ? 'white' : '' }}>
                    {`· ${bookedItem.notes}`}
                  </p>
                ) : (
                  <></>
                )}
              </div>
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    border: `1px solid rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.8)`,
                    width: '24px',
                    borderRadius: '12px',
                    textAlign: 'center',
                    height: '24px',
                    marginRight: '6px',
                    fontSize: '16px',
                    fontWeight: '600',
                    backgroundColor: darkMode ? 'transparent' : 'white',
                    color: `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.8)`,
                    fontStyle: 'normal',
                    cursor: 'pointer',
                    marginBottom: '22px',
                  }}
                  onClick={() => subtractBookedItem(index)}
                >
                  -
                </div>
                <div
                  style={{
                    border: `1px solid rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.8)`,
                    borderRadius: '12px',
                    width: '24px',
                    textAlign: 'center',
                    height: '24px',
                    fontSize: '16px',
                    fontWeight: '600',
                    backgroundColor: darkMode ? 'transparent' : 'white',
                    color: `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.8)`,
                    paddingTop: '1px',
                    fontStyle: 'normal',
                    cursor: 'pointer',
                    marginBottom: '22px',
                  }}
                  onClick={() => addSameBookedItem(index)}
                >
                  +
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </Fragment>
    ));
  };

  return (
    <>
      {isOpen ? (
        <div className="checkout-screen-container unselectable">
          <div
            style={{
              backgroundColor: darkMode ? 'transparent' : '#fff',
              position: 'absolute',
              top: -25,
              left: 20,
              height: 30,
              width: 30,
              color: `#333`,
              borderRadius: 15,
              cursor: 'pointer',
              border: '1px solid #aaa',
              zIndex: 1072,
            }}
            onClick={() => closeModal()}
          >
            <div
              style={{
                backgroundColor: `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.06)`,
                borderRadius: 13,
              }}
            >
              <p
                style={{
                  textAlign: 'center',
                  fontSize: 24,
                  fontWeight: 500,
                  marginTop: -2,
                  color: darkMode ? color : '',
                }}
              >
                ×
              </p>
            </div>
          </div>
          <div
            className="checkout-screen-scroll-container"
            style={{ backgroundColor: darkMode ? '#161616' : '' }}
          >
            <div
              className="checkout-screen-header"
              style={{
                backgroundColor: darkMode
                  ? '#161616'
                  : `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.07)`,
              }}
            >
              <p className="checkout-screen-title" style={{ color: darkMode ? color : '' }}>
                {i18n.t('appCheckoutTitle')}
              </p>
            </div>
            <div
              className="checkout-screen-scroll-container-inside"
              style={{
                backgroundColor: darkMode
                  ? '#161616'
                  : `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.07)`,
              }}
            >
              <div className="checkout-screen-order-summary-container">
                <div
                  className="checkout-screen-tile-container"
                  style={{
                    marginTop: 4,
                    boxShadow: darkMode
                      ? `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.2) 0px 2px 8px`
                      : '',
                    backgroundColor: darkMode ? '#101010' : '',
                  }}
                >
                  <p
                    className="checkout-screen-tile-title"
                    style={{ color: darkMode ? '#e1dfe6' : '' }}
                  >
                    {i18n.t('appOrderSummary')}
                  </p>
                  {parseCart()}
                  <div style={{ width: '100%', borderBottom: '1px solid #888' }} />
                  <div className="checkout-screen-total-flex">
                    <p style={{ fontWeight: 600, fontSize: 15, color: darkMode ? '#e1dfe6' : '' }}>
                      {i18n.t('appTotal')}
                    </p>
                    <p style={{ fontWeight: 600, fontSize: 15, color: darkMode ? '#e1dfe6' : '' }}>
                      {`${asCurrency(getTotalAmount() / 100)} €`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="checkout-screen-order-summary-container">
                <div
                  className="checkout-screen-tile-container"
                  style={{
                    boxShadow: darkMode
                      ? `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.2) 0px 2px 8px`
                      : '',
                    backgroundColor: darkMode ? '#101010' : '',
                  }}
                >
                  <p
                    className="checkout-screen-tile-title"
                    style={{ color: darkMode ? '#e1dfe6' : '' }}
                  >
                    {i18n.t('appPayment')}
                  </p>
                  {shop.allowedPaymentMethods.card === true &&
                  withStripe &&
                  isStripeAllowed &&
                  cart &&
                  cart.length > 0 &&
                  cart.reduce((sum, { amount }) => sum + amount, 0) > 50 ? (
                    <>
                      <div
                        className="customer-checkout-payment-button"
                        style={{
                          cursor: 'pointer',
                          backgroundColor: darkMode ? '#0a0a0a' : color,
                          boxShadow: darkMode
                            ? `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.55) 0px 1px 8px`
                            : `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.95) 0px 4px 12px`,
                        }}
                        onClick={() => {
                          if (!showStripeModal && !isUpdatingPaymentIntent) {
                            setShowStripeModal(true);
                            setShowLoading(true);
                          }
                        }}
                      >
                        {isUpdatingPaymentIntent ? (
                          <>
                            <Loader inline size="tiny" inverted active={isUpdatingPaymentIntent} />
                          </>
                        ) : (
                          <>{i18n.t('appPayWithCard')}</>
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <br />
                  {shop.allowedPaymentMethods.cash === true ? (
                    <>
                      <div
                        className="customer-checkout-payment-button"
                        style={{
                          cursor: 'pointer',
                          backgroundColor: darkMode ? '#0a0a0a' : color,
                          boxShadow: darkMode
                            ? `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.55) 0px 1px 8px`
                            : `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.95) 0px 4px 12px`,
                        }}
                        onClick={() => setShowCashPaymentModal(true)}
                      >
                        {i18n.t('appPayWithCashButton')}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {shop.allowedPaymentMethods.cash === false &&
                    shop.allowedPaymentMethods.card === false && (
                      <>
                        <p
                          style={{
                            fontWeight: 600,
                            textAlign: 'center',
                            color: darkMode ? 'white' : '',
                          }}
                        >
                          {i18n.t('paymentImpossibleError1')}
                        </p>
                        <p
                          style={{
                            fontWeight: 600,
                            textAlign: 'center',
                            color: darkMode ? 'white' : '',
                          }}
                        >
                          {i18n.t('paymentImpossibleError2')}
                        </p>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
          {showCashPaymentModal ? (
            <>
              <div
                className={`checkin-cash-modal-overlay ${showCashPaymentModal ? 'show-cash' : ''}`}
              />
              <div className={`checkin-cash-modal ${showCashPaymentModal ? 'show-cash' : ''}`}>
                <div
                  className="checkin-cash-container"
                  style={{
                    backgroundColor: darkMode ? 'black' : '',
                    borderTop: `1px solid ${color}`,
                  }}
                >
                  {showCashSuccess ? (
                    <>
                      <div
                        style={{
                          backgroundColor: darkMode ? 'transparent' : '#fff',
                          position: 'absolute',
                          top: 10,
                          left: 20,
                          height: 30,
                          width: 30,
                          color: `#333`,
                          borderRadius: 15,
                          cursor: 'pointer',
                          border: '1px solid #aaa',
                          zIndex: 1072,
                        }}
                        onClick={() => closeModal(true)}
                      >
                        <div
                          style={{
                            backgroundColor: `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.06)`,
                            borderRadius: 13,
                          }}
                        >
                          <p
                            style={{
                              textAlign: 'center',
                              fontSize: 24,
                              fontWeight: 500,
                              marginTop: -2,
                              color: darkMode ? color : '',
                            }}
                          >
                            ×
                          </p>
                        </div>
                      </div>
                      <SuccessErrorAnimation success />
                      <br />
                      <p
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          textAlign: 'center',
                          color: darkMode ? 'white' : '',
                        }}
                      >
                        {i18n.t('appPaySuccess1')}
                      </p>
                      <p
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          textAlign: 'center',
                          color: darkMode ? 'white' : '',
                        }}
                      >
                        {i18n.t('appPaySuccess2cash')}
                      </p>
                      <p style={{ color: darkMode ? 'white' : '' }}>
                        {i18n.t('appPaySuccess3cash')}
                      </p>
                      <Input
                        placeholder={i18n.t('appPaySuccess4')}
                        name="email"
                        value={customerEmail}
                        onChange={(e) => {
                          setCustomerEmail(e.target.value.replaceAll(/\s/g, ''));
                        }}
                        className="email-checkout-input"
                        error={inputError}
                      >
                        <input style={{ borderRadius: '15px', border: `1px solid ${color}` }} />
                      </Input>
                      <div
                        className="customer-checkout-payment-button"
                        style={{
                          cursor: 'pointer',
                          backgroundColor: emailReceipt ? '#ccc' : color,
                          boxShadow: emailReceipt
                            ? ''
                            : `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.95) 0px 4px 12px`,
                          marginTop: 15,
                          color: darkMode ? 'white' : '',
                        }}
                        onClick={
                          async () =>
                            !showLoading && !showLoading2 && !emailReceipt ? sendReceipt() : null
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      >
                        {showLoading && !inputError ? (
                          <>
                            <Loader
                              inline
                              inverted
                              size="tiny"
                              active={showLoading && !inputError}
                            />
                          </>
                        ) : (
                          <>
                            {emailReceiptError && emailReceiptError.length > 0 ? (
                              <>{emailReceiptError}</>
                            ) : (
                              <>{i18n.t('appPaySuccess5cash')}</>
                            )}
                          </>
                        )}
                      </div>
                      <br />
                      <p style={{ color: darkMode ? 'white' : '' }}>{i18n.t('appPaySuccess6')}</p>
                      <div
                        className="customer-checkout-payment-button"
                        style={{
                          cursor: 'pointer',
                          backgroundColor: downloadedReceipt ? '#ccc' : color,
                          boxShadow: downloadedReceipt
                            ? ''
                            : `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.95) 0px 4px 12px`,
                          marginTop: 15,
                          color: darkMode ? 'white' : '',
                        }}
                        onClick={
                          async () =>
                            !showLoading && !showLoading2 && !downloadedReceipt
                              ? downloadReceipt()
                              : null
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      >
                        {showLoading2 ? (
                          <>
                            <Loader inline inverted size="tiny" active={showLoading2} />
                          </>
                        ) : (
                          <>
                            {downloadReceiptError && downloadReceiptError.length > 0 ? (
                              <>{downloadReceiptError}</>
                            ) : (
                              <>{i18n.t('appPaySuccess7')}</>
                            )}
                          </>
                        )}
                      </div>
                      <br />
                    </>
                  ) : (
                    <>
                      {showCashError ? (
                        <>
                          <div
                            style={{
                              backgroundColor: darkMode ? 'transparent' : '#fff',
                              position: 'absolute',
                              top: 10,
                              left: 20,
                              height: 30,
                              width: 30,
                              color: `#333`,
                              borderRadius: 15,
                              cursor: 'pointer',
                              border: '1px solid #aaa',
                              zIndex: 1072,
                            }}
                            onClick={() => closeModal(false)}
                          >
                            <div
                              style={{
                                backgroundColor: `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.06)`,
                                borderRadius: 13,
                              }}
                            >
                              <p
                                style={{
                                  textAlign: 'center',
                                  fontSize: 24,
                                  fontWeight: 500,
                                  marginTop: -2,
                                  color: darkMode ? color : '',
                                }}
                              >
                                ×
                              </p>
                            </div>
                          </div>
                          <SuccessErrorAnimation success={false} />
                          <br />
                          <p
                            style={{
                              fontSize: 16,
                              fontWeight: 500,
                              textAlign: 'center',
                              color: darkMode ? 'white' : '',
                            }}
                          >
                            {i18n.t('appPayError1')}
                          </p>
                          <p
                            style={{
                              fontSize: 16,
                              fontWeight: 500,
                              textAlign: 'center',
                              color: darkMode ? 'white' : '',
                            }}
                          >
                            {i18n.t('appPayError2')}
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            style={{
                              fontSize: 18,
                              fontWeight: 500,
                              textAlign: 'center',
                              color: darkMode ? 'white' : '',
                            }}
                          >
                            {i18n.t('appPayWithCash')}
                          </p>
                          <p
                            style={{
                              fontSize: 16,
                              fontWeight: 400,
                              textAlign: 'center',
                              color: darkMode ? 'white' : '',
                            }}
                          >
                            {i18n.t('appPayWithCashSubtitle')}
                          </p>
                          <br />
                          <p style={{ fontSize: 12, margin: 0, color: darkMode ? 'white' : '' }}>
                            {i18n.t('dataprivacyWarning1')}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://moaby.app/files/moaby-Datenschutzerklaerung.pdf"
                            >
                              {i18n.t('dataprivacyWarning2')}
                            </a>
                            {i18n.t('dataprivacyWarning3')}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://moaby.app/files/moaby-AGB-Endkunde.pdf"
                            >
                              {i18n.t('dataprivacyWarning4')}
                            </a>
                            {i18n.t('dataprivacyWarning5')}
                          </p>
                          <br />
                          <div style={{ display: 'flex', gap: 16, margin: 'auto 0' }}>
                            <div
                              className="customer-checkout-payment-button"
                              style={{
                                cursor: 'pointer',
                                color,
                                backgroundColor: darkMode ? 'black' : 'white',
                                border: `1px solid ${color}`,
                                flexGrow: 1,
                                flexBasis: 0,
                                width: 0,
                              }}
                              onClick={() => setShowCashPaymentModal(false)}
                            >
                              {i18n.t('appPayCancel')}
                            </div>
                            <div
                              className="customer-checkout-payment-button"
                              style={{
                                cursor: 'pointer',
                                backgroundColor: color,
                                boxShadow: `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.95) 0px 2px 10px`,
                                flexGrow: 1,
                                flexBasis: 0,
                                width: 0,
                              }}
                              onClick={() => (showLoading === false ? payByCash() : null)}
                            >
                              {showLoading ? (
                                <>
                                  <Loader active={showLoading} inline inverted size="tiny" />
                                </>
                              ) : (
                                <>
                                  {`${i18n.t('appPayPay')} ${asCurrency(getTotalAmount() / 100)} €`}
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {showStripeModal ? (
            <>
              {showStripeError ? (
                <>
                  <div
                    className={`checkin-card-modal-overlay ${showStripeModal ? 'show-card' : ''}`}
                  />
                  <div className={`checkin-card-modal ${showStripeModal ? 'show-card' : ''}`}>
                    <div
                      className="checkin-card-container"
                      style={{
                        backgroundColor: darkMode ? 'black' : '',
                        borderTop: `1px solid ${color}`,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: darkMode ? 'transparent' : '#fff',
                          position: 'absolute',
                          top: 10,
                          left: 20,
                          height: 30,
                          width: 30,
                          color: `#333`,
                          borderRadius: 15,
                          cursor: 'pointer',
                          border: '1px solid #aaa',
                          zIndex: 1072,
                        }}
                        onClick={() => closeModal(false)}
                      >
                        <div
                          style={{
                            backgroundColor: `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.06)`,
                            borderRadius: 13,
                          }}
                        >
                          <p
                            style={{
                              textAlign: 'center',
                              fontSize: 24,
                              fontWeight: 500,
                              marginTop: -2,
                              color: darkMode ? color : '',
                            }}
                          >
                            ×
                          </p>
                        </div>
                      </div>
                      <SuccessErrorAnimation success={false} />
                      <br />
                      <p
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          textAlign: 'center',
                          color: darkMode ? 'white' : '',
                        }}
                      >
                        {i18n.t('appPayError1')}
                      </p>
                      <p
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          textAlign: 'center',
                          color: darkMode ? 'white' : '',
                        }}
                      >
                        {i18n.t('appPayError2')}
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={`checkin-card-modal-overlay ${showStripeModal ? 'show-card' : ''}`}
                  />
                  <div className={`checkin-card-modal ${showStripeModal ? 'show-card' : ''}`}>
                    <div
                      className="checkin-card-container"
                      style={{
                        backgroundColor: darkMode ? 'black' : '',
                        borderTop: `1px solid ${color}`,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 18,
                          fontWeight: 500,
                          textAlign: 'center',
                          color: darkMode ? color : '',
                        }}
                      >
                        {i18n.t('appPayWithCardTitle')}
                      </p>
                      <PaymentElement
                        onReady={() => setShowLoading(false)}
                        onChange={(event) => {
                          if (event.complete === true) {
                            setPayByCardEnabled(true);
                          } else {
                            setPayByCardEnabled(false);
                          }
                        }}
                      />
                      <Loader active={showLoading} />
                      {showLoading ? (
                        <>
                          <br />
                          <br />
                        </>
                      ) : (
                        <>
                          <br />
                          <p style={{ fontSize: 12, margin: 0, color: darkMode ? 'white' : '' }}>
                            {i18n.t('dataprivacyWarning1')}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://moaby.app/files/moaby-Datenschutzerklaerung.pdf"
                            >
                              {i18n.t('dataprivacyWarning2')}
                            </a>
                            {i18n.t('dataprivacyWarning3')}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://moaby.app/files/moaby-AGB-Endkunde.pdf"
                            >
                              {i18n.t('dataprivacyWarning4')}
                            </a>
                            {i18n.t('dataprivacyWarning5')}
                          </p>
                        </>
                      )}
                      <br />
                      <div style={{ display: 'flex', gap: 16, margin: 'auto 0' }}>
                        <div
                          className="customer-checkout-payment-button"
                          style={{
                            cursor: 'pointer',
                            color,
                            backgroundColor: darkMode ? 'black' : 'white',
                            border: `1px solid ${color}`,
                            flexGrow: 1,
                            flexBasis: 0,
                            width: 0,
                          }}
                          onClick={() => (!isPaymentOngoing ? setShowStripeModal(false) : null)}
                        >
                          {i18n.t('appPayCancel')}
                        </div>
                        <div
                          className="customer-checkout-payment-button"
                          style={{
                            cursor: 'pointer',
                            backgroundColor: color,
                            boxShadow: `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.95) 0px 1px 10px`,
                            flexGrow: 1,
                            flexBasis: 0,
                            width: 0,
                          }}
                          onClick={() => {
                            if (payByCardEnabled === true) {
                              payByCard();
                            }
                          }}
                        >
                          {isPaymentOngoing ? (
                            <>
                              <Loader inline size="tiny" inverted active={isPaymentOngoing} />
                            </>
                          ) : (
                            <>{`${i18n.t('appPayPay')} ${asCurrency(getTotalAmount() / 100)} €`}</>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Checkout;
