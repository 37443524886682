/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { Button, Dropdown, Grid, Input, Table } from 'semantic-ui-react';
import {
  downloadCashPointClosingExport,
  downloadTSEExport,
  getDSFinVK,
  triggerCashPointClosingExport,
  triggerTSEExport,
} from '../../../api/BookingAPI';
import { createTSS, getAllShops } from '../../../api/ShopAPI';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';
import { useStateValue } from '../../../core/context/StateProvider';
import './CreateTSSScreen.css';

const CreateTSSScreen = () => {
  const [{ token }, dispatch] = useStateValue();
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [selectedShop, setSelectedShop] = useState(null);
  const [shops, setShops] = useState([]);
  const [isShopsLoaded, setIsShopsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientSerialNr, setClientSerialNr] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [warningModalOptions, setWarningModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
    amount: null,
  });
  const [errorModalOptions, setErrorModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
  });

  useEffect(() => {
    if (dropDownOptions.length === 0 && isShopsLoaded === false) {
      getShops();
    }
  });

  const getShops = async () => {
    const res = await getAllShops(token);
    if (res && res.success === true) {
      setIsShopsLoaded(true);
      setShops(res.shops);
      setDropDownOptions(
        res.shops.map((shop) => ({
          key: shop._id.toString(),
          text: `${shop.name} ${shop._id.toString()}`,
          value: shop._id.toString(),
        }))
      );
    }
  };

  const onCreateTSS = async () => {
    if (clientSerialNr.length !== 0 && apiKey.length !== 0 && apiSecret.length !== 0) {
      setIsLoading(true);
      try {
        const res = await createTSS(
          token,
          selectedShop._id.toString(),
          clientSerialNr,
          apiKey,
          apiSecret
        );
        if (res && res.success === true) {
          setDropDownOptions([]);
        }
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert(error);
      }
    } else {
      // eslint-disable-next-line no-alert
      alert('CLIENT SERIAL NR MISSING!!!');
    }
    setIsLoading(false);
  };

  const onDisableTSS = async () => {};

  const triggerCashpointclosingExport = async (date) => {
    setWarningModalOptions({
      open: true,
      title: 'WARNING',
      message: `Are you sure you want to trigger the cashpointclosing export for ${date}???`,
      ok: false,
      onOk: null,
      yes: true,
      onYes: async () => {
        const res = await triggerCashPointClosingExport(selectedShop._id.toString(), date, token);
        if (res && res.success) {
          selectedShop.tss.cashPointClosingsExports = res.exports;
          closeWarningModal();
        } else {
          closeWarningModal();
          setErrorModalOptions({
            open: true,
            title: 'ERROR',
            message: res.error,
            ok: true,
            onOk: () => closeErrorModal(),
            yes: false,
            onYes: null,
            no: false,
            onNo: null,
          });
        }
      },
      no: true,
      onNo: () => closeWarningModal(),
    });
  };

  const triggerTseExport = async (date) => {
    setWarningModalOptions({
      open: true,
      title: 'WARNING',
      message: `Are you sure you want to trigger the TSE export for ${date}???`,
      ok: false,
      onOk: null,
      yes: true,
      onYes: async () => {
        const res = await triggerTSEExport(selectedShop._id.toString(), date, token);
        if (res && res.success) {
          selectedShop.tss.tseExports = res.exports;
          closeWarningModal();
        } else {
          closeWarningModal();
          setErrorModalOptions({
            open: true,
            title: 'ERROR',
            message: res.error,
            ok: true,
            onOk: () => closeErrorModal(),
            yes: false,
            onYes: null,
            no: false,
            onNo: null,
          });
        }
      },
      no: true,
      onNo: () => closeWarningModal(),
    });
  };

  const triggerCashPointClosingExportDownload = async (exportId) => {
    setWarningModalOptions({
      open: true,
      title: 'WARNING',
      message: `Are you sure you want to trigger the download?`,
      ok: false,
      onOk: null,
      yes: true,
      onYes: async () => {
        const res = await downloadCashPointClosingExport(
          exportId,
          selectedShop._id.toString(),
          token
        );
        if (res && res.error) {
          closeWarningModal();
          setErrorModalOptions({
            open: true,
            title: 'ERROR',
            message: res.error,
            ok: true,
            onOk: () => closeErrorModal(),
            yes: false,
            onYes: null,
            no: false,
            onNo: null,
          });
        } else {
          closeWarningModal();
        }
      },
      no: true,
      onNo: () => closeWarningModal(),
    });
  };

  const triggerTseExportDownload = async (exportId) => {
    setWarningModalOptions({
      open: true,
      title: 'WARNING',
      message: `Are you sure you want to trigger the download?`,
      ok: false,
      onOk: null,
      yes: true,
      onYes: async () => {
        const res = await downloadTSEExport(
          exportId,
          selectedShop.tss.tssId,
          selectedShop._id.toString(),
          token
        );
        if (res && res.error) {
          closeWarningModal();
          setErrorModalOptions({
            open: true,
            title: 'ERROR',
            message: res.error,
            ok: true,
            onOk: () => closeErrorModal(),
            yes: false,
            onYes: null,
            no: false,
            onNo: null,
          });
        } else {
          closeWarningModal();
        }
      },
      no: true,
      onNo: () => closeWarningModal(),
    });
  };

  const triggerCashpointclosing = async (date) => {
    setWarningModalOptions({
      open: true,
      title: 'WARNING',
      message: `Are you sure you want to trigger the cashpointclosing for ${date}???`,
      ok: false,
      onOk: null,
      yes: true,
      onYes: async () => {
        const res = await getDSFinVK(token, selectedShop._id.toString(), date);
        if (res && res.success) {
          closeWarningModal();
        } else {
          closeWarningModal();
          setErrorModalOptions({
            open: true,
            title: 'ERROR',
            message: res.error,
            ok: true,
            onOk: () => closeErrorModal(),
            yes: false,
            onYes: null,
            no: false,
            onNo: null,
          });
        }
      },
      no: true,
      onNo: () => closeWarningModal(),
    });
  };

  const closeWarningModal = () => {
    setWarningModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
      amount: null,
    });
  };

  const closeErrorModal = () => {
    setErrorModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
    });
  };

  const parseTable = () => (
    <>
      <div className="settings-table-container" style={{ height: 'calc(100vh - 460px)' }}>
        <Table fixed celled selectable padded sortable className="settings-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Cron-Status</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{parseTableBody()}</Table.Body>
        </Table>
      </div>
    </>
  );

  const parseTSEExport = () => {
    if (
      selectedShop &&
      selectedShop !== null &&
      selectedShop.tss !== null &&
      selectedShop.tss.tssId.length > 0
    ) {
      const monthes = [];
      const today = new Date();
      const shopCreatedDate = new Date(selectedShop.created);
      const startOfMonthCreatedDate = moment(shopCreatedDate).startOf('month').startOf('day');
      const differenceInMonths =
        Math.abs(
          moment(shopCreatedDate, 'YYYY-MM-DD')
            .startOf('day')
            .startOf('month')
            .diff(moment(today, 'YYYY-MM-DD').startOf('day'), 'months')
        ) + 1;
      for (let i = 0; i < differenceInMonths; i++) {
        const invoiceMonthDate = moment(startOfMonthCreatedDate)
          .add(i, 'months')
          .startOf('month')
          .startOf('day');
        monthes.push({
          date: invoiceMonthDate.locale('de').format('MMMM'),
          jsDate: invoiceMonthDate.toDate(),
        });
      }
      return (
        <>
          <div className="settings-table-container" style={{ height: 'calc(100vh - 460px)' }}>
            <Table fixed celled selectable padded sortable className="settings-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Month</Table.HeaderCell>
                  <Table.HeaderCell>Trigger Export</Table.HeaderCell>
                  <Table.HeaderCell>Download Export</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {monthes.reverse().map((cpc) => (
                  <>
                    <Table.Row>
                      <Table.Cell collapsing>{`${cpc.date}`}</Table.Cell>
                      <Table.Cell verticalAlign="middle" textAlign="center">
                        <Button
                          icon="sync"
                          size="large"
                          basic
                          color="blue"
                          onClick={() => {
                            triggerTseExport(moment(cpc.jsDate).format());
                          }}
                        />
                      </Table.Cell>
                      <Table.Cell verticalAlign="middle" textAlign="center">
                        <Button
                          icon="download"
                          size="large"
                          basic
                          color="blue"
                          disabled={
                            !(
                              selectedShop.tss.tseExports &&
                              selectedShop.tss.tseExports.findLast(
                                (exp) => moment(exp.forDate).month() === moment(cpc.jsDate).month()
                              )
                            )
                          }
                          onClick={() => {
                            if (
                              selectedShop.tss.tseExports &&
                              selectedShop.tss.tseExports.findLast(
                                (exp) => moment(exp.forDate).month() === moment(cpc.jsDate).month()
                              )
                            ) {
                              triggerTseExportDownload(
                                selectedShop.tss.tseExports.findLast(
                                  (exp) =>
                                    moment(exp.forDate).month() === moment(cpc.jsDate).month()
                                ).exportId
                              );
                            }
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </>
                ))}
              </Table.Body>
            </Table>
          </div>
        </>
      );
    }
    return <></>;
  };

  const parseCPCExport = () => {
    if (
      selectedShop &&
      selectedShop !== null &&
      selectedShop.tss !== null &&
      selectedShop.tss.tssId.length > 0
    ) {
      const monthes = [];
      const today = new Date();
      const shopCreatedDate = new Date(selectedShop.created);
      const startOfMonthCreatedDate = moment(shopCreatedDate).startOf('month').startOf('day');
      const differenceInMonths =
        Math.abs(
          moment(shopCreatedDate, 'YYYY-MM-DD')
            .startOf('day')
            .startOf('month')
            .diff(moment(today, 'YYYY-MM-DD').startOf('day'), 'months')
        ) + 1;
      for (let i = 0; i < differenceInMonths; i++) {
        const invoiceMonthDate = moment(startOfMonthCreatedDate)
          .add(i, 'months')
          .startOf('month')
          .startOf('day');
        monthes.push({
          date: invoiceMonthDate.locale('de').format('MMMM'),
          jsDate: invoiceMonthDate.toDate(),
        });
      }
      return (
        <>
          <div className="settings-table-container" style={{ height: 'calc(100vh - 460px)' }}>
            <Table fixed celled selectable padded sortable className="settings-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Month</Table.HeaderCell>
                  <Table.HeaderCell>Trigger Export</Table.HeaderCell>
                  <Table.HeaderCell>Download Export</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {monthes.reverse().map((cpc) => (
                  <>
                    <Table.Row>
                      <Table.Cell collapsing>{`${cpc.date}`}</Table.Cell>
                      <Table.Cell verticalAlign="middle" textAlign="center">
                        <Button
                          icon="sync"
                          size="large"
                          basic
                          color="blue"
                          onClick={() => {
                            triggerCashpointclosingExport(moment(cpc.jsDate).format());
                          }}
                        />
                      </Table.Cell>
                      <Table.Cell verticalAlign="middle" textAlign="center">
                        <Button
                          icon="download"
                          size="large"
                          basic
                          color="blue"
                          disabled={
                            !(
                              selectedShop.tss.cashPointClosingsExports &&
                              selectedShop.tss.cashPointClosingsExports.findLast(
                                (exp) => moment(exp.forDate).month() === moment(cpc.jsDate).month()
                              )
                            )
                          }
                          onClick={() => {
                            if (
                              selectedShop.tss.cashPointClosingsExports &&
                              selectedShop.tss.cashPointClosingsExports.findLast(
                                (exp) => moment(exp.forDate).month() === moment(cpc.jsDate).month()
                              )
                            ) {
                              triggerCashPointClosingExportDownload(
                                selectedShop.tss.cashPointClosingsExports.findLast(
                                  (exp) =>
                                    moment(exp.forDate).month() === moment(cpc.jsDate).month()
                                ).exportId
                              );
                            }
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </>
                ))}
              </Table.Body>
            </Table>
          </div>
        </>
      );
    }
    return <></>;
  };

  const parseTableBody = () => {
    if (
      selectedShop &&
      selectedShop !== null &&
      selectedShop.tss !== null &&
      selectedShop.tss.tssId.length > 0
    ) {
      const availableDates = [];
      const today = new Date();
      const shopCreatedDate = new Date(selectedShop.created);
      const differenceInDays =
        Math.abs(
          moment(shopCreatedDate, 'YYYY-MM-DD')
            .startOf('day')
            .diff(moment(today, 'YYYY-MM-DD').startOf('day'), 'days')
        ) + 1;
      for (let i = 0; i < differenceInDays; i++) {
        const date = moment(shopCreatedDate)
          .add(i, 'days')
          .set('hour', moment().get('hour'))
          .set('minute', moment().get('minute'));
        const dateFormatted = date.format('DD.MM.YYYY');
        const isTriggered = !!selectedShop.tss.cashPointClosings.find((closing) =>
          moment(closing.timeCreation * 1000).isSame(date, 'day')
        );
        const isDbTriggered = !!selectedShop.tss.cashPointClosings.find((closing) =>
          moment(closing.businessDate).isSame(date, 'day')
        );
        const isScheduled = !!(
          moment(date).isBefore(moment(today).add(1, 'days').set('hour', 5).set('minute', 30)) &&
          moment(date).isAfter(moment(today).set('hour', 0).set('minute', 0))
        );
        availableDates.push({
          date: dateFormatted,
          status:
            isTriggered === true ? 'TRIGGERED' : isScheduled === true ? 'SCHEDULED' : 'NOT RUN',
          jsDate: date.toDate(),
          dbStatus: isDbTriggered === true ? 'TRIGGERED' : 'NOT TRIGGERED',
        });
      }
      return availableDates.reverse().map((closing) => (
        <>
          <Table.Row>
            <Table.Cell collapsing>{closing.date}</Table.Cell>
            <Table.Cell verticalAlign="middle" textAlign="center">
              <p
                style={{
                  fontWeight: 600,
                  color:
                    closing.status === 'TRIGGERED'
                      ? 'green'
                      : closing.status === 'SCHEDULED'
                      ? 'orange'
                      : 'red',
                }}
              >
                {closing.status}
              </p>
            </Table.Cell>
            <Table.Cell verticalAlign="middle" textAlign="center">
              <p
                style={{
                  fontWeight: 600,
                  color: closing.dbStatus === 'TRIGGERED' ? 'green' : 'red',
                }}
              >
                {closing.dbStatus}
              </p>
            </Table.Cell>
            <Table.Cell verticalAlign="middle" textAlign="center">
              <Button
                icon="redo"
                size="large"
                basic
                color="blue"
                onClick={() => triggerCashpointclosing(moment(closing.jsDate).format())}
                disabled={closing.status === 'SCHEDULED'}
              />
            </Table.Cell>
          </Table.Row>
        </>
      ));
    }
    return <></>;
  };

  return (
    <>
      <div className="cockpit-title">
        <p className="cockpit-title">TSS</p>
      </div>
      <div className="tss-container">
        <Dropdown
          selection
          fluid
          placeholder="Select Shop"
          options={dropDownOptions}
          onChange={(_, data) => {
            setSelectedShop(shops.filter((shop) => shop._id.toString() === data.value)[0]);
          }}
        />
        <br />
        {selectedShop ? (
          <>
            <p style={{ fontWeight: 700, color: 'violet' }}>
              {shops.filter(
                (shop) =>
                  shop._id.toString() === selectedShop._id.toString() &&
                  shop.tss !== null &&
                  shop.tss.tssId.length > 0
              ).length > 0
                ? 'TSS EXISTS'
                : 'NO TSS YET'}
            </p>
          </>
        ) : (
          <></>
        )}
        <Input
          fluid
          placeholder="API KEY"
          value={
            apiKey || (selectedShop && selectedShop.tss !== null ? selectedShop.tss.apiKey : '')
          }
          disabled={
            !!(
              selectedShop &&
              shops.filter(
                (shop) =>
                  shop._id.toString() === selectedShop._id.toString() &&
                  shop.tss !== null &&
                  shop.tss.tssId.length > 0
              ).length > 0
            )
          }
          onChange={(e) => {
            setApiKey(e.target.value);
          }}
        />
        <br />
        <Input
          fluid
          placeholder="API SECRET"
          value={
            apiSecret ||
            (selectedShop && selectedShop.tss !== null ? selectedShop.tss.apiSecret : '')
          }
          disabled={
            !!(
              selectedShop &&
              shops.filter(
                (shop) =>
                  shop._id.toString() === selectedShop._id.toString() &&
                  shop.tss !== null &&
                  shop.tss.tssId.length > 0
              ).length > 0
            )
          }
          onChange={(e) => {
            setApiSecret(e.target.value);
          }}
        />
        <br />
        <Input
          fluid
          placeholder="Client Serial Nr."
          value={
            clientSerialNr ||
            (selectedShop && selectedShop.tss !== null
              ? selectedShop.tss.clients[0].serialNumber
              : '')
          }
          disabled={
            !!(
              selectedShop &&
              shops.filter(
                (shop) =>
                  shop._id.toString() === selectedShop._id.toString() &&
                  shop.tss !== null &&
                  shop.tss.tssId.length > 0
              ).length > 0
            )
          }
          onChange={(e) => {
            setClientSerialNr(e.target.value);
          }}
        />
        <br />
        <br />
        <div style={{ display: 'flex' }}>
          <Button
            loading={isLoading}
            fluid
            content="Create TSS"
            color="teal"
            onClick={onCreateTSS}
            disabled={
              isLoading ||
              (selectedShop && selectedShop.tss !== null && selectedShop.tss.tssId.length > 0) ||
              selectedShop === null
            }
          />
          <Button
            loading={isLoading}
            fluid
            content="Disable TSS"
            color="teal"
            onClick={onDisableTSS}
            disabled={
              isLoading || (selectedShop && selectedShop.tss === null) || selectedShop === null
            }
          />
        </div>
      </div>
      {selectedShop && selectedShop.tss !== null && (
        <Grid stackable doubling columns={2}>
          <Grid.Column>
            <div className="tss-container">
              <p style={{ fontWeight: 700 }}>Daily Cash Point Closings</p>
              {parseTable()}
              <br />
              <p style={{ fontWeight: 700 }}>Monthly Cash Point Closings Export</p>
              {parseCPCExport()}
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="tss-container">
              <p style={{ fontWeight: 700 }}>Monthly TSE Export</p>
              {parseTSEExport()}
            </div>
          </Grid.Column>
        </Grid>
      )}
      <ErrorWarningModal
        open={warningModalOptions.open}
        title={warningModalOptions.title}
        message={warningModalOptions.message}
        ok={warningModalOptions.ok}
        onOk={warningModalOptions.onOk}
        yes={warningModalOptions.yes}
        onYes={warningModalOptions.onYes}
        no={warningModalOptions.no}
        onNo={warningModalOptions.onNo}
        amount={warningModalOptions.amount}
      />
      <ErrorWarningModal
        open={errorModalOptions.open}
        title={errorModalOptions.title}
        message={errorModalOptions.message}
        ok={errorModalOptions.ok}
        onOk={errorModalOptions.onOk}
        yes={errorModalOptions.yes}
        onYes={errorModalOptions.onYes}
        no={errorModalOptions.no}
        onNo={errorModalOptions.onNo}
      />
    </>
  );
};

export default CreateTSSScreen;
