export default class NavbarItem {
  constructor(icon, linkTo, title, component, onClick, style) {
    this.icon = icon;
    this.linkTo = linkTo;
    this.title = title;
    this.component = component;
    this.onClick = onClick;
    this.style = style;
  }
}
