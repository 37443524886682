/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React from 'react';
import i18n from 'i18next';
import { Loader, Popup } from 'semantic-ui-react';
import { Doughnut } from 'react-chartjs-2';
import { abbreviate, asCurrency, round } from '../../../utils/NumberFormatter';
import './DoughnutChartTile.css';

const DoughnutChartTile = (props) => {
  const { data, loading, details } = props;
  let chartData;
  if (loading === false && data != null) {
    chartData = (canvas) => {
      const ctx = canvas.getContext('2d');
      const gradient = ctx.createLinearGradient(400, 0, 200, 500);
      gradient.addColorStop(0, '#97caef');
      gradient.addColorStop(1, '#fff');
      const gradient2 = ctx.createLinearGradient(250, 0, 0, 500);
      gradient2.addColorStop(0, '#97caef');
      gradient2.addColorStop(1, '#fff');
      return {
        labels: data.labels,
        datasets: [
          {
            data: data.data.map((iData) => abbreviate(iData)),
            backgroundColor: [gradient, gradient2],
            borderColor: ['#fff', '#fff'],
            borderWidth: [data.data[0] > data.data[1] ? 3 : 7, data.data[0] > data.data[1] ? 7 : 3],
          },
        ],
      };
    };
  }
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: { display: false },
    tooltips: {
      enabled: false,
    },
    cutoutPercentage: 93,
  };
  return (
    <>
      <div className="doughnut-chart-container">
        <div className="doughnut-content-container">
          <div className="doughnut-chart-header">
            <p className="doughnut-chart-title">{details.title}</p>
            <div
              className="doughnut-chart-image-container"
              style={{
                backgroundColor: `${details.imageBackground}1F`,
              }}
            >
              <img className="doughnut-chart-image" src={details.image} alt="" />
            </div>
          </div>
          {loading ? (
            <Loader size="massive" active={loading} inverted className="bookings-create-loader" />
          ) : (
            <>
              {data ? (
                <>
                  <div className="doughnut-legend">
                    <div className="doughnut-legend-left">
                      <p className="doughnut-legend-title">{data.leftLegendTitle}</p>
                      <Popup
                        position="bottom center"
                        hideOnScroll
                        content={`${data.leftLegendValue}€`}
                        trigger={
                          <p className="doughnut-legend-value-left">
                            <span className="doughnut-currency-left">€ </span>
                            {data.leftLegendValue < 1000 && data.leftLegendValue > 0
                              ? asCurrency(data.leftLegendValue)
                              : abbreviate(data.leftLegendValue)}
                          </p>
                        }
                      />
                    </div>
                    <div className="doughnut-legend-right">
                      <p className="doughnut-legend-title">{data.rightLegendTitle}</p>
                      <Popup
                        position="bottom left"
                        hideOnScroll
                        content={`${asCurrency(data.rightLegendValue)}€`}
                        trigger={
                          <p className="doughnut-legend-value-right">
                            <span className="doughnut-currency-right">€ </span>
                            {data.rightLegendValue < 1000 && data.rightLegendValue > 0
                              ? asCurrency(data.rightLegendValue)
                              : abbreviate(data.rightLegendValue)}
                          </p>
                        }
                      />
                    </div>
                  </div>
                  <div className="doughnut-chart-bar">
                    <Doughnut data={chartData} height={300} options={options} />
                    <div className="chartInner">
                      <div
                        className="chartPercentage"
                        style={{ color: data.data[0] > data.data[1] ? '#97caef' : '#97caef' }}
                      >
                        {data.data[0] === 0 && data.data[1] === 0
                          ? '0%'
                          : data.data[0] > data.data[1]
                          ? `${round((data.data[0] / (data.data[0] + data.data[1])) * 100, 0)}%`
                          : `${round((data.data[1] / (data.data[0] + data.data[1])) * 100, 0)}%`}
                      </div>
                      <div
                        className="chartTitle"
                        style={{ color: data.data[0] > data.data[1] ? '#97caef' : '#97caef' }}
                      >
                        {data.data[0] === 0 && data.data[1] === 0
                          ? i18n.t('dashBoardTopTenItemsNoData')
                          : data.data[0] > data.data[1]
                          ? i18n.t('dashBoardPaymentMethodsSubTitleRight')
                          : i18n.t('dashBoardPaymentMethodsSubTitleLeft')}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p style={{ fontWeight: 600, fontSize: 15, marginTop: 35 }}>
                    {`${i18n.t('dataError')}.`}
                  </p>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DoughnutChartTile;
