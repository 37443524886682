import moment from 'moment';
import { get, post, put, del } from './Fetch';
import { useStateValue } from '../core/context/StateProvider';

// SHOP

// GET

export const getShop = async () => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops`;
  const [{ token }] = useStateValue();
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getRooms = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/rooms`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getAllShops = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/all`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const createQRPDF = async (table, code, token) => {
  const downloadUrl = `${process.env.REACT_APP_SERVER_URL}shops/qr`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: token,
  };
  const title = `moaby-qrcode-table-${table}`;
  const response = await (
    await fetch(downloadUrl, { method: 'POST', headers, body: JSON.stringify({ code, table }) })
  ).blob();
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${title}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

// POST

export const createShop = async (token, data) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops`;
  const headers = { Authorization: token };
  const body = data;
  return post(url, headers, body);
};

export const createTSS = async (token, shopId, clientSerialNr, apiKey, apiSecret) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/tss`;
  const headers = { Authorization: token };
  const body = { shopId, clientSerialNr, apiKey, apiSecret };
  return post(url, headers, body);
};

export const createRooms = async (token, rooms) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/rooms`;
  const headers = { Authorization: token };
  const body = { rooms };
  return post(url, headers, body);
};

export const triggerMonthlyInvoiceEmail = async (token, shopId, date) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/monthly/email/${shopId}/${date}`;
  const headers = { Authorization: token };
  const body = {};
  return post(url, headers, body);
};

export const triggerWeeklyInvoiceEmail = async (token, shopId, dateFrom, dateTo) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/weekly/email/${shopId}/${dateFrom}/${dateTo}`;
  const headers = { Authorization: token };
  const body = {};
  return post(url, headers, body);
};

export const triggerMonthlyInvoiceDownload = async (token, shopId, date) => {
  const downloadUrl = `${process.env.REACT_APP_SERVER_URL}shops/monthly/pdf/${shopId}/${date}`;
  const headers = { 'Content-Type': 'application/pdf', Authorization: token };
  const title = 'moaby-Rechnung';
  const response = await (await fetch(downloadUrl, { method: 'POST', headers })).blob();
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${title}-${moment(date).format('MM-YYYY')}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const triggerWeeklyInvoiceDownload = async (token, shopId, dateFrom, dateTo) => {
  const downloadUrl = `${process.env.REACT_APP_SERVER_URL}shops/weekly/pdf/${shopId}/${dateFrom}/${dateTo}`;
  const headers = { 'Content-Type': 'application/pdf', Authorization: token };
  const title = 'moaby-Rechnung';
  const firstDayLastWeek = moment(dateFrom).toDate();
  const lastDayLastWeek = moment(dateTo).toDate();
  const response = await (await fetch(downloadUrl, { method: 'POST', headers })).blob();
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    `${title}-${moment(firstDayLastWeek).format('DDMM')}-${moment(lastDayLastWeek).format(
      'DDMM-YYYY'
    )}.pdf`
  );
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

// PUT

export const updateShop = async (token, update) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops`;
  const headers = { Authorization: token };
  const body = update;
  return put(url, headers, body);
};

export const updateSepa = async (token, hasSepa, shopId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/sepa/${shopId}/${hasSepa}`;
  const headers = { Authorization: token };
  const body = {};
  return put(url, headers, body);
};

export const updateIsClosed = async (token, isClosed, shopId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/isClosed/${shopId}/${isClosed}`;
  const headers = { Authorization: token };
  const body = {};
  return put(url, headers, body);
};

export const updateCardPaymentSetting = async (token, isCardPaymentFromOtherAllowed, shopId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/cardpayment/${shopId}/${isCardPaymentFromOtherAllowed}`;
  const headers = { Authorization: token };
  const body = {};
  return put(url, headers, body);
};

export const updateShopOwner = async (
  firstName = null,
  lastName = null,
  email = null,
  phoneNumber = null,
  idNumber = null,
  idPhotos = null
) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/shopowner`;
  const [{ token }] = useStateValue();
  const headers = { Authorization: token };
  const body = {
    firstName,
    lastName,
    email,
    phoneNumber,
    idNumber,
    idPhotos,
  };
  return put(url, headers, body);
};

export const updateHardware = async (token, update, shopId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/hardware`;
  const headers = { Authorization: token };
  const body = {
    rentedHardware: update,
    shopId,
  };
  return put(url, headers, body);
};

export const updatePricing = async (token, update, shopId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/pricing`;
  const headers = { Authorization: token };
  const body = {
    pricing: update,
    shopId,
  };
  return put(url, headers, body);
};

export const updateLogo = async (token, logo, shopId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/logo`;
  const headers = { Authorization: token };
  const body = {
    logo,
    shopId,
  };
  return put(url, headers, body);
};

// TABLES

// GET

export const getTables = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/tables`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getTablesOfUser = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/tables/user`;
  const headers = { Authorization: token };
  return get(url, headers);
};

// POST

export const createTable = async (token, table) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/tables`;
  const headers = { Authorization: token };
  const body = { table };
  return post(url, headers, body);
};

// PUT

export const updateTable = async (token, tableId, table) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/tables/${tableId}`;
  const headers = { Authorization: token };
  const body = table;
  return put(url, headers, body);
};

// DEL

export const deleteTable = async (token, tableId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/tables/${tableId}`;
  const headers = { Authorization: token };
  return del(url, headers);
};

export const deleteTables = async (token, tableIds) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/tables/multiple/delete`;
  const body = { tableIds };
  const headers = { Authorization: token };
  return del(url, headers, body);
};

export const deleteRoom = async (token, roomName) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/rooms/${roomName}`;
  const headers = { Authorization: token };
  return del(url, headers);
};

// PRINTERS

// POST

export const createPrinter = async (token, data) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/printer`;
  const headers = { Authorization: token };
  const body = data;
  return post(url, headers, body);
};

// PUT

export const updatePrinter = async (token, printerId, data) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/printer/${printerId}`;
  const headers = { Authorization: token };
  const body = data;
  return put(url, headers, body);
};

// DEL

export const deletePrinter = async (token, printerId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/printer/${printerId}`;
  const headers = { Authorization: token };
  return del(url, headers);
};

export const getExcelTemplate = async (token) => {
  const downloadUrl = `${process.env.REACT_APP_SERVER_URL}shops/tables/download/excel`;
  const headers = { Authorization: token };
  const title = 'Tischtemplate';
  const response = await (await fetch(downloadUrl, { method: 'GET', headers })).blob();
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${title}.xlsx`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const uploadExcel = async (token, excel) => {
  const url = `${process.env.REACT_APP_SERVER_URL}shops/tables/upload/excel`;
  const headers = { Authorization: token };
  const body = { excel };
  return post(url, headers, body);
};
