/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React from 'react';
import i18n from 'i18next';
import { Loader, Popup } from 'semantic-ui-react';
import { abbreviate, round, asCurrency } from '../../../utils/NumberFormatter';
import './KPITile.css';

// KPITile component.
const KPITile = (props) => {
  const { data, loading, isCurrency, details } = props;
  let growthColor = 'growth-neutral';
  let abbreviatedNumber;
  let roundedNumber;
  let currencyValue;
  if (loading === false && data != null) {
    growthColor =
      data.growth > 0 ? 'growth-positive' : data.growth < 0 ? 'growth-negative' : 'growth-neutral';
    abbreviatedNumber =
      data.value < 1000 && isCurrency ? asCurrency(data.value) : abbreviate(data.value);
    roundedNumber = round(data.growth, 2);
    currencyValue = asCurrency(data.value);
  }

  return (
    <>
      <div className="kpi-tile-container">
        <div className="kpi-tile-content-container">
          <div className="kpi-tile-header">
            <p className="kpi-tile-title">{details.title}</p>
            <div
              className="kpi-tile-image-container"
              style={{
                backgroundColor: `${details.imageBackground}1F`,
              }}
            >
              <img className="kpi-tile-image" src={details.image} alt="" />
            </div>
          </div>
          {loading ? (
            <Loader
              size="medium"
              active={loading}
              inverted
              className="bookings-create-loader"
              style={{ marginTop: 10 }}
            />
          ) : (
            <>
              {data ? (
                <>
                  <Popup
                    position="bottom center"
                    hideOnScroll
                    content={isCurrency ? `${currencyValue}€` : data.value}
                    trigger={
                      <p className="kpi-tile-value">
                        {abbreviatedNumber}
                        {isCurrency === true ? '€' : <></>}
                      </p>
                    }
                  />
                  <p className="kpi-tile-footer">
                    <span className={growthColor}>{`${roundedNumber}%`}</span>
                    {` ${i18n.t('dashBoardRevenueTodaySubTitle')}${details.descriptor}`}
                  </p>
                </>
              ) : (
                <>
                  <p style={{ fontWeight: 600, fontSize: 15, marginTop: 35 }}>
                    {`${i18n.t('dataError')}.`}
                  </p>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default KPITile;
