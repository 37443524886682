/* eslint-disable consistent-return */
// useCanvasContextFromImageURL.js
import { useState, useEffect } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const usePrintableLogo = (url) => {
  const [context, setContext] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!url) {
      setError('No URL provided');
      return;
    }

    setError(null);
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.src = url;

    image.onload = () => {
      const canvas = document.createElement('canvas');
      const maxWidth = 288;
      let scaledWidth;
      let scaledHeight;

      if (image.width > maxWidth) {
        scaledWidth = maxWidth;
        scaledHeight = Math.round((image.height / image.width) * scaledWidth);
      } else {
        scaledWidth = image.width;
        scaledHeight = image.height;
      }

      canvas.width = scaledWidth;
      canvas.height = scaledHeight;
      const ctx = canvas.getContext('2d', { willReadFrequently: true });
      ctx.drawImage(image, 0, 0, scaledWidth, scaledHeight);

      setContext(ctx);
    };

    image.onerror = (err) => {
      setError('Failed to load image');
      console.error('Image loading error:', err);
    };
  }, [url]);

  return { logoCanvasContext: context, logoError: error };
};
