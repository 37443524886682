/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Visibility, Image, Loader } from 'semantic-ui-react';
import QRCodeStyling from 'qr-code-styling';
import logo from '../../../screens/shop/Settings/logo.png';

const LazyImage = (props) => {
  const { size, style, src, onClick, data, name } = props;
  const [show, setShow] = useState(false);
  const [qrCode, setQRCode] = useState(null);
  const [qrRaw, setQRRaw] = useState(null);

  useEffect(() => {
    if (qrCode === null && data !== null && show === true) {
      getQRCode();
    }
  });

  const showImage = () => {
    setShow(true);
  };

  const qrCodeOptions = {
    width: 800,
    height: 800,
    data: '',
    margin: 0,
    qrOptions: { typeNumber: '0', mode: 'Byte', errorCorrectionLevel: 'Q' },
    imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
    dotsOptions: { type: 'extra-rounded', color: '#7268cd', gradient: null },
    backgroundOptions: { color: '#ffffff' },
    image: logo,
    dotsOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: '#6a1a4c',
        color2: '#6a1a4c',
        rotation: '0',
      },
    },
    cornersSquareOptions: { type: 'extra-rounded', color: '#7268cd' },
    cornersSquareOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: '#000000',
        color2: '#000000',
        rotation: '0',
      },
    },
    cornersDotOptions: { type: '', color: '#7268cd' },
    cornersDotOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: '#000000',
        color2: '#000000',
        rotation: '0',
      },
    },
    backgroundOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: '#ffffff',
        color2: '#ffffff',
        rotation: '0',
      },
    },
  };

  const getQRCode = async () => {
    qrCodeOptions.data = data;
    const qr = new QRCodeStyling(qrCodeOptions);
    const x = await qr.getRawData();
    setQRCode(window.URL.createObjectURL(x));
    setQRRaw(qr);
  };

  return (
    <>
      {show === false ? (
        <>
          <Visibility fireOnMount onOnScreen={showImage}>
            <Loader active inline="centered" size={size} />
          </Visibility>
        </>
      ) : (
        <>
          <Image
            style={style}
            src={data ? qrCode : src}
            onClick={() => {
              qrRaw.download({
                name,
                extension: 'png',
              });
            }}
          />
        </>
      )}
    </>
  );
};

export default LazyImage;
