/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Modal, Button, Table } from 'semantic-ui-react';
import './BookingsScreen.css';
import { cloneDeep, orderBy } from 'lodash';
import moment from 'moment';
import { asCurrency } from '../../../utils/NumberFormatter';
import { Printer } from '../../../utils/Printer';
import { useStateValue } from '../../../core/context/StateProvider';
import { showToast } from '../../../components/general/Toast/Toast';
import i18n from '../../../i18n/config';

const FailedPrintsModal = (props) => {
  const [{ shop }] = useStateValue();
  const { onClose, open, terminals } = props;
  const [printjobs, setPrintjobs] = useState([]);
  const [isPrinting, setIsPrinting] = useState(false);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const closeModal = () => {
    setIsPrinting(false);
    setPrintjobs([]);
    onClose();
  };

  useEffect(() => {
    const failedPrints = localStorage.getItem('failedPrints');
    if (open && failedPrints && JSON.parse(failedPrints).length !== printjobs.length) {
      const sortedPrintJobs = orderBy(JSON.parse(failedPrints), 'created', 'desc');
      setPrintjobs(sortedPrintJobs);
    }
  });

  const print = async (booking, index) => {
    showToast({
      loading: true,
      message: i18n.t('orderIsPrinting'),
      id: booking.bookingId,
    });
    setIsPrinting(true);
    if (shop.printPerCategory === true) {
      const printerItemsMap = {};
      for (let i = 0; i < booking.bookedItems.length; i++) {
        const item = booking.bookedItems[i];
        const { categoryName } = item;
        if (
          shop.rooms &&
          shop.rooms.length > 0 &&
          booking.table != null &&
          booking.table.tableNumber != null
        ) {
          const room = shop.rooms.find((room) =>
            room.tables.find((table) => table.number === booking.table.tableNumber)
          );
          const shopPrinter = room
            ? shop.printers.find(
                (printer) =>
                  (printer.rooms.includes(room.name) || printer.rooms.includes('all')) &&
                  (printer.categories.includes(categoryName) ||
                    (printer.categories.length === 1 && printer.categories[0] === 'all'))
              )
            : null;

          if (room && shopPrinter) {
            const keyName = shopPrinter.description;
            // eslint-disable-next-line no-prototype-builtins
            if (printerItemsMap.hasOwnProperty(keyName)) {
              printerItemsMap[keyName] = {
                items: [...printerItemsMap[keyName].items, item],
                room: room.name,
              };
            } else {
              printerItemsMap[keyName] = { items: [item], room: room.name };
            }
          } else {
            setErrorWarningModalOptions({
              open: true,
              message: `${i18n.t('failedPrintJobsError3part1')}${booking.bookingId}${i18n.t(
                'failedPrintJobsError3part2'
              )}${categoryName}${i18n.t('failedPrintJobsError3part3')}`,
            });
            setIsPrinting(false);
          }
        } else {
          const shopPrinter = shop.printers.find(
            (printer) =>
              printer.categories.includes(categoryName) ||
              (printer.categories.length === 1 && printer.categories[0] === 'all')
          );
          if (shopPrinter) {
            const keyName = shopPrinter.description;
            // eslint-disable-next-line no-prototype-builtins
            if (printerItemsMap.hasOwnProperty(keyName)) {
              printerItemsMap[keyName] = {
                items: [...printerItemsMap[keyName].items, item],
                room: null,
              };
            } else {
              printerItemsMap[keyName] = { items: [item], room: null };
            }
          } else {
            setErrorWarningModalOptions({
              open: true,
              message: `${i18n.t('failedPrintJobsError3part1')}${booking.bookingId}${i18n.t(
                'failedPrintJobsError3part2'
              )}${categoryName}${i18n.t('failedPrintJobsError3part3')}`,
            });
            setIsPrinting(false);
          }
        }
      }

      for (let i = 0; i < Object.keys(printerItemsMap).length; i++) {
        const printerDescription = Object.keys(printerItemsMap)[i];
        const { items, room } = printerItemsMap[printerDescription];
        const printer = shop.printers.find((printer) => printer.description === printerDescription);
        if (printer) {
          const printerDevice = new Printer(printer);
          printerDevice.epos.onerror = () => {
            setErrorWarningModalOptions({
              message: i18n.t('printingErrorMessage'),
              open: true,
            });
            setIsPrinting(false);
          };
          printerDevice.epos.onreceive = (res) => {
            if (res.success) {
              printjobs.splice(index, 1);
              setPrintjobs([...printjobs]);
              localStorage.setItem('failedPrints', JSON.stringify(printjobs));
              window.dispatchEvent(new Event('storage'));
            }
            setIsPrinting(false);
          };
          printerDevice.printCategory(items, booking, room, !!shop.autoPrint);
          // eslint-disable-next-line no-await-in-loop
          await new Promise((resolve) => setTimeout(resolve, 500));
        }
      }
    } else if (
      shop.rooms &&
      shop.rooms.length > 0 &&
      booking.table != null &&
      booking.table.tableNumber != null
    ) {
      const room = shop.rooms.find((room) =>
        room.tables.find((table) => table.number === booking.table.tableNumber)
      );
      const shopPrinter = room
        ? shop.printers.find(
            (printer) => printer.rooms.includes(room.name) || printer.rooms.includes('all')
          )
        : null;
      if (room && shopPrinter) {
        const printerDevice = new Printer(shopPrinter);
        printerDevice.epos.onerror = () => {
          setErrorWarningModalOptions({
            message: i18n.t('printingErrorMessage'),
            open: true,
          });
          setIsPrinting(false);
        };
        printerDevice.epos.onreceive = (res) => {
          if (res.success) {
            printjobs.splice(index, 1);
            setPrintjobs([...printjobs]);
            localStorage.setItem('failedPrints', JSON.stringify(printjobs));
            window.dispatchEvent(new Event('storage'));
          }
          setIsPrinting(false);
        };
        const newBooking = cloneDeep(booking);
        printerDevice.printInternal(newBooking, room.name, !!shop.autoPrint);
        await new Promise((resolve) => setTimeout(resolve, 500));
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('failedPrintJobsError2'),
        });
        setIsPrinting(false);
      }
    } else if (shop.printers[0]) {
      printInternal(booking, null, index);
    } else {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('failedPrintJobsError1'),
      });
      setIsPrinting(false);
    }
  };

  const printInternal = async (booking, roomName, index) => {
    const printerDevice = new Printer(shop.printers[0]);
    printerDevice.epos.onerror = () => {
      setErrorWarningModalOptions({
        message: i18n.t('printingErrorMessage'),
        open: true,
      });
      setIsPrinting(false);
    };
    printerDevice.epos.onreceive = (res) => {
      if (res.success) {
        printjobs.splice(index, 1);
        setPrintjobs([...printjobs]);
        localStorage.setItem('failedPrints', JSON.stringify(printjobs));
        window.dispatchEvent(new Event('storage'));
      }
      setIsPrinting(false);
    };
    printerDevice.printInternal(booking, roomName, !!shop.autoPrint);
    await new Promise((resolve) => setTimeout(resolve, 500));
  };

  return (
    <>
      <Modal key={124323} open={open} dimmer="blurring" closeOnEscape closeOnDimmerClick>
        <Modal.Header style={{ borderBottom: 'none', backgroundColor: '#f5f5f9' }}>
          {i18n.t('errorPrintJobsTitle')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal()}
            floated="right"
            circular
            className="settings-close"
          />
        </Modal.Header>
        <Modal.Content
          style={{ backgroundColor: '#f5f5f9', borderRadius: 0, paddingTop: 0, paddingBottom: 18 }}
        >
          <div>
            {printjobs.length > 0 ? (
              <div className="bookings-table-container-discount" style={{ maxHeight: 400 }}>
                <Table
                  fixed
                  padded
                  style={{ border: 'none', borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
                >
                  <Table.Header style={{ backgroundColor: '#f5f59' }}>
                    <Table.Row>
                      <Table.HeaderCell
                        style={{ backgroundColor: '#f5f5f9', border: 'none' }}
                        width="2"
                      >
                        {i18n.t('dailySummaryDate')}
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        verticalAlign="middle"
                        textAlign="center"
                        style={{ backgroundColor: '#f5f5f9', border: 'none' }}
                        width="2"
                      >
                        ID
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{ backgroundColor: '#f5f5f9', border: 'none' }}
                        width="4"
                      >
                        {i18n.t('bookingsItems')}
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: '#f5f5f9', border: 'none' }}>
                        {i18n.t('job')}
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{ backgroundColor: '#f5f5f9', border: 'none' }}
                        verticalAlign="middle"
                        textAlign="center"
                      >
                        {i18n.t('bookingsTable')}
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{ backgroundColor: '#f5f5f9', border: 'none' }}
                        verticalAlign="middle"
                        textAlign="center"
                      >
                        {i18n.t('sum')}
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{ backgroundColor: '#f5f5f9', border: 'none' }}
                        verticalAlign="middle"
                        textAlign="center"
                        width="2"
                      />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {printjobs.map((printjob, index) => (
                      <>
                        <Table.Row style={{ fontWeight: 600, borderTop: 'none' }}>
                          <Table.Cell>
                            {moment(printjob.created).format(
                              `HH:mm[${i18n.t('uhrGerman')}], DD.MM.YYYY`
                            )}
                          </Table.Cell>
                          <Table.Cell verticalAlign="middle" textAlign="center">
                            {printjob.bookingId}
                          </Table.Cell>
                          <Table.Cell>
                            {printjob.bookedItems.map((item) => (
                              <p>{`- ${item.itemTitle}`}</p>
                            ))}
                          </Table.Cell>
                          <Table.Cell verticalAlign="middle" textAlign="center">
                            {printjob.printjobId}
                          </Table.Cell>
                          <Table.Cell verticalAlign="middle" textAlign="center">
                            {printjob.table && printjob.table.tableNumber
                              ? printjob.table.tableNumber
                              : '-'}
                          </Table.Cell>
                          <Table.Cell verticalAlign="middle" textAlign="center">
                            {`${asCurrency(printjob.amount / 100)}€`}
                          </Table.Cell>
                          <Table.Cell verticalAlign="middle" textAlign="center" width="1">
                            <Button
                              icon="print"
                              color="teal"
                              onClick={() => print(printjob, index)}
                              disabled={isPrinting === true}
                            />
                          </Table.Cell>
                        </Table.Row>
                      </>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            ) : (
              <>
                <p style={{ fontWeight: 600 }}>{i18n.t('noFailedPrintJobs')}</p>
              </>
            )}
          </div>
        </Modal.Content>
      </Modal>
      <Modal dimmer="blurring" size="tiny" open={errorWarningModalOptions.open}>
        <Modal.Header style={{ borderBottom: 'none' }}>
          <p>{i18n.t('newPrinterErrorTitle')}</p>
        </Modal.Header>
        <Modal.Content>
          <p style={{ fontWeight: 600 }}>{errorWarningModalOptions.message}</p>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
          <Button content="OK" color="teal" onClick={onErrorWarningModalOk} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default FailedPrintsModal;
