/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { Modal, Button, TextArea, Form, Radio, Dropdown } from 'semantic-ui-react';
import { useStateValue } from '../../../core/context/StateProvider';
import './BookingsScreen.css';
import StyledInput from '../../../components/general/Input/Input';
import { createDiverseItem } from '../../../api/ItemAPI';
import { showToast } from '../../../components/general/Toast/Toast';

const DiverseProductModal = (props) => {
  const { onClose, open, categories } = props;
  const [{ token, shop }] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [newItem, setNewItem] = useState({
    title: null,
    price: null,
    note: null,
    taxRate: null,
    category: null,
  });
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });

  const getCategoryTranslation = (category) => {
    const lang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
    if (
      category.name !== i18n.t('appAllCategories') &&
      lang &&
      category.translations &&
      category.translations.length > 0
    ) {
      const name = category.translations.find((translation) => translation.lang === lang);
      if (name) {
        return name.val;
      }
      const englishName = category.translations.find((translation) => translation.lang === 'en');
      if (englishName) {
        return englishName.val;
      }
      return category.name;
    }
    return category.name;
  };

  const getOriginalCategoryFromName = (categoryName) => {
    if (categories !== null) {
      const category = categories.find((category) =>
        category.translations.find((translation) => translation.val === categoryName)
      );
      if (category) {
        return category.name;
      }
      return categoryName;
    }
    return categoryName;
  };

  useEffect(() => {
    if (
      open &&
      newItem.title != null &&
      newItem.price != null &&
      newItem.taxRate != null &&
      newItem.category != null &&
      isSaveDisabled === true
    ) {
      setIsSaveDisabled(false);
    }
    if (
      open &&
      isSaveDisabled === false &&
      (newItem.title == null ||
        newItem.price == null ||
        newItem.taxRate == null ||
        newItem.category == null)
    ) {
      setIsSaveDisabled(true);
    }
  });

  const closeModal = (item, note) => {
    setIsLoading(false);
    setIsSaveDisabled(true);
    setNewItem({ title: null, price: null, note: null, taxRate: null, category: null });
    onClose(item, note);
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const createNewDiverseItem = async () => {
    const data = {
      title: newItem.title,
      price: newItem.price,
      taxRate: newItem.taxRate,
      category: newItem.category,
    };
    try {
      const res = await createDiverseItem(token, data);
      if (res && res.success) {
        closeModal(res.item, newItem.note);
      } else {
        showToast({
          error: true,
          title: i18n.t('newPrinterErrorTitle'),
          message: i18n.t('itemCreateError'),
        });
      }
    } catch (error) {
      showToast({
        error: true,
        title: i18n.t('newPrinterErrorTitle'),
        message: i18n.t('itemCreateError'),
      });
    }
  };

  const dropdownOptions = (categories) => {
    if (categories) {
      const options = [];
      const translatedCategories = categories.map((category) => getCategoryTranslation(category));
      translatedCategories.sort();
      translatedCategories.forEach((category) => {
        const object = {};
        object.key = category.key;
        object.value = category;
        object.text = category;
        options.push(object);
      });
      return options;
    }
    return [];
  };

  const onDropdownChange = (value) => {
    const category = categories.find(
      (category) => category.name === getOriginalCategoryFromName(value)
    );
    setNewItem({ ...newItem, category });
  };

  return (
    <>
      <Modal
        open={open}
        dimmer="blurring"
        size="tiny"
        closeOnEscape
        closeOnDimmerClick
        style={{ backgroundColor: '#f5f5f9' }}
      >
        <Modal.Header style={{ borderBottom: 'none', backgroundColor: '#f5f5f9' }}>
          {i18n.t('diverseItem')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal()}
            floated="right"
            circular
            className="settings-close"
          />
        </Modal.Header>
        <Modal.Content
          scrolling
          style={{ backgroundColor: '#f5f5f9', paddingTop: 0, paddingBottom: 0 }}
        >
          <div className="items-modal-content" style={{ marginLeft: 0 }}>
            <div className="items-modal-header">
              <StyledInput
                id="title"
                placeholder={i18n.t('itemsTitle')}
                onChangeTitle={(value) => {
                  const trimmedVal = value ? value.trim() : null;
                  setNewItem({ ...newItem, title: trimmedVal || null });
                }}
              />
              <StyledInput
                id="price"
                placeholder={i18n.t('itemsPrice')}
                type="currency"
                onChangePrice={(value) => {
                  if (value) {
                    setNewItem({ ...newItem, price: parseFloat(value.replace(',', '.')) * 100 });
                  } else {
                    setNewItem({ ...newItem, price: null });
                  }
                }}
              />
            </div>
            <p style={{ fontWeight: 'bold' }}>{i18n.t('itemsCategory')}</p>
            <Dropdown
              selection
              placeholder={i18n.t('itemsCategories')}
              fluid
              defaultValue={newItem.category}
              onChange={(_, data) => onDropdownChange(data.value)}
              options={dropdownOptions(categories)}
              className="items-modal-dropdown"
              id="items-dropdown"
            />
            <p style={{ fontWeight: 'bold' }}>{i18n.t('appNotes')}</p>
            <TextArea
              placeholder={i18n.t('appNotes')}
              className="items-modal-textarea"
              style={{ resize: 'none', width: '100%' }}
              onChange={(e) => {
                setNewItem({ ...newItem, note: e.target.value });
              }}
            />
            <p style={{ fontWeight: 'bold' }}>{`${i18n.t('taxRate')}`}</p>
            <Form>
              <Form.Field>
                <Radio
                  label="19%"
                  name="radioGroup"
                  value={0.19}
                  checked={newItem.taxRate === 0.19}
                  onChange={() => {
                    setNewItem({ ...newItem, taxRate: 0.19 });
                  }}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="7%"
                  name="radioGroup"
                  value={0.07}
                  checked={newItem.taxRate === 0.07}
                  onChange={() => {
                    setNewItem({ ...newItem, taxRate: 0.07 });
                  }}
                />
              </Form.Field>
            </Form>
          </div>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: '#f5f5f9' }}>
          <Button
            content={i18n.t('add')}
            color="teal"
            onClick={() => createNewDiverseItem()}
            disabled={isSaveDisabled}
            loading={isLoading}
          />
        </Modal.Actions>
      </Modal>
      <Modal dimmer="blurring" size="tiny" open={errorWarningModalOptions.open}>
        <Modal.Header style={{ borderBottom: 'none' }}>
          <p>{i18n.t('newPrinterErrorTitle')}</p>
        </Modal.Header>
        <Modal.Content>
          <p style={{ fontWeight: 600 }}>{errorWarningModalOptions.message}</p>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
          <Button content="OK" color="teal" onClick={onErrorWarningModalOk} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default DiverseProductModal;
